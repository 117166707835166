import { keys, map } from 'lodash/fp';
import React, { useState } from 'react';
import { StyledElementContainer, StyledNestedItem } from '../styles/containers';
import { StyledLabel } from '../styles/label';
import { StyledInput, StyledInputContainer } from '../styles/input';
import { hasObj } from '../utils';

const Item = ({ definition, onChange, objectKey, valueObject, inputHasBlur, validator, autoSelect, readOnly, readOnlyElement, hasBlur, svg }) => (
  <StyledElementContainer>
    <StyledLabel htmlFor={objectKey}> {definition.label} {definition.required && <span className="required">*</span>}</StyledLabel>
    <StyledInputContainer>
      <StyledInput
        onChange={e => onChange(e)}
        value={valueObject[objectKey]}
        name={objectKey}
        id={objectKey}
        placeholder={definition.placeholder}
        type={definition.type}
        onBlur={e => inputHasBlur(e)}
        invalid={validator && hasBlur[objectKey] && !validator[objectKey].valid}
        readOnly={readOnly || readOnlyElement === objectKey}
        autoFocus={autoSelect ? true : false}
      />
    </StyledInputContainer>
    {hasObj(validator) && validator[objectKey].errorMessage && hasBlur[objectKey] && (
      <p className="error-form-container">{validator[objectKey].errorMessage}</p>
    )}
  </StyledElementContainer>
)

const RenderForm = ({ form, valueObject, onChange, validator, readOnly, readOnlyElement, autoSelect, nested }) => {
  const [hasBlur, updateHasBlur] = useState({});

  const inputHasBlur = ({ target: { name } }) =>
    updateHasBlur({
      [name]: true,
      ...hasBlur
    });

  return (
    map(key => {
      const definition = form[key];

      return (
        <React.Fragment key={key}>
          {nested ? (
            <StyledNestedItem>
              <Item
                definition={definition}
                onChange={onChange}
                objectKey={key}
                valueObject={valueObject}
                inputHasBlur={inputHasBlur}
                validator={validator}
                autoSelect={form[key]['autoFocus'] || autoSelect}
                readOnly={readOnly}
                readOnlyElement={readOnlyElement}
                hasBlur={hasBlur}
              />
            </StyledNestedItem>
          ) : (
              <Item
                definition={definition}
                onChange={onChange}
                objectKey={key}
                valueObject={valueObject}
                inputHasBlur={inputHasBlur}
                validator={validator}
                autoSelect={form[key]['autoFocus'] || autoSelect}
                readOnly={readOnly}
                readOnlyElement={readOnlyElement}
                hasBlur={hasBlur}
              />
            )}
        </React.Fragment>
      )
    })(keys(form))
  )
};

export default RenderForm;
