import React from 'react';
import { StyledInputContainer, StyledInputWrapper, StyledRadioInput } from "../styles/input";

const Radio = ({ value, name, checked, onChange, label }) => (
  <StyledInputWrapper>
    <StyledInputContainer style={{ float: 'left' }}>
      <StyledRadioInput>
        {label}
        <input
          type="radio"
          checked={checked}
          value={value}
          name={name}
          onChange={(e) => onChange(e)}
        />
        <span className="checkmark" />
      </StyledRadioInput>
    </StyledInputContainer>
  </StyledInputWrapper>
);

export default Radio;
