const dev = {
  "aws_user_pools_id": process.env.REACT_APP_DEV_USER_POOL_ID,
  "aws_user_pools_web_client_id": process.env.REACT_APP_DEV_APP_CLIENT_ID,
  "apiUrl": process.env.REACT_APP_DEV_API_URL,
  "apiKey": process.env.REACT_APP_DEV_API_KEY,
  "stage": 'prod',
  "env": process.env.REACT_STAGE,
  "oauth": {},
  "environment": "DEV"
};

const tst = {
  "aws_project_region": process.env.REACT_DEV_REGION,
  "aws_cognito_region": process.env.REACT_APP_DEV_REGION,
  "aws_user_pools_id": process.env.REACT_APP_TST_USER_POOL_ID,
  "aws_user_pools_web_client_id": process.env.REACT_APP_TST_APP_CLIENT_ID,
  "apiUrl": process.env.REACT_APP_TST_API_URL,
  "apiKey": process.env.REACT_APP_TST_API_KEY,
  "stage": 'prod',
  "env": process.env.REACT_STAGE,
  "oauth": {},
  "environment": "ACC"
};

const prd = {
  "stage": "prod",
  "env": process.env.REACT_STAGE,
  "apiUrl": process.env.REACT_APP_PRD_API_URL,
  "apiKey": process.env.REACT_APP_PRD_API_KEY,
  "aws_user_pools_id": process.env.REACT_APP_PRD_USER_POOL_ID,
  "aws_user_pools_web_client_id": process.env.REACT_APP_PRD_APP_CLIENT_ID,
  "environment": "PRD"
};


const environments = {
  dev,
  tst,
  prd
}

const config = environments[process.env.REACT_APP_STAGE];

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
