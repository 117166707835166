
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { apiCall } from '../../api';
import side from '../../assets/images/side.jpg';
import getSpinner from '../../shared/components/loadingSpinner';
import MessageBox from '../../shared/components/messageBox';
import { userApplicationsSelector } from '../../shared/selectors/cognito';
import { userSelector } from '../../shared/selectors/user';
import { StyledApplicationContainer } from '../../shared/styles/containers';
import getSvg from '../../shared/svg/getSvg';
import { hasSuccess } from '../../shared/utils';
import Navigation from '../navigation/main';

const StyledContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  height: calc(100vh - 135px);

  ul {
    width: 60%;
    margin: 0 80px;
    display: inline-block;
    padding-left: 0;

    &:not-first-child {
      padding-left: 30px;
    }

    li {
      float: left;
      width: 50%;
      list-style: none;
      margin-top: 50px;
    }
  }
`
const StyledSideDiv = styled.div`
  position: absolute;
  left: 0;

  img {
    height: calc(100vh - 135px);
  }
`

const StyledContactDiv = styled.div`
  width: 100%;
  margin: 0 auto;
  position: absolute;
  bottom: 0;

  color : ${props => props.theme.color.red};
  font-size: 20px;

  .wrapper {
    width: 30%;
    margin: 0 auto;
    position: relative;

    svg {
      float: left;
      width: 50px;
      position: absolute;
      left: 35px;
      top: -20px;
    }
  }

  @media only screen and (max-width : 2400px) {
    .wrapper {
      width: 35%;
    }
  }
  @media only screen and (max-width : 2038px) {
    .wrapper {
      width: 40%;
    }
  }
  @media only screen and (max-width : 1687px) {
    .wrapper {
      width: 45%;
    }
  }

`

const StyledApplicationButton = styled.button`
  width: 350px;
  padding: 20px;
  background-color: transparent
  color: ${props => props.theme.color.blue}
  border: none;
  font-size: 20px;
  cursor: pointer;

  ${props => props.active && (
    `
      &:hover {
        p {
          text-decoration: underline;
        }
        svg {
          width: 60px;
          height: 60px;
        }
      }
    `
  )}


  ${props => !props.active && `
    opacity: .5;
    cursor: default;
  `}

  svg {
    float: left;

    width: 55px;
    height: 55px;

    .users-1 {
      fill: #cc3f5e;
    }

    .users-2 {
      fill: #fff;
    }

    .archief-1 {
      fill: #cc3f5e;
    }

    .archief-2 {
      fill: none;
    }
  }

  p {
    float: left;
    margin-left: 10px;
  }
`

export const translateApps = {
  'axy-life-leven': 'Leven',
  'klantoverzicht': 'Klantenoverzicht',
  // 'axy-life-pensioen': 'Pensioen',
  'zilverhuis-hypotheek': 'Hypotheek',
  'digitaal-archief': 'Digitaal archief',
  'algemene-informatie': 'Informatie',
  "contact": "Contact",
  // 'zilverhuis-diensten': 'Diensten',
}

const Applications = ({ user, applications, initMessage, history }) => {
  const { superAdmin, admin } = user;
  const [loading, updateLoading] = useState(false);


  useEffect(() => {
    var one = document.getElementById("sideDiv");
    var two = document.getElementById("logo");
    var style = window.getComputedStyle(one);
    var wdt = style.getPropertyValue('width');
    two.style.width = wdt;
  }, [])

  const handleApplicationClick = async (key, active) => {
    if (active) {
      if (key === 'contact') {
        window.open('https://www.onderlinge.info/service/contact', '_blank');
      } else if (key === 'zilverhuis-hypotheek') {
        window.open('https://www.onderlinge.info/hypotheek.aspx', '_blank');
      } else if (key === 'algemene-informatie') {
        window.open('https://www.onderlinge.info/over-ons/paginas/downloads.aspx', '_blank');
      } else {
        updateLoading(true);
        const body = {
          application: key,
        }
  
        Object.keys(window.localStorage).forEach(item => {
          if (item.includes('refreshToken')) {
            body['refreshToken'] = window.localStorage.getItem(item);
          } else if (item.includes('LastAuthUser')) {
            body['lastAuthUser'] = window.localStorage.getItem(item);
          }
        });

        const response = await apiCall({
          path: 'applicationLink/generate',
          method: 'post',
          body
        });

        if (hasSuccess(response)) {
          updateLoading(false);
          const url = response['Success'];
          if (process.env.NODE_ENV === 'development') {
            const code = url.split(':3001/')[1];
            window.open(`http://localhost:3001/${code}`, "_blank");
          } else {
            window.open(url, "_blank");
          }
        }
      }
    }
  }

  return (
    <>
      <Navigation user={user} history={history} initMessage={initMessage} />
      <StyledApplicationContainer>
        <StyledSideDiv id="sideDiv">
          <img src={side} alt="side" />
        </StyledSideDiv>
        <StyledContainer>
          <ul>
            {Object.keys(translateApps).map(key => {
              const definition = translateApps[key];

              return (
                <li key={key}>
                  <StyledApplicationButton active={applications[key]} key={key} onClick={() => handleApplicationClick(key, applications[key])}>
                    {getSvg[key]} <p>{definition}</p>
                  </StyledApplicationButton>
                </li>
              )
            })}
          </ul>
          <MessageBox initMessage={initMessage} superAdmin={superAdmin} />
          <StyledContactDiv>
            <div className="wrapper">
              {getSvg['smartPhone']} <p>Vragen? Bel of mail ons gerust: 070-3421111 / info@ogmail.nl</p>
            </div>
          </StyledContactDiv>
        </StyledContainer>
      </StyledApplicationContainer>
      {loading && (
        getSpinner('common')
      )}
    </>
)};

const mapStateToProps = (state) => ({
  applications: userApplicationsSelector(state),
  user: userSelector(state)
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Applications);
