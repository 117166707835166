export const listItems = {
  userManagment: [
    "username",
    "intermediary",
    "tpcode",
    "rights",
    "groups",
    "disabled",
    "actions",
    "disable_isOffice",
    "disable_digital_archive_read_fi",
    "disable_digital_archive_read_pd",
    "disable_digital_archive_read_po",
    "disable_axy_life_leven_rights",
    "disable_axy_life_pensioen_rights",
    "disable_klantoverzicht_rights",
  ],
};

// PRD
// export const listItems = {
//   userManagment: [
//     "username",
//     "intermediary",
//     "tpcode",
//     "rights",
//     "groups",
//     "disabled",
//     "actions",
//     "disable_isOffice",
//     "disable_digital_archive_read_fi",
//     "disable_digital_archive_read_pd",
//     "disable_digital_archive_read_po",
//     "disable_axy_life_leven_rights",
//     "disable_axy_life_pensioen_rights",
//   ],
// };

export const translateListItems = {
  userManagment: {
    username: "Gebruiker",
    intermediary: "Adviseur",
    tpcode: "TP-code",
    rights: "Rechten",
    // groups: "Applicaties",
    disabled: "Status",
    actions: "Acties",
  },
};

export const initRadioButtons = {
  superAdmin: false,
  admin: false,
  user: false,
};

export const translateRadioButtons = {
  superAdmin: "Onderlinge admin",
  admin: "Adviseur admin",
  user: "Gebruiker",
};

export const translateDigitalArchiveOptions = {
  read_fi: "Lezen Financieel",
  read_po: "Lezen Verzekering",
  read_pd: "Lezen Pensioen",
};

export const translateAxyLifeOptions = {
  mutation: "Muteren",
  read: "Lezen",
};

export const translatklantoverzichtOptions = {
  mutation: "Muteren",
  read: "Lezen",
};

