import styled from 'styled-components';


export const StyledLabel = styled.label`
  font-size: 1.2rem;
  span.required {
    font-size: 20px;
    color: ${props => props.theme.color.blue};
  }

  &.label-button {
    color: ${props => props.theme.color.blue};
    background-color: ${props => props.theme.color.grey}
    background-color: ${props => props.theme.color[props.color ? props.color : 'buttonBackground' ]}
    border: 1px solid ${props => props.theme.color.buttonBorder};
    padding: 10px;
    width: auto;
    cursor: pointer;
    border-radius: 5px;
  }
`
