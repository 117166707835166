import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { allAppicationsHandlerAction, allUsersHandlerAction } from '../../shared/actions/cognito';
import { setErrorMessageAction } from '../../shared/actions/error';
import CheckBox from '../../shared/components/checkBox';
import Confirm from '../../shared/components/confirm';
import Radio from '../../shared/components/radio';
import RenderForm from '../../shared/components/renderForm';
import { applicationsSelector, intermediaryListSelector } from '../../shared/selectors/cognito';
import { userSelector } from '../../shared/selectors/user';
import { StyledActionButton, StyledButton, StyledFixedBackground, StyledInput, StyledInputContainer } from '../../shared/styles';
import { StyledElementContainer, StyledScreenContainer } from '../../shared/styles/containers';
import { StyledLabel } from '../../shared/styles/label';
import { getKey, hasObj } from '../../shared/utils';
import { editUserForm, intermediaryForm } from '../auth/constants';
import { initRadioButtons, translateAxyLifeOptions, translatklantoverzichtOptions, translateDigitalArchiveOptions, translateRadioButtons } from './constants';

const translateApps = {
  "digitaal-archief": "Digitaal archief",
  "axy-life-leven": "Leven",
  "klantoverzicht": "Klantenoverzicht", // tst
  // "axy-life-pensioen": "Pensioen",
};

const initializeOptions = ({ user, key }) => {
  const newOptions = {};
  if (user[key]) {
    const rights = user[key].split(",").filter((item) => item !== "");
    rights.forEach((right) => {
      const splitted = right.split("-");
      const name = splitted[0];
      const permission = splitted[1];

      newOptions[name] = permission === 'true'
    });
  } else {
      newOptions['read'] = false;
    if (key === "disable_axy_life_pensioen_rights") {
      newOptions['mutation'] = false;
    }
  }
  return newOptions;
}

const initializeRights = ({ user, rightName }) => {
  const rights = user[rightName].split(",").filter((item) => item !== "");
  const newOptions = [];

  rights.forEach((right) => {
    const splitted = right.split("-");
    const name = splitted[0];
    const permission = splitted[1];

    if (user['superAdmin']) {
      newOptions.push(name)
    } else {
      if (permission === 'true') {
         newOptions.push(name)
      }
    }
  });

  return newOptions;
};

const EditUser = ({ deleteUserHandler, editUserHandler, handleCancelScreen, user, selectedUser, applications }) => {
  const [valueObject, updateValueObject] = useState({
    email: selectedUser.username
  });

  const [intermediaryValue, updateIntermediaryValue] = useState({
    intermediary: selectedUser.intermediary
  });

  const [userStatus, updateUserStatus] = useState({
    active: selectedUser.disabled === 'Actief'
  });

  const [digitalArchiveCheckboxOptions, updateDigitalArchiveCheckboxOptions] = useState(["read_fi", "read_po", "read_pd"]);
  const [axyLifeLevenCheckboxOptions, updateAxyLifeLevenCheckboxOptions] = useState(["mutation"]);
  const [klantoverzichtCheckboxOptions, updateKlantoverzichtCheckboxOptions] = useState(["read"]);
  const [axyLifePensioenCheckboxOptions, updateAxyLifePensioenCheckboxOptions] = useState(["mutation", "read"]); // tst

  const [checkedRadioButtons, updateCheckedRadioButtons] = useState(initRadioButtons);
  const [applicationCheckboxValues, updateApplicationCheckboxes] = useState({});
  const [initCheckboxValues, updateInitCheckboxValues] = useState({});

  const [initDigitalArchiveOptions, updateInitDigitalArchiveOptions] = useState({});
  const [initAxyLifePensioenOptions, updateInitAxyLifePensioenOptions] = useState({});
  const [initAxyLifeLevenOptions, updateInitAxyLifeLevenOptions] = useState({});
  const [initKlantoverzichtOptions, updateInitKlantoverzichtOptions] = useState({}); // tst
  
  const [digitalArchiveOptions, updatedigitalArchiveOptions] = useState({});
  const [axyLifeLevenOptions, updateAxyLifeLevenOptions] = useState({});
  const [klantoverzichtOptions, updateKlantoverzichtOptions] = useState({}); // tst
  const [axyLifePensioenOptions, updateAxyLifePensioenOptions] = useState({});

  const [selectedRights, updateSelectedRights] = useState();
  const [valid, updateValid] = useState(false);
  const [initUserRights, updateInitUserRights] = useState();
  const [validApplicationChanges, updateApplicationChanges] = useState(false);
  const [statusChanges, updateUserStatusChanges] = useState(false);
  const [ confirm, updateConfirm ] = useState(false);

  useEffect(() => {
    if (Object.keys(user).includes("axyLifeLevenRights")) {
      updateAxyLifeLevenCheckboxOptions(
        initializeRights({ user, rightName: "axyLifeLevenRights" })
      );
    }
    if (Object.keys(user).includes("axyLifePensioenRights")) {
      updateAxyLifePensioenCheckboxOptions(
        initializeRights({ user, rightName: "axyLifePensioenRights" })
      );
    }
    if (Object.keys(user).includes("digitalArchiveRights")) {
      updateDigitalArchiveCheckboxOptions(
        initializeRights({ user, rightName: "digitalArchiveRights" })
      );
    }
    if (Object.keys(user).includes("klantoverzichtRights")) { // tst
      updateKlantoverzichtCheckboxOptions(
        initializeRights({ user, rightName: "klantoverzichtRights" })
      );
    }
  }, [user]);

  useEffect(() => {
    updateSelectedRights(
      Object.keys(
        checkedRadioButtons).map(key => checkedRadioButtons[key] ? key : false
      ).filter(item => item !== false)[0]
    );
  }, [checkedRadioButtons]);

  useEffect(() => {
    const appChanges = JSON.stringify(initCheckboxValues) !== JSON.stringify(applicationCheckboxValues);
    const digitalArchiveOptionChanges = JSON.stringify(initDigitalArchiveOptions) !== JSON.stringify(digitalArchiveOptions);
    const axyLifeLevenOptionChanges = JSON.stringify(initAxyLifeLevenOptions) !== JSON.stringify(axyLifeLevenOptions);
    const axyLifePensioenOptionChanges = JSON.stringify(initAxyLifePensioenOptions) !== JSON.stringify(axyLifePensioenOptions);
    const klantoverzichtOptionChanges = JSON.stringify(initKlantoverzichtOptions) !== JSON.stringify(klantoverzichtOptions); // tst
    const userStatusChanges = (userStatus.active ? 'Actief' : 'Inactief') !== selectedUser.disabled

    updateValid(
      selectedRights !== initUserRights ||
      appChanges ||
      digitalArchiveOptionChanges ||
      axyLifePensioenOptionChanges ||
      axyLifeLevenOptionChanges ||
      klantoverzichtOptionChanges ||
      userStatusChanges
    );

    updateApplicationChanges(
      appChanges
    );

    updateUserStatusChanges(
      userStatusChanges
    )

  }, [selectedRights, applicationCheckboxValues, userStatus, digitalArchiveOptions, axyLifeLevenOptions, axyLifePensioenOptions, klantoverzichtOptions])

  useEffect(() => {
    const customeAttributeDigitalArchive = 'disable_digital_archive_';

    if (applications) {
      const initCheckboxValues = {}
      applications.forEach(app => {
        initCheckboxValues[app] = false
      });

      selectedUser.groups.forEach(app => {
        initCheckboxValues[app] = true
      });

      updateInitCheckboxValues(initCheckboxValues);
      updateApplicationCheckboxes(initCheckboxValues);

      const digitalArchiveOptions = {}

      Object.keys(selectedUser).forEach(key => {
        if (key.includes(customeAttributeDigitalArchive)) {
          const item = key.split(customeAttributeDigitalArchive)[1];
          digitalArchiveOptions[item] = selectedUser[key];
        }

        if (key.includes('disable_axy_life_leven_rights')) {
          const opt = initializeOptions({ user: selectedUser, key });
          updateInitAxyLifeLevenOptions(opt);
          updateAxyLifeLevenOptions(opt);
        }
        if (key.includes('disable_axy_life_pensioen_rights')) {
          const opt = initializeOptions({ user: selectedUser, key })
          updateInitAxyLifePensioenOptions(opt);
          updateAxyLifePensioenOptions(opt);
        }
        if (key.includes('disable_klantoverzicht_rights')) { // tst
          const opt = initializeOptions({ user: selectedUser, key })
          updateInitKlantoverzichtOptions(opt);
          updateKlantoverzichtOptions(opt);
        }
      });

      updateInitDigitalArchiveOptions(digitalArchiveOptions);
      updatedigitalArchiveOptions(digitalArchiveOptions);
    }

  }, [applications, selectedUser]);

  useEffect(() => {
    const { rights } = selectedUser;
    const radioButtonKey = Object.keys(translateRadioButtons).filter(
      key => translateRadioButtons[key] === rights
    )[0];

    updateInitUserRights(radioButtonKey);

    const radioButtons = {
      ...initRadioButtons,
      [radioButtonKey]: true
    }

    if (rights === undefined) {
      radioButtons['user'] = true
    } 

    updateCheckedRadioButtons(radioButtons);
  }, []);


  const handleChange = ({ target: { name, value } }) => {
    updateValueObject({
      ...valueObject,
      [name]: value
    });
  }

  const handleIntermediaryChange = ({ target: { value } }) => {
    updateIntermediaryValue(value);
  }

  const handleRadioButtonChange = ({ target: { checked, value } }) => {
    updateCheckedRadioButtons({
      ...initRadioButtons,
      [value]: checked
    });
  }

  const handleApplicationCheckbox = ({ target: { name, checked } }) => {
    const key = getKey({keys: translateApps, item: name});
    updateApplicationCheckboxes({
      ...applicationCheckboxValues,
      [key]: checked
    })
  }

  const handleCheckboxChanges = (
    { target: { name, checked } },
    application
  ) => {
    const optionStates = {
      digitalArchive: updatedigitalArchiveOptions,
      axyLifeLeven: updateAxyLifeLevenOptions,
      axyLifePensioen: updateAxyLifePensioenOptions,
      klantoverzicht: updateKlantoverzichtOptions,
    };

    const optionKeys = {
      digitalArchive: getKey({
        keys: translateDigitalArchiveOptions,
        item: name,
      }),
      axyLifeLeven: getKey({ keys: translateAxyLifeOptions, item: name }),
      axyLifePensioen: getKey({ keys: translateAxyLifeOptions, item: name }),
      klantoverzicht: getKey({ keys: translatklantoverzichtOptions, item: name }),
    };

    const OptionApplications = {
      digitalArchive: digitalArchiveOptions,
      axyLifeLeven: axyLifeLevenOptions,
      axyLifePensioen: axyLifePensioenOptions,
      klantoverzicht: klantoverzichtOptions
    };

    optionStates[application]({
      ...OptionApplications[application],
      [optionKeys[application]]: checked,
    });
  };

  const handleSubmit =  async (e) => {
    e.preventDefault();

    let digitalArchiveValue = ''
    let axyLifeLevenValue = "";
    let axyLifePensioenValue = "";
    let klantoverzichtValue = "";

    Object.keys(digitalArchiveOptions).forEach(key => {
      digitalArchiveValue = digitalArchiveValue + `${key}-${digitalArchiveOptions[key]},`
    });

    Object.keys(axyLifeLevenOptions).forEach((key) => {
      axyLifeLevenValue =
        axyLifeLevenValue + `${key}-${axyLifeLevenOptions[key]},`;
    });

    Object.keys(axyLifePensioenOptions).forEach((key) => {
      axyLifePensioenValue =
        axyLifePensioenValue + `${key}-${axyLifePensioenOptions[key]},`;
    });

    Object.keys(klantoverzichtOptions).forEach((key) => {
      klantoverzichtValue =
      klantoverzichtValue + `${key}-${klantoverzichtOptions[key]},`;
    });

    const userObject = {
      username: valueObject.email,
      attributes: [
        {
          Name: "custom:is_admin",
          Value: selectedRights === 'admin' ? "true" : "false"
        },
        {
          Name: "custom:is_super_admin",
          Value: selectedRights === 'superAdmin' ? "true" : "false"
        },
        {
          Name: "custom:digital_archive",
          Value: digitalArchiveValue
        },
        {
          Name: "custom:digital_archive",
          Value: digitalArchiveValue,
        },
        {
          Name: "custom:axylife_pensioen",
          Value: axyLifePensioenValue,
        },
        {
          Name: "custom:axylife_leven",
          Value: axyLifeLevenValue,
        },
        {
          Name: "custom:klantoverzicht",
          Value: klantoverzichtValue,
        },
      ]
    }

    const applicationSelection = {
      username: valueObject.email,
      groupList: Object.keys(applicationCheckboxValues).map(key => applicationCheckboxValues[key] ? key : false).filter(item => item !== false)
    }

    const returnObject = {
      userObject,
      applicationSelection,
      selectedUser,
      validApplicationChanges
    }

    if (statusChanges) {
      userObject.attributes.push({
        Name: 'custom:disabled',
        Value: userStatus.active ? "false" : "true"
      });
      returnObject['userDisabled'] = !userStatus.active
    }



    editUserHandler(returnObject);
    handleCancelScreen({});
  }

  const handleDeleteUser = ({ submit }) => {
    updateConfirm(!confirm);

    if (submit) {
      handleCancelScreen({});
    }
  }

  const handleUserStatus = ({ target: { checked } }) => {
    updateUserStatus({
      active: checked
    });
  }

  return !confirm ? (
    <>
      <StyledFixedBackground>
        <StyledScreenContainer style={{ position: 'relative', width: '40%' }}>
            <h2>Wijzig gebruiker</h2>
            <form onSubmit={handleSubmit}>
              <RenderForm
                form={editUserForm}
                valueObject={valueObject}
                onChange={handleChange}
                validator={{}}
                readOnly={true}
              />
              <StyledElementContainer>
                {selectedRights !== 'superAdmin' && (
                  <>
                    <h2>
                      Rechten
                    </h2>
                    {Object.keys(initRadioButtons).map(key => {
                      return key === 'superAdmin' ? (
                        null
                      ) : (
                        <React.Fragment key={key}>
                          {/superAdmin|admin/.test(key) ? (
                            <>
                              {user.superAdmin && (
                                <Radio
                                  key={key}
                                  name="rights"
                                  label={translateRadioButtons[key]}
                                  checked={checkedRadioButtons[key]}
                                  value={key}
                                  onChange={handleRadioButtonChange}
                                />
                              )}
                            </>
                          ) : (
                            <Radio
                              key={key}
                              name="rights"
                              label={translateRadioButtons[key]}
                              checked={checkedRadioButtons[key]}
                              value={key}
                              onChange={handleRadioButtonChange}
                            />
                          ) }
                        </React.Fragment>
                      )
                    })}
                  </>
                )}
              </StyledElementContainer>
              <div style={{ clear: 'both' }}/>
              {selectedRights === 'admin' && (
                <RenderForm
                  form={intermediaryForm}
                  valueObject={intermediaryValue}
                  onChange={handleIntermediaryChange}
                  validator={{}}
                  readOnly={true}
                />
              )}
              {selectedRights === 'user' && (
                <StyledElementContainer>
                  <StyledLabel> Adviseur <span className="required">*</span></StyledLabel>
                  <StyledInputContainer style={{ height: 'auto' }}>
                    <StyledInput
                      onChange={undefined}
                      value={selectedUser.intermediary}
                      name={'objectKey'}
                      id={'objectKey'}
                      placeholder={''}
                      type={'text'}
                      onBlur={undefined}
                      invalid={false}
                      readOnly={true}
                      autoFocus={false}
                    />
                  </StyledInputContainer>
              </StyledElementContainer>
              )}
              {hasObj(applicationCheckboxValues) && (
                <>
                  <StyledElementContainer>
                    <h2>
                      Applicaties
                    </h2>
                    {applications.map(app => (
                      <CheckBox
                        key={app}
                        label={translateApps[app]}
                        onChange={handleApplicationCheckbox}
                        checked={applicationCheckboxValues[app]}
                      />
                    ))}
                  </StyledElementContainer>
                  {applicationCheckboxValues['digitaal-archief'] && (
                    <StyledElementContainer>
                      <h2>
                        Rechten Digitaal archief
                      </h2>
                      {digitalArchiveCheckboxOptions.map(item => (
                        <CheckBox
                          key={item}
                          label={translateDigitalArchiveOptions[item]}
                          onChange={(e) => handleCheckboxChanges(e, "digitalArchive")}
                          checked={digitalArchiveOptions[item]}
                        />
                      ))}
                    </StyledElementContainer>
                  )}
                  {applicationCheckboxValues["axy-life-leven"] && (
                    <StyledElementContainer>
                      <h2>Rechten Leven</h2>
                      {axyLifeLevenCheckboxOptions.map((item) => (
                        <CheckBox
                          key={item}
                          label={translateAxyLifeOptions[item]}
                          onChange={(e) => handleCheckboxChanges(e, "axyLifeLeven")}
                          checked={axyLifeLevenOptions[item]}
                        />
                      ))}
                    </StyledElementContainer>
                  )}
                  {applicationCheckboxValues["klantoverzicht"] && (
                    <StyledElementContainer>
                      <h2>Rechten klantenoverzicht</h2>
                      {klantoverzichtCheckboxOptions.map((item) => (
                        <CheckBox
                          key={item}
                          label={translatklantoverzichtOptions[item]}
                          onChange={(e) => handleCheckboxChanges(e, "klantoverzicht")}
                          checked={klantoverzichtOptions[item]}
                        />
                      ))}
                    </StyledElementContainer>
                  )}
                  {/* {applicationCheckboxValues["axy-life-pensioen"] && (
                    <StyledElementContainer>
                      <h2>Rechten Pensioen</h2>
                      {axyLifePensioenCheckboxOptions.map((item) => (
                        <CheckBox
                          key={item}
                          label={translateAxyLifeOptions[item]}
                          onChange={(e) => handleCheckboxChanges(e, "axyLifePensioen")}
                          checked={axyLifePensioenOptions[item]}
                        />
                      ))}
                    </StyledElementContainer>
                  )} */}
                </>
              )}
              <StyledElementContainer>
                <h2>
                  Gebruiker status
                </h2>
                <CheckBox
                  key={'status'}
                  label={`${userStatus.active ? 'Actief' : 'Inactief'}`}
                  onChange={handleUserStatus}
                  checked={userStatus.active}
                />
              </StyledElementContainer>
              <StyledElementContainer>
                <h2>
                  Acties
                </h2>
                <StyledActionButton type="button" onClick={handleDeleteUser}>
                  Gebruiker verwijderen
                </StyledActionButton>
              </StyledElementContainer>
              <StyledElementContainer>
                <StyledButton type="button" onClick={handleCancelScreen}>
                  Annuleer
                </StyledButton>
                <StyledButton type="submit" disabled={(!valid)}>
                  Bevestig
                </StyledButton>
              </StyledElementContainer>
            </form>
        </StyledScreenContainer>
      </StyledFixedBackground>
    </>
  ) : (
    <Confirm
      text={{
        heading: 'Verwijder gebruiker',
        paraf: `Weet je zeker dat je ${selectedUser.username} wilt verwijderen?`
      }}
      handleCancelScreen={handleDeleteUser}
      action={deleteUserHandler}
      actionParams={{ selectedUser }}
    />
  )
};

const mapStateToProps = (state) => ({
  user: userSelector(state),
  intermediaryList: intermediaryListSelector(state),
  applications: applicationsSelector(state)
});

const mapDispatchToProps = dispatch => ({
  allUsersHandler: users => dispatch(allUsersHandlerAction(users)),
  allAppicationsHandler: applications => dispatch(allAppicationsHandlerAction(applications)),
  setErrorMessage: message => dispatch(setErrorMessageAction(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);

