import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../../assets/images/onderlinge.png';
import { userInfoHandlerAction } from '../../shared/actions/user';
import MessageBox from '../../shared/components/messageBox';
import { StyledButton } from '../../shared/styles';
import { StyledNavigation } from '../../shared/styles/navigation';
import getSvg from '../../shared/svg/getSvg';
import { clearLocalStorage } from '../../shared/utils';


const StyledNameTag = styled.div`
  position: absolute;
  right: 0;
  top: -30px;
  padding: 10px;
  border: 1px solid #ececec;
  background-color: #fbfbfb;
  border-radius: 10px;

  svg {
    float: right;
    padding-left: 5px;
    fill: ${props => props.theme.color.blue};
  }
`

const StyledActionUl = styled.ul`
  text-decoration: none;
  list-style-type: none;
  position: absolute;
  right: 25px;
  margin-top: 20px;

  li {
    margin-top: 15px;
    button {
      text-transform: lowercase;
      margin: 0;
    }
  }
`

const Navigation = ({ user, userInfoHandler, backButton, history, initMessage }) => {
  const { superAdmin, admin } = user;

  const userSignOut = () => {
    clearLocalStorage();
    userInfoHandler({});
    history.push('/');
  }


  return (
    <StyledNavigation>
      <div className="header">
        <div id="logo" className="logo">
          <a href="https://www.onderlinge.info/" rel="noopener noreferrer" target="_blank">
            <img src={logo} alt="logo" />
          </a>
        </div>
        <div className="header-text">
          <h2>
            Wij zijn Onderlinge 's-Gravenhage
          </h2>
          <p>Welkom op OG Connect</p>
          {/* <MessageBox initMessage={initMessage} superAdmin={superAdmin} /> */}
        </div>
        <StyledActionUl>
          <li>
            <StyledButton width="150" type="button" color="red" onClick={userSignOut}>
              uitloggen
            </StyledButton>
          </li>
          {(superAdmin || admin) && (
            <li>
              <Link to={backButton ?  '/dashboard/applications' : '/dashboard/beheer'}>
                <StyledButton width="150" type="button" color="red">
                  {backButton ? 'terug' : 'beheer'}
                </StyledButton>
              </Link>
            </li>
          )}
        </StyledActionUl>
      </div>
    </StyledNavigation>
)};

const mapStateToProps = (state, props) => ({
});

const mapDispatchToProps = dispatch => ({
  userInfoHandler: user => dispatch(userInfoHandlerAction(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navigation))
