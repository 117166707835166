import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { apiCallUnAuthenticated } from '../../api';
import { updateMessageAction } from '../../shared/actions/shared';
import RenderForm from '../../shared/components/renderForm';
import SubmitButton from '../../shared/components/submitButton';
import { StyledButton, StyledFixedBackground } from '../../shared/styles';
import { StyledScreenContainer } from '../../shared/styles/containers';
import { hasError } from '../../shared/utils';
import { emailRegex, forgotPasswordForm, initialForgotPasswordForm, transelateErrorMessage } from './constants';

const ResendActivationMail = ({ updateMessage, history }) => {
  const [form, updateForm] = useState(initialForgotPasswordForm);
  const [errorCode, updateErrorCode] = useState();
  const [loading, updateLoading] = useState();
  const [validator, updateValidator] = useState({});
  const [valid, updateValid] = useState({});

  useEffect(() => {
    const validObject = {
      email: {
        valid: RegExp(emailRegex).test(form['email']),
        errorMessage: RegExp(emailRegex).test(form['email']) ? '' : 'Geen geldig email adres'
      },
    }
    updateValidator(validObject);

   updateValid(!Object.keys(validObject).map(key => validObject[key]['valid']).includes(false));
  }, [form]);

  const submitFunction = () => {
    updateMessage({});
  }

  const handleSubmit = async e => {
    e.preventDefault();
    updateLoading(true);
    const response = await apiCallUnAuthenticated({
      path: 'unauth/resend-confirmation-code',
      method: 'post',
      body: {
        username: form['email']
      }
    });

    if (response) {
      updateLoading(false);

      if (hasError(response)) {
        updateErrorCode('ActivationCodeError')
      } else {
        history.push('/');
        updateMessage({
          title: 'Activatiemail verzonden',
          body: `Er is een activatiemail verzonden naar ${form['email']}.`,
          linkLabel: 'Volgende',
          submitFunction,
          button: true
        });
      }
    }
  };

  const handleFormChange = ({ target: { name, value } }) => {
    updateForm({ ...form, [name]: value })
  }

  return (
    <StyledFixedBackground>
      <StyledScreenContainer style={{ position: 'relative', width: '40%' }}>
        <h2>
          Verstuur activatiemail opnieuw
        </h2>
        <form onSubmit={handleSubmit}>
          <RenderForm
            form={forgotPasswordForm}
            valueObject={form}
            onChange={handleFormChange}
            validator={validator}
          />
          <p className="error">
            {transelateErrorMessage[errorCode]}
          </p>
          <SubmitButton
            valid={valid}
            loading={loading}
            text="Verstuur"
          />
          <StyledButton type="button">
            <Link to='/'> Terug </Link>
          </StyledButton>
        </form>
      </StyledScreenContainer>
    </StyledFixedBackground>

)};

const mapStateToProps = (state, props) => ({
});

const mapDispatchToProps = dispatch => ({
  updateMessage: loading => dispatch(updateMessageAction(loading))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResendActivationMail))
