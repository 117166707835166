import empty from 'empty';
import { combineReducers } from 'redux';

export default combineReducers({
  session: (state = empty.object, { type, payload = empty.object }) => {
    switch (type) {
      case 'USER_SESSION_ERROR_MESSAGE':
        return payload

      default:
        return state
    }
  },
  apiCall: (state = empty.object, { type, payload = empty.object }) => {
    switch (type) {
      case 'API_CALL_ERROR_MESSAGE':
        return payload

      default:
        return state
    }
  },
});
