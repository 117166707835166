import React from 'react';
import Authentication from './authentication';
import NewPassword from './newpassword';
import ResetPassword from './resetPassword';
import SetupMFA from './setupMfa';
import VerificationCode from './verification';

const GetComponent = ({ componentParams, handleCancelScreen, powerOffSelection }) => {
  const {
    component,
    cUser,
    loginForm,
    code,
    submitFunction,
    history,
    clearComponentParams
  } = componentParams;

  const getComponent = {
    auth: <Authentication cUser={cUser} loginForm={loginForm} clearComponentParams={clearComponentParams} history={history} />,
    newPassword: <NewPassword loginForm={loginForm} clearComponentParams={clearComponentParams} />,
    setupMFA: <SetupMFA cUser={cUser} code={code} clearComponentParams={clearComponentParams} />,
    resetPassword: <ResetPassword submitFunction={submitFunction} clearComponentParams={clearComponentParams} />,
    verification: <VerificationCode clearComponentParams={clearComponentParams} />
  }

  return getComponent[component];
}

export default GetComponent;
