import React from "react";

const OnderlingeDigitaalArchiefSVG = () => (
<svg id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53.58 59.17">
  <defs>
    {/* <style>
      .archief-1 {
        fill: #cc3f5e;
      }

      .archief-2 {
        fill: none;
      }
    </style> */}
  </defs>
  <g>
    <path className="archief-1" d="M277.27,390.76a.25.25,0,0,0,.19-.05,3.64,3.64,0,0,0,1.63.3l.16.45c-.37,1.89-.75,3.77-1.13,5.66q-2.4,12.08-4.84,24.15c-.18.88,0,1.1.83.89.27,0,.55,0,.83,0h45.37c.28,0,.55,0,.83,0,1,.25,1.25,0,1-1.06-.81-3.23-1.33-6.53-2.11-9.77-.85-3.57-1.4-7.21-2.2-10.79-.68-3.08-1.15-6.21-1.71-9.32l.05,0,0-.07,1.86-.31a.17.17,0,0,0,.05.18c-.13.42.12.77.2,1.15,1.61,8.11,3.19,16.23,4.84,24.33a66.09,66.09,0,0,0,1.42,6.68q0,12.83,0,25.67c-.23,1-1,1.08-1.83,1.08q-24.75,0-49.51,0c-2.12,0-2.12,0-2.24-2V425.3a32.09,32.09,0,0,1,.18-3.55c.29-1.87.74-3.71,1.11-5.55q.67-3.29,1.34-6.55c.52-2.6,1-5.21,1.55-7.82s1.1-5.18,1.55-7.79A15.39,15.39,0,0,0,277.27,390.76Zm20.49,33.35c-7.91,0-15.83,0-23.75,0-.85,0-1.16.14-1.15,1.09q.06,10.86,0,21.71c0,.89.21,1.13,1.12,1.13q23.68-.06,47.38,0c1,0,1.23-.32,1.23-1.29q-.06-10.73,0-21.47c0-1-.27-1.18-1.2-1.17C313.52,424.13,305.64,424.11,297.76,424.11Z" transform="translate(-270.94 -390.71)"/>
    <path className="archief-1" d="M281.79,412.72c5.06,0,10.12,0,15.18,0l18.89,0c.9.14,2.15-.29,2.2,1.3v5.52c-.24,1.28-1.28.38-1.88.67l-.12-4.27c.08-.67.29-1.29-.86-1.29q-17.47.06-34.94,0c-.85,0-1.06.28-.86,1l-.06,4.42-.59.07c-1,.16-1.43-.1-1.34-1.25a38.13,38.13,0,0,0,0-4.66c-.05-1.11.33-1.65,1.49-1.51l1.43,0Z" transform="translate(-270.94 -390.71)"/>
    <path className="archief-1" d="M311.78,405.22c-6.41,0-12.82,0-19.24,0-3,0-6.05,0-9.07,0-1.05-.26-1.54,0-1.43,1.22s0,2.48,0,3.71c.07,1-.48.85-1.1.9s-.85-.18-.84-.85c.05-2.15,0-4.3,0-6.46v0a2.1,2.1,0,0,1,1.64-.5q15.88,0,31.77,0a2,2,0,0,1,1.37.29.13.13,0,0,0,.1.11c.44,2.28.11,4.59.17,6.89,0,.67-.65.55-1.1.58s-.78-.23-.76-.79c0-1.24,0-2.47,0-3.71C313.35,405.12,313.35,405.12,311.78,405.22Z" transform="translate(-270.94 -390.71)"/>
    <path className="archief-1" d="M284.67,393.79l23.88,0c.76,0,1.52,0,2.27,0,1.86.12,1.91.17,1.91,2,0,.95,0,1.9,0,2.86a6,6,0,0,1-.14,2.21c-.21.48-1.12-.07-1.63.32l-.11,0-.06-4.52c.19-.8,0-1.19-.92-.95l-16.72-.05c-2.58,0-5.17,0-7.76.05-.35,0-.84-.25-.7.47v4.56a.17.17,0,0,0,0,.18c-.44,0-.87,0-1.31.06s-.62-.15-.61-.61c0-1.91,0-3.83,0-5.74,0-.58.25-.88.86-.87C284,393.85,284.31,393.81,284.67,393.79Z" transform="translate(-270.94 -390.71)"/>
    <path className="archief-2" d="M297.76,424.11c7.88,0,15.76,0,23.63,0,.93,0,1.2.21,1.2,1.17q-.07,10.74,0,21.47c0,1-.2,1.29-1.23,1.29Q297.67,448,274,448c-.91,0-1.12-.24-1.12-1.13q.06-10.86,0-21.71c0-.95.3-1.09,1.15-1.09C281.93,424.12,289.85,424.11,297.76,424.11Zm0,13.86c3.68,0,7.36,0,11,0,1.07,0,1.54-.41,1.5-1.49,0-1.44,0-2.88,0-4.32,0-.9-.41-1.33-1.31-1.31h-22.2c-1.38,0-1.59.23-1.6,1.6s.05,2.65,0,4c-.05,1.15.41,1.57,1.55,1.56C290.39,437.94,294.06,438,297.74,438Z" transform="translate(-270.94 -390.71)"/>
    <path className="archief-1" d="M297.74,438c-3.68,0-7.35,0-11,0-1.14,0-1.6-.41-1.55-1.56.06-1.31,0-2.64,0-4s.22-1.6,1.6-1.6H309c.9,0,1.32.41,1.31,1.31,0,1.44,0,2.88,0,4.32,0,1.08-.43,1.5-1.5,1.49C305.1,438,301.42,438,297.74,438Zm0-1.91h8.63c2,0,2,0,2.06-2.08,0-1-.3-1.24-1.27-1.23-5.91,0-11.82,0-17.73,0-2.39,0-2.35,0-2.35,2.39,0,.75.24.93,1,.92C291.24,436,294.48,436.06,297.71,436.06Z" transform="translate(-270.94 -390.71)"/>
  </g>
</svg>

);

export default OnderlingeDigitaalArchiefSVG;
