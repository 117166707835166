/* eslint-disable no-mixed-operators */
import { createSelector } from 'reselect';
import _filter from 'lodash/fp/filter';
import { listItems } from '../../components/dashboard/constants';
import { createTableGroup } from '../utils';
import { userManagementRowsPerPageSelector, userManagementSearchValueSelector } from './list';
import { userSelector } from './user';

const users = state => state.cognito.users;
const applications = state => state.cognito.applications;

export const usersSelector = createSelector(
  [userSelector, users, userManagementRowsPerPageSelector, userManagementSearchValueSelector], (portalUser, users, rows, searchValue) => {
    const formattedUsers = [];

    users.forEach(user => {
      const newUserObject = {}

      Object.keys(user).forEach(key => {
        const definition = user[key];

        if (key === 'Attributes') {
          definition.forEach(attribute => {
            const { Name, Value } = attribute;
            if (Name === 'email') {
              newUserObject['username'] = Value
            }

            if (Name === 'custom:is_super_admin') {
              if (Value === 'true') {
                newUserObject['rights'] = 'Onderlinge admin'
              }
            }

            if (Name === 'custom:is_admin') {
              if (Value === 'true') {
                newUserObject['rights'] = 'Adviseur admin'
              }
            }

            if (Name === 'custom:disabled') {
              if (user['UserStatus'] === 'FORCE_CHANGE_PASSWORD') {
                newUserObject['disabled'] = 'Niet geactiveerd'
              } else {
                newUserObject['disabled'] = ( Value === 'false' || Value === 'False') ? 'Actief' : 'Inactief'
              }
            }

            if (Name === 'custom:intermediary_name') {
              newUserObject['intermediary'] = Value ? Value : '' 
            }

            if (Name === 'custom:intermediary') {
              newUserObject['tpcode'] = Value ? Value : '' 
            }

            if (Name === 'custom:is_office') {
              newUserObject['disable_isOffice'] = Value ? Value : '' 
            }

            if (Name === 'custom:axylife_pensioen') {
              newUserObject['disable_axy_life_pensioen_rights'] = Value ? Value : '' 
            }

            if (Name === 'custom:axylife_leven') {
              newUserObject['disable_axy_life_leven_rights'] = Value ? Value : '' 
            }

            if (Name === 'custom:klantoverzicht') {
              newUserObject['disable_klantoverzicht_rights'] = Value ? Value : '' 
            }

            if (Name === 'custom:digital_archive') {
              const items = Value.split(',').filter(value => value !== '');
              items.forEach(item => {
                ['read_po', 'read_fi', 'read_pd'].forEach(attr => {
                  if (item.includes(attr)) {
                    const value = item.split(`${attr}-`).filter(itm => itm !== '')[0] === 'true';
                    newUserObject[`disable_digital_archive_${attr}`] = value
                  }
                })
              });
            }
          });
        }
        if (key === 'Groups') {
          newUserObject['groups'] = definition;
        }
      });
      if (!Object.keys(newUserObject).includes('rights')) {
        newUserObject['rights'] = 'Gebruiker'
      }
      if (!Object.keys(newUserObject).includes('groups')) {
        newUserObject['groups'] = []
      }
      newUserObject['actions'] = ['edit']
      if (portalUser.admin) {
        if (newUserObject.intermediary === portalUser.intermediaryName) {
          formattedUsers.push(newUserObject);
        }
      } else {
        formattedUsers.push(newUserObject);
      }
    });

    const filterSearch = _filter(({ username, intermediary, tpcode }) => {
      return (
        username && username.toLowerCase().search(searchValue.toLowerCase()) !== -1 ||
        intermediary && intermediary.toLowerCase().search(searchValue.toLowerCase()) !== -1 ||
        tpcode && tpcode.toLowerCase().search(searchValue.toLowerCase()) !== -1
      );
    }
    )(formattedUsers);

    const table = createTableGroup({
      items: filterSearch,
      groupLimit: rows,
      listItems: listItems['userManagment']
    });

    return Object.keys(table).length === 0 ? { 1: []} : table;
  }
);

export const intermediaryListSelector = createSelector([users, userSelector], (users, user) => {
  const intermediaryList = [];

  users.forEach(user => {
    Object.keys(user).forEach(key => {
      if (key === 'Attributes') {
        user[key].forEach(attribute => {
          const { Name, Value } = attribute;

          if (Name === 'custom:intermediary_name') {
            if (!intermediaryList.includes(Value)) {
              intermediaryList.push(Value);
            }
          }
        })
      }
    })
  });

  return intermediaryList.sort();
});

export const applicationsSelector = createSelector([userSelector, applications], (user, applications) => {
  const object = {}
  applications.forEach(item => {
    object[item['GroupName']] = item['GroupName']
  });

  // return user.admin ? user.groups : applications.map(item => item['GroupName']);
  // return user.admin ? user.groups : ['digitaal-archief', 'axy-life-leven']; // prd
  return user.admin ? user.groups : ['digitaal-archief', 'axy-life-leven', 'klantoverzicht']; // tst
});

export const userApplicationsSelector = createSelector([applications, userSelector], (applications, user) => {
  // const allApplications = applications.map(item => item['GroupName']);
  // const allApplications = ['digitaal-archief', 'axy-life-leven']; // prd
  const allApplications = ['digitaal-archief', 'axy-life-leven', 'klantoverzicht']; // tst

  const object = {
    "zilverhuis-hypotheek": true,
    "algemene-informatie": true,
    "contact": true,
  }
  if (user.groups) {
    allApplications.forEach(app => {
      object[app] = user.groups.includes(app);
    });
  }

  return object;
});
