import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, Route } from "react-router-dom";
import logo from "../../assets/images/onderlinge.png";
import { updateMessageAction } from "../../shared/actions/shared";
import RenderForm from "../../shared/components/renderForm";
import SubmitButton from "../../shared/components/submitButton";
import { StyledButton } from "../../shared/styles";
import {
  StyledFormContainer,
  StyledWrapperContainer,
} from "../../shared/styles/containers";
import {
  emailRegex,
  initialForm,
  loginForm,
  transelateErrorMessage,
} from "./constants";
import GetComponent from "./getComponent";
import ResendActivationMail from "./resendActivationMail";

const Login = ({ updateMessage, history, acceptance }) => {
  const [loading, updateLoading] = useState(false);
  const [form, updateForm] = useState(initialForm);
  const [errorCode, updateErrorCode] = useState();
  const [componentParams, updateComponentParams] = useState();
  const [validator, updateValidator] = useState({});
  const [valid, updateValid] = useState({});

  useEffect(() => {
    const validObject = {
      email: {
        valid: RegExp(emailRegex).test(form["email"]),
        errorMessage: RegExp(emailRegex).test(form["email"])
          ? ""
          : "Geen geldig email adres",
      },
      password: {
        valid: form["password"].length > 0,
        errorMessage:
          form["password"].length > 0
            ? ""
            : "Het wachtwoord moet tenminste bestaan uit acht karakters, één hoofdletter, één nummer en één speciaal karakter",
      },
    };
    updateValidator(validObject);

    updateValid(
      !Object.keys(validObject)
        .map((key) => validObject[key]["valid"])
        .includes(false)
    );
  }, [form]);

  const handleInputChange = ({ target: { name, value } }) => {
    updateForm({
      ...form,
      [name]: value,
    });
  };

  const clearComponentParams = () => {
    updateComponentParams();
    updateMessage({});
  };

  const handleConfirmResetPassword = () => {
    updateComponentParams({
      component: "verification",
      clearComponentParams,
    });
    updateMessage({});
  };

  const handlePasswordReset = () => {
    updateComponentParams({
      component: "resetPassword",
      loginForm: form,
      submitFunction: handleConfirmResetPassword,
      clearComponentParams,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateErrorCode({});
    updateLoading(true);
    Auth.signIn(form.email.toLowerCase(), form.password)
      .then((user) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          updateComponentParams({
            component: "newPassword",
            loginForm: form,
            clearComponentParams,
          });
          updateForm(initialForm);
          updateLoading(false);
        } else if (user.challengeName === "SOFTWARE_TOKEN_MFA") {
          updateErrorCode("");
          updateComponentParams({
            component: "auth",
            loginForm: form,
            cUser: user,
            clearComponentParams,
            history,
          });
          updateForm(initialForm);
          updateLoading(false);
        } else if (user.challengeName === "MFA_SETUP" || user.challengeName === undefined) {
          Auth.setupTOTP(user).then((code) => {
            updateComponentParams({
              component: "setupMFA",
              code: {
                qr: `otpauth://totp/AWSCognito:${
                  form.email
                }?secret=${code}&issuer=Onderlinge-Portal`,
                number: code,
              },
              cUser: user,
              clearComponentParams,
            });
            updateForm(initialForm);
            updateLoading(false);
          });
        }
      })
      .catch((err) => {
        updateErrorCode(err.code);
        updateLoading(false);
      });
  };

  return (
    <>
      <StyledWrapperContainer acceptance={acceptance}>
        {componentParams ? (
          <GetComponent componentParams={componentParams} />
        ) : (
          <StyledFormContainer>
            <h2>{acceptance ? 'ACCEPTATIE - Inloggen' : 'Inloggen'}</h2>
            <form onSubmit={handleSubmit}>
              <RenderForm
                form={loginForm}
                valueObject={form}
                onChange={handleInputChange}
                validator={validator}
              />
              <p className="error">{transelateErrorMessage[errorCode]}</p>
              <SubmitButton valid={valid} loading={loading} text="Login" />
              <StyledButton type="button" onClick={handlePasswordReset}>
                Wachtwoord vergeten
              </StyledButton>
              <p>
                <Link to="resendActivation">
                  Verstuur activatiemail opnieuw
                </Link>
              </p>
              <p>
                <a
                  style={{ textDecoration: "underline" }}
                  href="handleiding.pdf"
                  download="handleiding-inloggen-og-connect.pdf"
                  target="_blank"
                >
                  Handleiding inloggen
                </a>
              </p>
            </form>
          </StyledFormContainer>
        )}
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
      </StyledWrapperContainer>
      <Route
        exact
        path="/resendActivation"
        component={() => <ResendActivationMail />}
      />
    </>
  );
};

const mapStateToProps = (state, props) => ({});

const mapDispatchToProps = (dispatch) => ({
  updateMessage: (message) => dispatch(updateMessageAction(message)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
