import React from "react";

const FormattedLifeSVG = () => (
  <svg
    id="Laag_1"
    data-name="Laag 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 202.5 196.09"
  >
    <path
      className="cls-life-1"
      style={{
        fill: "none",
        stroke: "#cb3f5d",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "8px",
      }}
      d="M84.5,152.59h35.83c20.09,0,20.55-29,20.55-29H79.09l-51.72,8H1.5"
    />
    <path
      className="cls-life-1"
      style={{
        fill: "none",
        stroke: "#cb3f5d",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "8px",
      }}
      d="M1.5,194.59H115.65c18.25,0,27.75-10.55,27.75-10.55L201,127.77s-15.68-22.72-41.22-1.41L130.13,151.6"
    />
    <path
      className="cls-life-1"
      style={{
        fill: "none",
        stroke: "#cb3f5d",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "8px",
      }}
      d="M82,46.54C98.48,66.17,94.43,92.48,94.43,92.48S63.69,87,47.22,67.36,34.81,21.42,34.81,21.42,65.55,26.92,82,46.54Z"
    />
    <path
      className="cls-life-1"
      style={{
        fill: "none",
        stroke: "#cb3f5d",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "8px",
      }}
      d="M94.8,39.09C107.3,14,139.71,1.5,139.71,1.5s12.14,28.27-.36,53.39S94.43,92.48,94.43,92.48"
    />
    <line
      className="cls-life-1"
      style={{
        fill: "none",
        stroke: "#cb3f5d",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "8px",
      }}
      x1="122.72"
      y1="35.64"
      x2="94.43"
      y2="92.48"
    />
    <line
      className="cls-life-1"
      style={{
        fill: "none",
        stroke: "#cb3f5d",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "8px",
      }}
      x1="94.5"
      y1="109.59"
      x2="94.5"
      y2="92.59"
    />
  </svg>
);

export default FormattedLifeSVG;
