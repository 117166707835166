import React from 'react';
import { StyledFixedBackground, StyledListLoadingSpinner, StyledLoadingSpinner } from '../styles';
import { StyledFormSpinnerContainer } from '../styles/containers';

const CommonSpinner = () => (
  <StyledFixedBackground>
    <StyledLoadingSpinner autoHeight={true}>
      <div className="wrapper" />
      <div className="loading-container">
        <div className="loader" />
      </div>
    </StyledLoadingSpinner>
  </StyledFixedBackground>
);

const SubmitSpinner = () => (
  <StyledLoadingSpinner submitSpinner={true}>
    <div className="loading-container">
      <div className="loader" />
    </div>
  </StyledLoadingSpinner>
);

const FormSpinner = () => (
  <StyledFormSpinnerContainer>
    <StyledLoadingSpinner formSpinner={true}>
      <div className="loading-container">
        <div className="loader" />
      </div>
    </StyledLoadingSpinner>
  </StyledFormSpinnerContainer>
)

const ListSpinner = () => <StyledListLoadingSpinner />

const getSpinner = (type) => {
  const spinners = {
    submit: <SubmitSpinner />,
    common: <CommonSpinner />,
    form: <FormSpinner />,
    list: <ListSpinner />
  }

  return spinners[type];
};

export default getSpinner;
