import styled from 'styled-components';


export const StyledEnviromentIndicator = styled.div`
  background-color: orange;
  width: 200px;
  height: 30px;
  position: absolute;
  left: 0;
  top: 0
  z-index: 1100;
  font-size: 20px;
  text-align: center;
  transform: translateX(-45px) translateY(30px) rotate(-40deg)
`

export const StyledWrapper = styled.div`
  max-width: 80%;
  margin: 0 auto;
`

export const StyledContainer = styled.div`
  max-width: 80%;
  margin: 0 auto;
`
export const StyledInputContainer = styled.div`
  padding: 3px 0px 10px 0px;
  font-size: 1.0em;
  display: flex;
  flex-direction: column;
  height: 100px;
  overflow: scroll;
`
export const StyledInfoBox = styled.div`
  position absolute;
  width: ${props => props.width ? `${props.width}px` : '70%'};
  min-height: 60px;
  height: auto;
  background-color: #fff;
  border-radius: 5px;

  p {
    padding: 0 6px;
  }
`

export const StyledConfirm = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);

  .container {
    width: 40%;
    min-height: 500px;
    background-color: ${props => props.theme.color.white};
    margin: 170px auto 0 auto;

    .table-container {
      td {
        padding-left: 10px;

        &.bold {
          font-weight: 900;
        }
      }
    }

    button {
      float: right;
      margin-top: -35px;
      margin-right: 30px;
      width: 80px;
      height: 25px;
      cursor: pointer;
    }
  }

  .confirm-container {
    width: 550px
    height: auto;
    min-height: 250px;
    background-color: ${props => props.theme.color.white};
    margin: 170px auto 0 auto;
    text-align: center;
    position: relative;
    border-radius: 10px;

    .radio-button-container {
      position: absolute;
      top: 10px;
      right: 10px;

      .radio-container {
        padding-right: 10px;
        float: left;
      }

      label {
        float: left;
        margin-top: 8px;
      }

      input {
        float: left;
        cursor: pointer;
      }

      input[type='radio'] {
        -webkit-appearance: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        outline: none;
        border: 3px solid gray;
      }
    }

    input[type='radio']:before {
        content: '';
        display: block;
        width: 60%;
        height: 60%;
        margin: 20% auto;
        border-radius: 50%;
    }

    input[type="radio"]:checked:before {
      background: ${props => props.theme.color.red};
    }

    input[type="radio"]:checked {
      border-color: ${props => props.theme.color.red};
    }
  }


    h2 {
      padding: 10px 0;
      border-bottom: 1px solid #aeaeae;
      float: none;

      span.name {
        color: #ff4500;
      }

      span.id {
        font-size: .8em;
        color: #6a6459;
        font-weight: normal;
      }
    }

    ul {
      list-style-type: none;
      float: left;
      padding-left: 10px;
      text-align: left;

      &.multiple-list {
        padding-left: 0;
        width: 100%;

        .list-container {
          padding-bottom: 5px;
          padding-left: 10px;
          border-bottom: 1px solid #8f8f8f;

          &:not(:first-child) {
            padding-top: 5px;
          }
        }
      }

      li {
        span {
          font-weight: 900;
        }
      }
    }

    input {
      width: 60%;
      margin-top: 5px;
    }

    label {
      text-align: left;

      span {
        color: #ff4500;
      }
    }

    .label-id {
      float: left;
      padding: 10px;
    }

    p {
      font-size: 20px;
    }
  }
`

export const StyledRadioButtonContainer = styled.div`
  display: inline;
  top: 10px;
  right: 10px;

  .radio-container {
    padding-right: 10px;
    float: left;
  }

  label {
    float: left;
    margin-top: 5px;
  }

  input {
    float: left;
    cursor: pointer;
  }

  input[type='radio'] {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    outline: none;
    border: 3px solid gray;
  }

  input[type='radio']:before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      margin: 2px -8px
      border-radius: 50%;
  }

  input[type="radio"]:checked:before {
    background: ${props => props.theme.color.orange};;
  }

  input[type="radio"]:checked {
    border-color: ${props => props.theme.color.orange};
  }
`

export const StyledInput = styled.input`
  padding: 10px;

  &[type="file"] {
    display: none;
  }

  ${props => props.height && `
    height: ${props.height}px !important;
  `}

  ${props => (props.margin || props.margin === 0) && `
    margin: ${props.margin}px !important;
  `}

  ${props => props.width && `
    width: ${props.width}%;
  `}

  &:focus {
    outline: none;
  }

  &:read-only {
    background-color: ${props => props.theme.color.readOnlyBackgroundColor};
    border: 1px solid #bdbdbd;
    color: #696969;
    cursor: auto;
  }

  ${props => props.invalid && `
    border: 1px solid red;
  `}
`

export const StyledActionButton = styled.button`
  bottom: 15px;
  color: ${props => props.theme.color.buttonColor};
  background-color: ${props => props.theme.color.red}
  border: 1px solid ${props => props.theme.color.buttonBorder};
  padding: 10px;
  margin: 0 2px;
  border-radius: 5px;
  font-size: 1rem;
  text-transform: uppercase;

  &:hover:not([disabled]) {
    cursor: pointer;
    opacity: .8;
  }

  &:disabled {
    opacity: .5;
  }

  outline: none;

  ${props => props.loading && `
    width: 60px;
    background-color: transparent;
    border: none
`}
`

export const StyledButton = styled.button`
  position: relative;
  bottom: 15px;
  color: ${props => props.theme.color.buttonColor};
  background-color: ${props => props.theme.color.buttonBackground}
  background-color: ${props => props.theme.color[props.color ? props.color : 'buttonBackground' ]}
  border: 1px solid ${props => props.theme.color.buttonBorder};
  padding: 10px;
  margin: 0 2px;
  border-radius: 5px;
  font-size: 1rem;
  margin-top: 25px;
  text-transform: uppercase;

  width: ${props => props.width ? `${props.width}px` : 'auto'}

  &:hover:not([disabled]) {
    cursor: pointer;
    opacity: .8;
  }

  &:disabled {
    opacity: .5;
  }

  outline: none;

  ${props => props.navButton && `
    position: absolute;
    bottom: 0;
    text-transform: capitalize;
  `}

  ${props => props.right && `
    right: ${props.right}px;
  `}

  ${props => props.isLoading && `
    width: 60px;
    background-color: transparent;
    border: none
  `}

  a {
    text-decoration: none;
    color: ${props => props.theme.color.white}
  }
`

export const StyledMFAContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;

  .wrapper {
    max-width: 520px
    max-height: 500px;
    height: auto;
    margin: 0 auto;
    width: 100%;
    margin-top: 151px;
    background-color: ${props => props.theme.color.white}
    text-align: center;
    border-radius: 7px;

    .qr-container {
      padding-top: 30px;

      input {
        padding: 10px;
        outline: none;
        display: block;
        margin: 0 auto;
        text-align: center;

        &.mfa-code-input {
          width: 72%;

          &::selection {
            background-color: ${props => props.theme.color.lightGrey}
          }
        }
      }

      .mfa-code-container {
        position: relative;
        height: 85px;

        .copy-button {
          float: right;
          margin-right: 62px;
          margin-top: 5px;
          padding: 3px;
          color: ${props => props.theme.color.buttonColor};
          background-color: ${props => props.theme.color.buttonBackground}
          border: 1px solid ${props => props.theme.color.buttonBorder};
          border-radius: 5px;

          outline: none;
        }
      }

      form {
        padding-bottom: 90px;

        .button-container {
          padding-bottom: 10px;
          position: relative;
        }

        input {
          width: 180px;
          ${props => props.error && `
            border: 1px solid red;
          `}
        }

        p.error {
          padding: 10px;
          color: red;
        }
      }
    }
  }
`;

export const StyledLoadingSpinner = styled.div`
  margin: 0 auto;

  ${props => props.autoHeight && `
    height: 100vh
  `}

  ${props => props.submitSpinner && `
    position: absolute;
    top: 18px;
  `}

  ${props => props.formSpinner && `
    margin-top: 50%;
  `}

  .wrapper {
    height: 45%;
    width: 100%;
  }

  .loading-container {
    margin-top: -32px;
    text-align: center;

    .loader {
      display: inline-block;
      animation: spin 2s linear infinite;
      border: 5px solid ${prop => prop.theme.color.lightGrey};
      border-radius: 50%;
      border-top: 5px solid ${prop => prop.theme.color.red};
      outline: none;
      height: ${props => props.submitSpinner ? `30px` : `70px`};
      width: ${props => props.submitSpinner ? `30px` : `70px`};
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

export const StyledListLoadingSpinner= styled.div`
  display: inline-block;
  animation: spin 2s linear infinite;
  border: 5px solid ${prop => prop.theme.color.lightGrey};
  border-radius: 50%;
  border-top: 5px solid ${prop => prop.theme.color.red};
  height: 20px;
  outline: none;
  width: 20px;
  margin-top: 5px;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const StyledFixedBackground = styled.div`
  position: fixed;
  overflow: scroll;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.6);
  z-index: 9999;
`;

export const StyledContentContainer = styled.div`
  background-color: ${props => props.mainBackgroundColor};
  // width: ${props => props.toggle ? '100%' : 'calc(100% - 250px)'};
  // width: calc(100% - 250px);
  // margin: 0px 0px 0px 250px;

  .content {
    width: 80%;
    margin: auto;
  }

  .paging {
    position: absolute;
    width: 100%;
    bottom: 20px;
    border-top: 1px solid #c3c3c3;
    padding-top: 10px;

    ul {
      list-style-type: none;
      margin: 0;

      li {
        display: inline-block;
        float: right;
      }
    }
  }
`;

export const StyledContactContainer = styled.div`
  padding-top: 20px;

  width: 60%;
  padding: 10px;
  border: 1px solid ${props => props.theme.color.sideNavigation};
  border-radius: 5px;
  background-color: ${props => props.theme.color.lightGrey};
  margin-bottom: 15px;

  h2 {
    font-size: 23px;
  }
  a {
    color: ${props => props.theme.color.orange}
    font-size: 20px;
  }

  p {
    font-size: 18px;
    margin: 0;
    padding-top: 5px;

    span {
      display: block;
    }
  }
`

export const StyledMainScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: center;
  margin-top: 110px;

  .link-container {
    text-align: center;
    a {
      font-size: 20px;
      color: ${props => props.theme.color.buttonColor}
    }
  }

  .header {
    padding-bottom: 25px;

    h2 {
      padding-bottom: 5px;
    }

    p {
      margin: 2px 0;
    }
    span {
      margin-top: 5px;
      a {
        color: ${props => props.theme.color.buttonColor}
      }
    }


    .disclaimer {
      h2 {
        font-size: 30px;
      }

      .content-container {
        padding-top: 20px;
        h2 {
          font-size: 23px;
        }
        p {
          padding-top: 10px;
          font-size: 20px;
          margin-left: 25px;

          span {
            display: block;
            margin: 20px;
          }
        }
      }
    }
  }
`

export const CheckboxContainer = styled.div`
  position: relative;
  margin-top: 10px;


  svg {
    position: absolute;
    margin-left: 5px;
    top: -2px;

    &:hover {
      fill: ${props => props.theme.color.red};
    }
  }

`

export const StyledCheckBox = styled.label`
  ${props => props.displayBlock && `
    display: block;
  `}
  ${props => props.displayInitial && `
    display: initial
  `}
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border: 1px solid #b5b5b5;

    ${props => props.disabled && `
      opacity: .2;
      cursor: default;
    `}
  }

  a {
    color: ${props => props.theme.color.orange}
  }

  &:hover input ~ .checkmark {
    background-color: #f0f0f0;
  }

  input:checked ~ .checkmark {
    background-color: ${props => props.theme.color.red};
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

export const StyledMessageContainer = styled.div`
  max-width 520px;
  margin: 150px auto;
  padding: 15px;
  background-color: ${props => props.theme.color.owhite};
  border-radius: 5px;
  color: ${props => props.theme.color.darkGrey};
  position: relative;

  .button {
    margin-top: 60px;
  }

  h2 {
    padding-bottom: 15px;
    margin: 0;
  }

  span {
    color: ${props => props.theme.color.orange};
  }

  p.message {
    margin-top: 0px;
  }

  .link-container {
    display: flex;
    flex-direction: column;

    button {
      padding: 10px;
      width: 45%;
      font-size: 15px;
      bottom: 0px;
      color: ${props => props.active ? props.theme.color.orange : props.theme.color.buttonColor};
      background-color: ${props => props.theme.color.buttonBackground}
      border: 1px solid ${props => props.theme.color.buttonBorder};
      cursor: pointer;
    }

    .button {
      margin: 15px 0;

      a {
        padding: 10px;
        color: ${props => props.theme.color.buttonColor};
        background-color: ${props => props.theme.color.buttonBackground}
        border: 1px solid ${props => props.theme.color.buttonBorder};
        border-radius: 5px;
        text-decoration: none;
      }
    }

    a {
      color: ${props => props.theme.color.darkGrey};
      padding: 3px 0;

      &:hover {
        color: ${props => props.theme.color.orange};
        opacity: .8;
      }
    }
  }
`

export const StyledInfoContainer = styled.div`
  width: 560px;
  height: auto;
  background-color: ${props => props.theme.color.lightGrey};
  margin: 100px auto 0 auto;
  text-align: left;
  position: relative;
  border-radius: 10px;
  padding: 40px;
  h2 {
    text-align: left;
    padding-bottom: 15px;
  }
`
