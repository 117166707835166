import React from "react";

const OnderlingeGebruikersfSVG = () => (
<svg id="Laag_2" data-name="Laag 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.8 58.08">
  <defs>
    {/* <style>
      .users-1 {
        fill: #cc3f5e;
      }

      .users-2 {
        fill: #fff;
      }
    </style> */}
  </defs>
  <path className="users-1" d="M292.72,416.13a5.21,5.21,0,1,0,5.26-5.22A5.2,5.2,0,0,0,292.72,416.13Zm5.22,26.53c4,0,7.92,0,11.87,0,.67,0,1.22.1,1.2-.93s-.42-1-1.15-1q-11.94,0-23.88,0c-.77,0-1.12.1-1.13,1s.43.92,1.09.91C289.94,442.65,293.94,442.66,297.94,442.66Zm0-20.42c-1.4,0-2.8,0-4.19,0a2.61,2.61,0,0,0-2.7,2.63c-.06,1.39,0,2.79,0,4.18,0,.75.38.75.89.72s1.07.17,1-.71c-.05-1.23,0-2.47,0-3.71,0-.83.32-1.24,1.21-1.23,2.51,0,5,0,7.54,0,1,0,1.27.43,1.25,1.31,0,1.16,0,2.32,0,3.47,0,.83.35.9,1,.89s1,0,.93-.82c-.06-1.31,0-2.63,0-4a2.7,2.7,0,0,0-2.91-2.79Zm-.13,14.06c2.95,0,5.91,0,8.86,0,.72,0,1.06-.09,1.05-1s-.28-1-1-1c-5.87,0-11.73,0-17.6,0-.67,0-1,.06-1,.88s.05,1.07.94,1.05C292,436.26,294.91,436.3,297.82,436.3Z" transform="translate(-279.55 -391.27)"/>
  <path className="users-1" d="M292,401.12c0,.15.07.31.1.46.22,1.17-.18,1.55-1.43,1.5-2.75-.11-5.52,0-8.27-.05-.71,0-1,.14-1,.91q0,21.29,0,42.58c0,.79.29.89,1,.89q15.54,0,31.07,0c.74,0,.93-.21.93-.94q0-21.23,0-42.46c0-.79-.22-1-1-1-2.92,0-5.84,0-8.76,0-.33,0-.94.26-.9-.4s-.47-1.35.55-1.55h9.6c2.39.18,2.39.18,2.39,2.57q0,20.44,0,40.88c0,.71,0,1.43,0,2.15-.17.78.17,1.62-.29,2.36l-.71.3c-5.78,0-11.56,0-17.33,0-5.42,0-10.84,0-16.26,0a4.77,4.77,0,0,0-.84,0c-1.06.16-1.37-.31-1.37-1.34q0-22.71,0-45.44c0-.95.32-1.4,1.25-1.44Z" transform="translate(-279.55 -391.27)"/>
  <path className="users-1" d="M301.93,403.74c-.1.8-.3,1.71,1,1.4.73-.18.84.14.81.72S304,407,303,407c-2.51-.06-5,0-7.53,0-.76,0-1.52,0-2.28,0-.48,0-1.14.28-1.13-.71,0-.8.14-1.18,1-1.07s.9-.47.9-1.14c0-4.15,0-8.29.06-12.44h0l1.38-.3a42.37,42.37,0,0,0,5,0,1.36,1.36,0,0,1,1.46,1.43ZM300,399.11v-2.75c0-3.2,0-3.18-3.15-3.15-.73,0-1,.18-.95.93,0,2.59,0,5.19,0,7.78,0,3.29,0,3.26,3.26,3.15.73,0,.85-.3.84-.93C300,402.47,300,400.79,300,399.11Z" transform="translate(-279.55 -391.27)"/>
  <path className="users-1" d="M292.72,416.13a5.21,5.21,0,1,1,5.16,5.22A5.17,5.17,0,0,1,292.72,416.13Zm5.2-3.32a3.29,3.29,0,0,0-3.3,3.37,3.31,3.31,0,0,0,6.61,0A3.29,3.29,0,0,0,297.92,412.81Z" transform="translate(-279.55 -391.27)"/>
  <path className="users-1" d="M297.94,442.66c-4,0-8,0-12,0-.66,0-1.1,0-1.09-.91s.36-1,1.13-1q11.94,0,23.88,0c.73,0,1.14.05,1.15,1s-.53.93-1.2.93C305.86,442.65,301.9,442.66,297.94,442.66Z" transform="translate(-279.55 -391.27)"/>
  <path className="users-1" d="M298,422.24h3.95a2.7,2.7,0,0,1,2.91,2.79c0,1.32,0,2.64,0,4,0,.78-.34.81-.93.82s-1-.06-1-.89c.07-1.15,0-2.31,0-3.47,0-.88-.29-1.32-1.25-1.31-2.51,0-5,0-7.54,0-.89,0-1.22.4-1.21,1.23,0,1.24,0,2.48,0,3.71,0,.88-.55.69-1,.71s-.92,0-.89-.72c.06-1.39,0-2.79,0-4.18a2.61,2.61,0,0,1,2.7-2.63C295.15,422.21,296.55,422.24,298,422.24Z" transform="translate(-279.55 -391.27)"/>
  <path className="users-1" d="M297.82,436.3c-2.91,0-5.82,0-8.74,0-.89,0-.9-.37-.94-1.05s.3-.88,1-.88c5.87,0,11.73,0,17.6,0,.74,0,1,.14,1,1s-.33,1-1.05,1C303.73,436.28,300.77,436.3,297.82,436.3Z" transform="translate(-279.55 -391.27)"/>
  <path className="users-2" d="M297.92,412.81a3.29,3.29,0,0,1,3.31,3.36,3.31,3.31,0,0,1-6.61,0A3.29,3.29,0,0,1,297.92,412.81Z" transform="translate(-279.55 -391.27)"/>
</svg>

);

export default OnderlingeGebruikersfSVG;
