import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { apiCallUnAuthenticated } from "../../api";
import {
  allAppicationsHandlerAction,
  allUsersHandlerAction,
} from "../../shared/actions/cognito";
import { sessionErrorAction } from "../../shared/actions/error";
import { globalLoadingAction } from "../../shared/actions/shared";
import { userInfoHandlerAction } from "../../shared/actions/user";
import Message from "../../shared/components/message";
import RenderForm from "../../shared/components/renderForm";
import SubmitButton from "../../shared/components/submitButton";
import {
  authenticatedUser,
  userSessionSelector,
} from "../../shared/selectors/user";
import { StyledButton } from "../../shared/styles";
import { StyledFormContainer } from "../../shared/styles/containers";
import {
  authenticationForm,
  initialAuthenticationForm,
  transelateErrorMessage,
} from "./constants";

const Authentication = ({
  cUser,
  loginForm,
  userInfoHandler,
  globalLoadingHandler,
  clearComponentParams,
  sessionErrorHandler,
  allUsersHandler,
  allAppicationsHandler,
}) => {
  const [form, updateForm] = useState(initialAuthenticationForm);
  const [errorCode, updateErrorCode] = useState();
  const [loading, updateLoading] = useState();
  const [validator, updateValidator] = useState({});
  const [valid, updateValid] = useState({});
  const [message, updateMessage] = useState({});

  useEffect(() => {
    const validObject = {
      code: {
        valid: form.code.length > 0,
        errorMessage:
          form.code.length === 6 ? "" : "De code bestaat uit 6 cijfers.",
      },
    };
    updateValidator(validObject);

    updateValid(
      !Object.keys(validObject)
        .map((key) => validObject[key]["valid"])
        .includes(false)
    );
  }, [form]);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateLoading(true);
    updateErrorCode({});

    const AmazonCognitoIdentity = require("amazon-cognito-identity-js");

    const data = {
      UserPoolId: cUser.pool.userPoolId,
      ClientId: cUser.pool.clientId,
    };

    const userPool = new AmazonCognitoIdentity.CognitoUserPool(data);

    const userData = {
      Username: cUser.username,
      Pool: userPool,
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    const authenticationData = {
      Username: cUser.Username,
      Password: loginForm.password,
    };

    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
      authenticationData
    );

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async function() {
        updateLoading(false);
        globalLoadingHandler(true);
        authenticatedUser({
          userInfoHandler,
          updateLoading: globalLoadingHandler,
          allUsersHandler,
          allAppicationsHandler,
        });
        userSessionSelector({
          sessionTimeout: sessionErrorHandler,
        });
        window.location = "/dashboard/applications";
      },
      onFailure: function(err) {
        updateErrorCode(err.code);
        updateLoading(false);
      },
      totpRequired: function() {
        updateLoading(false);
        cognitoUser.sendMFACode(form.code, this, "SOFTWARE_TOKEN_MFA");
      },
    });
  };

  const handleFormChange = ({ target: { name, value } }) => {
    updateForm({ ...form, [name]: value });
  };

  const resetMfa = async () => {
    const response = await apiCallUnAuthenticated({
      path: "unauth/reset-mfa-request",
      method: "post",
      body: {
        username: loginForm.email,
      },
    });

    if (response) {
      updateMessage({
        title: "MFA reset aangevraagd",
        body: `Er is een mail verzonden naar ${loginForm.email}.`,
        button: true,
        submitFunction: clearComponentParams,
        linkLabel: "Volgende",
      });
    }
  };

  return (
    <>
      <StyledFormContainer>
        <h2 style={{ paddingBottom: "5px" }}>Multi-factor authenticatie</h2>
        <p style={{ marginTop: "0px" }}>
          De code bestaat uit 6 cijfers en staat in uw authenticatieapp
        </p>
        <form onSubmit={handleSubmit}>
          <RenderForm
            form={authenticationForm}
            valueObject={form}
            onChange={handleFormChange}
            validator={validator}
            autoSelect={false}
          />
          <p className="error">{transelateErrorMessage[errorCode]}</p>
          <SubmitButton valid={valid} loading={loading} text="Bevestig" />
          <StyledButton type="button" onClick={clearComponentParams}>
            Terug
          </StyledButton>
          <div style={{float: "clear"}}/>
          <StyledButton type="button" onClick={() => resetMfa()}>
            MFA opnieuw instellen
          </StyledButton>
        </form>
      </StyledFormContainer>
      {Object.keys(message).length > 0 && <Message message={message} />}
    </>
  );
};

const mapStateToProps = (state, props) => ({});

const mapDispatchToProps = (dispatch) => ({
  userInfoHandler: (user) => dispatch(userInfoHandlerAction(user)),
  globalLoadingHandler: (loading) => dispatch(globalLoadingAction(loading)),
  allUsersHandler: (users) => dispatch(allUsersHandlerAction(users)),
  allAppicationsHandler: (applications) =>
    dispatch(allAppicationsHandlerAction(applications)),
  sessionErrorHandler: (message) => dispatch(sessionErrorAction(message)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Authentication));
