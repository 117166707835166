import styled from 'styled-components';


export const StyledNavigation = styled.nav`
  align-items: center;
  font-size: 1.1em;
  font-family: ave, sans-serif;
  width: 100%;
  height: 135px;
  z-index: 1000;
  top: 0;
  left: 0;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#bfbfbf+0,000000+100&0.65+0,0+100 */
  background: -moz-linear-gradient(top,  rgba(191,191,191,0.65) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  rgba(191,191,191,0.65) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  rgba(191,191,191,0.65) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6bfbfbf', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */


  // border-bottom: 1px solid #efefef;
  // margin-bottom: 45px;
  position: relative;

  .header {
    width: 100%;
    height: 65px;
    position: relative;
    max-width: 100%;
    margin: 0 auto;

    .header-text {
      position: absolute;
      margin-left: 360px;
      padding-top: 35px;

      h2 {
        color: ${props => props.theme.color.red};
      }

      p {
        color: ${props => props.theme.color.blue};
        margin-top: 10px;
        font-size: 20px;
      }
    }

    .logo {
      // width: 290px;
      // margin-left: -75px;
      // margin-top: -30px;
      width: 15%;
      height: 135px;
      float: left;
      z-index: 1000
      background-color: transparent;


      img {
        width: 160px;
        padding: 12px 60px;
        margin: auto;
        cursor: pointer;
      }
    }
  }

  @media only screen and (max-width : 768px) {
    transition: none;
    background-color: ${props => props.theme.color.lightGrey};
    border-bottom: .5px solid ${props => props.theme.color.darkGrey};
    z-index: 1000;

    .header {
      padding: 0;
      height: 0;

      .menu-icon {
        display: block;
        position: absolute;
        background-color: transparent;
        border: none;
        outline: none;
      }

      .logo {
        text-align: center;
        width: 100%;
        float: none;
        margin-left: 0;
      }

      .menu {
        float: none;
        text-align: center;
        background-color: #888;
        margin-top: 10px;
        padding-bottom: 10px;
        position: relative;
        left: 0px;
        top: -2px;
        z-index: -1;
        width: 100%;
        transition: max-height, .5s;
        padding: 0;
        max-height: ${props => !props.toggle ? '0' : '500px'};
        overflow: hidden;
        border-top: 1px solid ${props => props.theme.color.darkGrey};

        ul {
          li {
            display: block;
            padding: 15px 0;
            border-bottom: 1px solid ${props => props.theme.color.black};

            a {
              width: 100%;
              display: block;
            }

            &.extern-link {
              margin-left: 0;
              border: none;
              border-bottom: 1px solid ${props => props.theme.color.black};
              border-radius: none !important;
              padding: 15px 0;

              &:hover {
                margin-left: 0;
                border: none;
                border-bottom: 1px solid ${props => props.theme.color.black};
                border-radius: none !important;
                padding: 15px 0;
                background-color: inherit;
              }
            }

            &.contact {
              padding-bottom: 15px;

              button {
                color: ${props => props.theme.color.white};
                border none;
                width: 100%;
                display: block;

                &:hover {
                  background-color: inherit;
                }
              }
            }

            a {
              color: ${props => props.theme.color.white};
            }
          }
        }
      }
    }
  }
`;


export const StyledListItem = styled.li`
  padding: 0 10px;
  display: inline-block;

  a {
    text-decoration: none;
    color: ${props => props.active ? props.theme.color.red : props.theme.color.darkGrey};
    cursor: pointer;
    font-size: 1.2em;

    &:hover {
      color: ${props => props.theme.color.white};
    }
  }

  &.extern-link {
    padding: 5px 5px 5px 20px;
    margin-left: 25px;
    font-size: .8em;
    border-left: 1px solid ${props => props.theme.color.darkGrey};

    a {
      color: ${props => props.theme.color.blue};
    }

    &:hover {
      a {
        color: ${props => props.theme.color.darkGrey};
      }
    }
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: inherit;
    color: ${props => props.theme.color.darkGrey};
    outline: none;
    padding: 5px;
    border-radius: 5px;
    height: 35px;
    font-size: 1.2em;

    &:hover {
      background-color: #ebebeb;
    }
  }
`
