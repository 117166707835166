import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router";
import { Route } from "react-router-dom";
import { apiCall } from '../../api';
import { StyledApplicationWrapper } from '../../shared/styles/containers';
import { hasSuccess } from '../../shared/utils';
import Applications from './applications';
import UserManagement from './userManagement';

const Dashboard = ({ user, history }) => {
  // const [ isSuperAdmin, updateIsSuperAdmin ] = useState(false);
  const [ initMessage, updateInitMessage ] = useState();

  function useEffectAsync(effect, inputs) {
    useEffect(() => {
       effect();
     }, inputs);
 }

 useEffectAsync( async () => {
    if (window.location.pathname !== '/dashboard/applications') {
      history.push('/dashboard/applications')
    }

    const response = await apiCall({
      path: 'message/get',
      method: 'post',
      body: {
        message: 'main',
      }
    });

    if (Object.keys(response).includes('body')) {
      updateInitMessage(JSON.parse(response['body']));
    }
    // updateIsSuperAdmin(
    //   user['superAdmin'] ? user['superAdmin'] : false
    // );
  }, []);


  return (
    <StyledApplicationWrapper>
      {window.location.pathname === '/dashboard/applications' && (
        <Applications history={history} initMessage={initMessage} />
      )}
      {(user.superAdmin || user.admin) && (
        <Route exact path="/dashboard/beheer" component={(history) => <UserManagement history={history} />} />
      )}
    </StyledApplicationWrapper>
)};

export default withRouter(Dashboard);
