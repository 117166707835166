import Amplify from 'aws-amplify';
import { createBrowserHistory as createHistory } from "history";
import React from 'react';
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from 'styled-components';
import './App.css';
import awsmobile from './aws-exports';
import Root from './components/root';
import createReduxStore from "./shared/store";
import theme from './shared/styles/theme';

Amplify.configure(awsmobile);

export const history = createHistory();
export const store = createReduxStore();

const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Router>
        <Root history={history} />
      </Router>
    </ThemeProvider>
  </Provider>
);

export default App;
