import styled from "styled-components";
import { map, range } from 'lodash/fp';

import propTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { StyledTableContainer } from '../styles/containers';
import { StyledActionButton, StyledAddButtonContainer, StyledBodyTH, StyledBodyTR, StyledPaging, StyledPagingButton, StyledRowsPerPageContainer, StyledShowTh, StyledTBody, StyledTh, StyledThead, StyledTheadButton, StyledTr, StyledUserTable } from '../styles/table';
import getSvg from '../svg/getSvg';
import CheckBox from './checkBox';
import SearchInput from './searchInput';
import Select from './select';
import getSpinner from '../../shared/components/loadingSpinner';
import { translateApps } from '../../components/dashboard/applications';
import { StyledButton } from '../../shared/styles';

const StyledFilterContainer = styled.div`
  width: 100%;

  ul {
    list-style-type: none;

    li {
      float: right;

      &.selection {
        margin-right: 50px;
        margin-top: -35px;
      }
    }
  }

  .filter {
    width: 220px;
  }
`;

export const PagingComponent = ({ handlePaging, items, page }) => {
  const [internalPages, updateInternalPages] = useState({});
  const [pageIndex, updatePageIndex] = useState();
  const [latestIndex, updateLatestIndex] = useState();

  const reset = () => {
    updateInternalPages({});
  };

  useEffect(() => {
    reset();
  }, [items]);

  useEffect(() => {
    const totalLength = Object.keys(items).length;
    const totalPages = range(totalLength, 0);
    let numbers = [];

    const internalPages = {};

    totalPages.forEach((pageNumber) => {
      const divider = (pageNumber - 1) / 4;

      if (Math.floor(divider) === divider) {
        internalPages[divider] = [...numbers, pageNumber];
        updateInternalPages(internalPages);
        numbers = [];
      } else {
        numbers.push(pageNumber);
      }
    });

    updateLatestIndex(
      totalLength > 4 ? Math.max(...Object.keys(internalPages)) : 0
    );

    Object.keys(internalPages).forEach((key) => {
      if (internalPages[key].includes(page)) {
        updatePageIndex(parseInt(key));
      }
    });
  }, [page, items]);

  const handleInternalPaginge = (p) => {
    handlePaging(p);
  };

  return (
    <StyledPaging>
      <ul>
        {pageIndex !== latestIndex && (
          <>
            <li>
              <StyledPagingButton
                onClick={() => handleInternalPaginge(page + 1)}
              >
                volgende
              </StyledPagingButton>
            </li>
            <li>
              <StyledPagingButton
                onClick={() => handleInternalPaginge(Object.keys(items).length)}
              >
                {Object.keys(items).length}
              </StyledPagingButton>
            </li>
            <li>...</li>
          </>
        )}
        {map((pageNumber) => (
          <li key={pageNumber}>
            <StyledPagingButton
              type="button"
              active={pageNumber === page}
              onClick={() => handleInternalPaginge(pageNumber)}
            >
              {pageNumber}
            </StyledPagingButton>
          </li>
        ))(internalPages[pageIndex])}
        {page > 4 && (
          <>
            <li>...</li>
            <li>
              <StyledPagingButton onClick={() => handleInternalPaginge(1)}>
                {1}
              </StyledPagingButton>
            </li>
            <li>
              <StyledPagingButton
                onClick={() => handleInternalPaginge(page - 1)}
              >
                vorige
              </StyledPagingButton>
            </li>
          </>
        )}
      </ul>
    </StyledPaging>
  );
};

export const RowsPerPageComponent = ({ handleChange, groups, selectedRow }) => (
  <StyledRowsPerPageContainer>
    <Select
      label="Aantal items"
      options={groups > 1 && groups === 2 ? [15, 25] : [15, 25, 50]}
      selectedItem={selectedRow}
      selectHandler={handleChange}
    />
  </StyledRowsPerPageContainer>
)

export const ListComponent = ({
  user,
  searchValue,
  handleSearch,
  listItems,
  actionHandler,
  bodyItems,
  openScreenHandler,
  handlePaging,
  selectedRow,
  filterDirection,
  filterTableHandler,
  assignForm,
  addProfie,
  checkboxValues,
  handleCheckUsers,
  userUpdateLoader,
  title,
  translateListItems,
  page,
  handleRowsPergPage,
  loadingItem,
  loadingKey,
  disabledAction,
  isLoadingUsers
}) => {
  const [showId, updateShowId] = useState(false);
  const [groups, updateGroups] = useState(1);

  useEffect(() => {
    updateGroups(Object.keys(bodyItems).length)
  }, []);

  const show = ({ hover, id }) => {
    if (hover) {
      updateShowId(id);
    } else {
      updateShowId()
    }
  }

  return (
    <StyledTableContainer style={{ width: assignForm && '98%', minHeight: assignForm && '560px', marginTop: addProfie && '20px' }}>
      <h2 style={{ float: 'left' }}>
        {title}
      </h2>
      {user['superAdmin'] && (
        <StyledButton onClick={() => openScreenHandler('resetUserMfa')} style={{ position: "absolute", top: "-37px", bottom: "unset", right: "365px", textTransform: 'none' }}>
          Reset gebruikers MFA
        </StyledButton>
      )}
      {user['superAdmin'] && (
        <StyledButton onClick={() => openScreenHandler('uploadUserSheet')} style={{ position: "absolute", top: "-37px", bottom: "unset", right: "200px", textTransform: 'none' }}>
          Upload usersheet
        </StyledButton>
      )}
      <SearchInput
        value={searchValue}
        handleSearch={handleSearch}
      />
      {isLoadingUsers && getSpinner('common')}
      <StyledUserTable>
        <StyledThead>
          <StyledTr>
            {assignForm && (
              <StyledTh>
                <StyledTheadButton onClick={filterTableHandler}>
                  Assign
                </StyledTheadButton>
              </StyledTh>
            )}
            {listItems.filter(key => !key.includes('disable_')).map((definition, index) => {
              return (
                <React.Fragment key={definition}>
                  {!assignForm ? (
                    <StyledTh key={`${definition}-`}>
                      <StyledTheadButton style={{ cursor: 'auto' }}   onClick={filterTableHandler}>
                        {translateListItems[definition]}
                      </StyledTheadButton>
                    </StyledTh>
                  ) : (
                      <React.Fragment key={`${definition}-${index}`}>
                        {assignForm && definition !== 'actions' && (
                          <StyledTh key={`${definition}-${definition}`}>
                            <StyledTheadButton onClick={filterTableHandler}>
                              {definition}
                            </StyledTheadButton>
                          </StyledTh>
                        )}
                      </React.Fragment>
                    )}
                </React.Fragment>
              )
            })}
          </StyledTr>
        </StyledThead>
        <StyledTBody>
          {bodyItems[page].map((bodyObject, index) => (
            <StyledBodyTR key={`${bodyObject['email']}ds${index}`}>
              {assignForm && (
                <StyledTh>
                  <CheckBox
                    displayInitial={true}
                    checked={checkboxValues[bodyObject['email']] !== undefined && checkboxValues[bodyObject['email']]}
                    onChange={(e) => handleCheckUsers(e, bodyObject)}
                  />
                </StyledTh>
              )}
              {Object.keys(bodyObject).filter(key => !key.includes('disable_')).map(key => {
                let definition = bodyObject[key];
                if ( key === 'groups') {
                  definition = definition.map((item, index) => `${translateApps[item]}${definition.length === (index + 1) ? '' : ', '}`).filter(item => item !== 'undefined, ');
                }
                return (
                  <React.Fragment key={`${key}-${definition}`}>
                    {key === 'actions' && !assignForm ? (
                      <>
                        {loadingItem && loadingItem[bodyObject[loadingKey]] ? (
                          <th>
                              {getSpinner('list')}
                          </th>
                        ) : (
                          <StyledTh>
                            {definition.map(action => (
                              <StyledActionButton disabled={disabledAction[0] === bodyObject[disabledAction[1]]} key={action} onClick={() => actionHandler(action, bodyObject)}>
                                {getSvg[action]}
                              </StyledActionButton>
                            ))}
                          </StyledTh>
                        )}
                      </>
                    ) : (
                        <>
                          {Array.isArray(definition) && key === 'users' ? (
                            <StyledShowTh show={showId === index}>
                              <button onClick={() => show({ hover: true, id: index })} onMouseLeave={() => show({ hover: false, id: index })}>
                                {showId === index ? (
                                  <>
                                    {definition.map((item, i) => {
                                      return (
                                        <React.Fragment key={i}>
                                          <p>
                                            {item}
                                          </p>
                                        </React.Fragment>
                                      )
                                    })}
                                  </>
                                ) : (
                                    'View'
                                  )}
                              </button>
                            </StyledShowTh>
                          ) : (
                              <>
                                {assignForm && key !== 'actions' ? (
                                  <StyledBodyTH>{definition} </StyledBodyTH>
                                ) : (
                                    <>
                                      {key !== 'actions' && (
                                        <StyledBodyTH>{definition}</StyledBodyTH>
                                      )}
                                    </>
                                  )}
                              </>
                            )}
                        </>
                      )}
                  </React.Fragment>
                )
              })}
            </StyledBodyTR>
          ))}
        </StyledTBody>
      </StyledUserTable>
      {!assignForm && (
        <StyledAddButtonContainer>
          <button title="Voeg gebruiker toe" onClick={() => openScreenHandler('addUser')}>
            {getSvg['add']}
          </button>
        </StyledAddButtonContainer>
      )}
      {groups > 1 && (
        <RowsPerPageComponent
          handleChange={handleRowsPergPage}
          groups={groups}
          selectedRow={selectedRow}
        />
      )}
      <PagingComponent
        items={bodyItems}
        handlePaging={handlePaging}
        page={page}
      />
    </StyledTableContainer>
  )
}

ListComponent.propTypes = {
  searchValue: propTypes.string,
  handleSearch: propTypes.func,
  listItems: propTypes.oneOfType([
    propTypes.object.isRequired,
    propTypes.array
  ]),
  actionHandler: propTypes.func,
  bodyItems: propTypes.oneOfType([
    propTypes.object,
    propTypes.array
  ]),
  openScreenHandler: propTypes.func.isRequired,
  listPage: propTypes.number.isRequired,
  handlePaging: propTypes.func.isRequired,
  handleChange: propTypes.func,
  selectedItem: propTypes.number
}
