import React from "react";

const FormattedPensionSVG = () => (
  <svg
    id="Laag_1"
    data-name="Laag 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 202.5 190.38"
  >
    <circle
      className="cls-pension-1"
      style={{
        fill: "none",
        stroke: "#cb3f5d",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "8px",
      }}
      cx="101.5"
      cy="27.43"
      r="25.93"
    />
    <circle
      className="cls-pension-1"
      style={{
        fill: "none",
        stroke: "#cb3f5d",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "8px",
      }}
      cx="160.76"
      cy="86.69"
      r="3.7"
    />
    <path
      className="cls-pension-1"
      style={{
        fill: "none",
        stroke: "#cb3f5d",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "8px",
      }}
      d="M127.43,57.73a77.75,77.75,0,0,0-25.93-4.38,79.09,79.09,0,0,0-25.93,4.32"
    />
    <path
      className="cls-pension-1"
      style={{
        fill: "none",
        stroke: "#cb3f5d",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "8px",
      }}
      d="M16.31,101.5A14.81,14.81,0,0,1,1.5,86.69"
    />
    <path
      className="cls-pension-1"
      style={{
        fill: "none",
        stroke: "#cb3f5d",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "8px",
      }}
      d="M162.9,63.52l23.34-27.59a1.85,1.85,0,0,0-1.41-3H127"
    />
    <path
      className="cls-pension-1"
      style={{
        fill: "none",
        stroke: "#cb3f5d",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "8px",
      }}
      d="M77,34.21C42.12,43,16.78,69.82,16.78,101.5c0,15,5.68,28.8,15.33,40.19a29.31,29.31,0,0,1,6.89,19v24.53a3.7,3.7,0,0,0,3.7,3.7H64.3a3.7,3.7,0,0,0,3.7-3.7V168.47a99.67,99.67,0,0,0,33.19,5.59A101.07,101.07,0,0,0,127,170.74v14.44a3.7,3.7,0,0,0,3.7,3.7h22.6a3.7,3.7,0,0,0,3.7-3.7V170.24a22.21,22.21,0,0,1,11.68-19.56l28.43-15.33a7.39,7.39,0,0,0,3.89-6.52V103.58a3.7,3.7,0,0,0-3.7-3.7h-4.18a7.33,7.33,0,0,1-7.25-6.07c-2.22-13.19-8.93-25.18-18.73-35"
    />
  </svg>
);

export default FormattedPensionSVG;
