import empty from 'empty';
import { combineReducers } from 'redux';

export default combineReducers({
  info: (state = empty.object, { type, payload = empty.object }) => {
    switch (type) {
      case 'USER_INFO':
        return payload

      default:
        return state
    }
  }
});
