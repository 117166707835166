import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CheckboxContainer, StyledCheckBox, StyledInfoBox } from '../styles';


const CheckBox = ({ label, onChange, checked, info, width, disabled, link, linkLabel, label2, displayInitial }) => {
  const [hover, updateHover] = useState(false);

  const mouseOver = () => {
    updateHover(!hover)
  }

  return (
    <CheckboxContainer>
      <StyledCheckBox displayBlock={!info} displayInitial={displayInitial} disabled={disabled}>
        {label && label}
        {link && (
          <Link to={link}>{linkLabel}</Link>
        )}
        {label2 && label2}
        <input type="checkbox" name={label} checked={checked} onChange={onChange} disabled={disabled} />
        <span className="checkmark" />
      </StyledCheckBox>
      {info && <span onMouseEnter={() => mouseOver()} onMouseLeave={() => mouseOver()}>d</span>}
      {info && hover && (
        <StyledInfoBox width={width}>
          <p>
            {info}
          </p>
        </StyledInfoBox>
      )}
    </CheckboxContainer>
  )
};

export default CheckBox;
