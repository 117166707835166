import React from "react";
import AddUser from "../../components/dashboard/addUser";
import EditUser from "../../components/dashboard/editUser";
import UploadUserSheet from "../../components/dashboard/uploadUserSheet";
import ResetUserMfa from "../../components/dashboard/resetUserMfa";

const GetScreen = ({ getObject, handleCancelScreen }) => {
  const {
    screen,
    intermediaryList,
    user,
    editUserHandler,
    deleteUserHandler,
  } = getObject;

  const getScreen = {
    addUser: (
      <AddUser
        handleCancelScreen={handleCancelScreen}
        intermediaryList={intermediaryList}
      />
    ),
    editUser: (
      <EditUser
        deleteUserHandler={deleteUserHandler}
        editUserHandler={editUserHandler}
        handleCancelScreen={handleCancelScreen}
        intermediaryList={intermediaryList}
        selectedUser={user}
      />
    ),
    uploadUserSheet: (
      <UploadUserSheet handleCancelScreen={handleCancelScreen} />
    ),
    resetUserMfa: (
      <ResetUserMfa handleCancelScreen={handleCancelScreen} />
    ),
  };

  return getScreen[screen];
};

export default GetScreen;
