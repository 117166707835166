import { combineReducers } from 'redux';

export default combineReducers({
  users: (state = [], { type, payload = [] }) => {
    switch (type) {
      case 'UPDATE_ALL_USERS':
        return payload

      default:
        return state
    }
  },
  applications: (state = [], { type, payload = [] }) => {
    switch (type) {
      case 'UPDATE_ALL_APPLICATIONS':
        return payload

      default:
        return state
    }
  }
});
