import React from "react";

const FormattedSmartPhoneSVG = () => (
  <svg id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 203.2 237.78">
    <path style={{ fill:'none', stroke:'#cb3f5d', strokeLinecap:'round', strokeLinejoin: 'round', strokeWidth: '3px' }} d="M183.59,169.63a12.31,12.31,0,1,1-17.42-17.41l5.81-5.81a12.31,12.31,0,1,1,17.41,17.42Z"/>
    <path style={{ fill:'none', stroke:'#cb3f5d', strokeLinecap:'round', strokeLinejoin: 'round', strokeWidth: '3px' }} d="M179.24,141.37A12.31,12.31,0,0,1,161.82,124L172,113.8a12.31,12.31,0,1,1,17.41,17.42Z"/>
    <path style={{ fill:'none', stroke:'#cb3f5d', strokeLinecap:'round', strokeLinejoin: 'round', strokeWidth: '3px' }} d="M174.89,110.94a12.31,12.31,0,0,1-17.41-17.42L172,79a12.31,12.31,0,1,1,17.41,17.42Z"/>
    <path style={{ fill:'none', stroke:'#cb3f5d', strokeLinecap:'round', strokeLinejoin: 'round', strokeWidth: '3px' }} d="M166.91,75.41l31.18-31.16a12.31,12.31,0,0,0-17.41-17.41L166.91,40.61"/>
    <line style={{ fill:'none', stroke:'#cb3f5d', strokeLinecap:'round', strokeLinejoin: 'round', strokeWidth: '3px' }} x1="167.2" y1="175.5" x2="54.2" y2="175.5"/>
    <line style={{ fill:'none', stroke:'#cb3f5d', strokeLinecap:'round', strokeLinejoin: 'round', strokeWidth: '3px' }} x1="167.2" y1="27.5" x2="54.2" y2="27.5"/>
    <path style={{ fill:'none', stroke:'#cb3f5d', strokeLinecap:'round', strokeLinejoin: 'round', strokeWidth: '3px' }} d="M53.67,131.93c12.89-38.65,16.9-72.9-.57-83.7a8.67,8.67,0,0,0-13.21,6.69c-2.29,27.88-12.4,46.8-20.19,57.53a51,51,0,0,0-9.5,30v22.15a26,26,0,0,1-7.65,18.44l-1,1.06"/>
    <path style={{ fill:'none', stroke:'#cb3f5d', strokeLinecap:'round', strokeLinejoin: 'round', strokeWidth: '3px' }} d="M167.2,84.5V18.89A17.4,17.4,0,0,0,149.8,1.5H71.59A17.39,17.39,0,0,0,54.2,18.89V48.7c16.72,11.25,12.72,45.05,0,83.23v52.18A17.39,17.39,0,0,0,71.59,201.5H149.8a17.4,17.4,0,0,0,17.4-17.39V170.5"/>
    <path style={{ fill:'none', stroke:'#cb3f5d', strokeLinecap:'round', strokeLinejoin: 'round', strokeWidth: '3px' }} d="M131.91,201.5A73.79,73.79,0,0,1,84.59,227a27,27,0,0,0-15.94,7.51l-1.74,1.73"/>
  </svg>
);

export default FormattedSmartPhoneSVG;
