import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { updateMessageAction } from '../../shared/actions/shared';
import RenderForm from '../../shared/components/renderForm';
import { StyledButton } from '../../shared/styles';
import { StyledFormContainer } from '../../shared/styles/containers';
import { emailRegex, initialResetPasswordForm, passRegex, resetPassswordForm, transelateErrorMessage } from './constants';

const VerificationCode = ({ updateMessage, clearComponentParams }) => {
  const [form, updateForm] = useState(initialResetPasswordForm);
  const [errorCode, updateErrorCode] = useState();
  const [validator, updateValidator] = useState({});
  const [valid, updateValid] = useState({});
  const [filled, updateFilled] = useState(false);

  useEffect(() => {
    const validObject = {
      email: {
        valid: RegExp(emailRegex).test(form['email']),
        errorMessage: RegExp(emailRegex).test(form['email']) ? '' : 'Geen geldig email adres'
      },
      password: {
        valid: RegExp(passRegex).test(form['password']),
        errorMessage: RegExp(passRegex).test(form['password']) ? '' : 'Het wachtwoord moet tenminste bestaan uit acht karakters, één hoofdletter, één nummer en één speciaal karakter'
      },
      confirmPassword: {
        valid: form['password'] === form['confirmPassword'],
        errorMessage: form['password'] === form['confirmPassword'] ? '' : 'Wachtwoorden komen niet overeen'
      },
      verificationCode: {
        valid: form['verificationCode'].length === 6,
        errorMessage: form['verificationCode'].length === 6 ? '' : 'De code bestaat uit 6 cijfers.'
      }
    }
    updateValidator(validObject);

   updateValid(!Object.keys(validObject).map(key => validObject[key]['valid']).includes(false));
  }, [form]);

  const submitFunction = () => {
    updateMessage();
    clearComponentParams();
  }

  const handleSubmit = e => {
    e.preventDefault();

    Auth.forgotPasswordSubmit(form.email, form.verificationCode, form.password).then((data) => {
      updateMessage({
        title: 'Wachtwoord is aangepast',
        body: `Het wachtwoord is succesvol aangepast, log in met het nieuwe wachtwoord.`,
        linkLabel: 'Volgende',
        submitFunction,
        button: true
      });
      updateFilled(true);
      updateValid(false);
    }).catch(err => {
      updateErrorCode(err.code);
      updateForm({
        ...form,
        verificationCode: ' '
      });
    })
  };

  const handleFormChange = ({ target: { name, value } }) => {
    updateForm({
      ...form,
      [name]: value,
    });
  }

  return (
    <StyledFormContainer>
      <form onSubmit={handleSubmit} style={{ paddingBottom: '75px' }}>
        <h2>
          Wachtwoord opnieuw instellen
        </h2>
          <>
            <RenderForm
              form={resetPassswordForm}
              valueObject={form}
              onChange={handleFormChange}
              validator={validator}
              readOnly={filled}
            />
            <StyledButton type="submit" disabled={!valid}>
              Bevestig
            </StyledButton>
          </>
          <p className="error">
            {transelateErrorMessage[errorCode]}
          </p>
        </form>
    </StyledFormContainer>
)};

const mapStateToProps = (state, props) => ({
});

const mapDispatchToProps = dispatch => ({
  updateMessage: loading => dispatch(updateMessageAction(loading))
});


export default connect(mapStateToProps, mapDispatchToProps)(VerificationCode);
