import { combineReducers } from 'redux';

export default combineReducers({
  userManagementPaging: (state = 1, { type, payload = 1 }) => {
    switch (type) {
      case 'UPDATE_LIST_PAGING_USER_MANAGEMENT':
        return payload

      default:
        return state
    }
  },
  userManagementRowsPerPage: (state = 15, { type, payload = 15 }) => {
    switch (type) {
      case 'UPDATE_ROWS_PER_PAGE_USER_MANAGEMENT':
        return payload

      default:
        return state
    }
  },
  userManagementSearchValue: (state = '', { type, payload = '' }) => {
    switch (type) {
      case 'UPDATE_SEARCH_VALUE_USER_MANAGEMENT':
        return payload

      default:
        return state
    }
  }
});
