import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { updateMessageAction } from '../../shared/actions/shared';
import RenderForm from '../../shared/components/renderForm';
import SubmitButton from '../../shared/components/submitButton';
import { StyledButton } from '../../shared/styles';
import { StyledFormContainer } from '../../shared/styles/containers';
import { emailRegex, forgotPasswordForm, initialForgotPasswordForm, transelateErrorMessage } from './constants';

const ResetPassword = ({ submitFunction, updateMessage, clearComponentParams }) => {
  const [form, updateForm] = useState(initialForgotPasswordForm);
  const [errorCode, updateErrorCode] = useState();
  const [loading, updateLoading] = useState();
  const [validator, updateValidator] = useState({});
  const [valid, updateValid] = useState({});
  const [filled, updateFilled] = useState(false);

  useEffect(() => {
    const validObject = {
      email: {
        valid: RegExp(emailRegex).test(form['email']),
        errorMessage: RegExp(emailRegex).test(form['email']) ? '' : 'Geen geldig email adres'
      },
    }
    updateValidator(validObject);

   updateValid(!Object.keys(validObject).map(key => validObject[key]['valid']).includes(false));
  }, [form]);

  const handleSubmit = e => {
    e.preventDefault();
    updateLoading(true);
    Auth.forgotPassword(form.email).then((data) => {
      updateLoading(false);
      updateMessage({
        title: 'Verificatiecode verzonden',
        body: `Een verificatiecode is verzonden naar ${data.CodeDeliveryDetails.Destination}.`,
        linkLabel: 'Vul verificatiecode in',
        submitFunction,
        button: true
      });
      updateValid(false);
      updateFilled(true);
    }).catch(err => {
      updateErrorCode(`${err.code}ResetPassword`);
      updateLoading(false);
    });
  };

  const handleFormChange = ({ target: { name, value } }) => {
    updateForm({ ...form, [name]: value })
  }

  return (
    <StyledFormContainer>
      <h2>
        Wachtwoord herstellen
      </h2>
      <form onSubmit={handleSubmit}>
        <RenderForm
          form={forgotPasswordForm}
          valueObject={form}
          onChange={handleFormChange}
          validator={validator}
          readOnly={filled}
        />
        <p className="error">
          {transelateErrorMessage[errorCode]}
        </p>
        <SubmitButton
          valid={valid}
          loading={loading}
          text="Bevestig"
        />
        <StyledButton type="button" onClick={clearComponentParams}>
          Terug
        </StyledButton>
      </form>
    </StyledFormContainer>
)};

const mapStateToProps = (state, props) => ({
});

const mapDispatchToProps = dispatch => ({
  updateMessage: loading => dispatch(updateMessageAction(loading))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
