import { combineReducers } from 'redux';

export default combineReducers({
  loading: (state = false, { type, payload = false }) => {
    switch (type) {
      case 'IS_LOADING':
        return payload

      default:
        return state
    }
  },
  message: (state = {}, { type, payload = {} }) => {
    switch (type) {
      case 'UPDATE_MESSAGE':
        return payload

      default:
        return state
    }
  }
});
