import React from 'react';
import { connect } from "react-redux";
import { setErrorMessageAction } from '../actions/error';
import { errorMessageSelector } from '../selectors/shared';
import { StyledButton, StyledFixedBackground, StyledMessageContainer } from '../styles';

const ErrorMessageComponent = ({ message, setErrorMessage }) => {
  const handleCancel = () => {
    setErrorMessage({})
  }

  return (
    <StyledFixedBackground>
      <StyledMessageContainer>
        <h2>
          {message.title}
        </h2>
        <p className="message">
          {message.post_error}
        </p>
        <div className="link-container" style={{ paddingTop: '50px' }} >
          <StyledButton onClick={() => handleCancel()} style={{ right: '10px' }}>Cancel</StyledButton>
        </div>
      </StyledMessageContainer>
    </StyledFixedBackground>
  )
};


const mapStateToProps = (state) => ({
  message: errorMessageSelector(state)
});

const mapDispatchToProps = dispatch => ({
  setErrorMessage: message => dispatch(setErrorMessageAction(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorMessageComponent);

