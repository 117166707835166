import React from 'react';
import { StyledButton } from '../styles';
import getSpinner from './loadingSpinner';

const SubmitButton = ({ loading, valid, text, filled }) => {
  let disable;

  if (filled !== undefined) {
    disable = !valid && filled;
  } else {
    disable = !valid;
  }

  return (
    <StyledButton type="submit" disabled={disable} isLoading={loading}>
      {loading ? (
          getSpinner('submit')
      ) : (
        text
      )}
    </StyledButton>
)};

export default SubmitButton;
