import styled from 'styled-components';

export const StyledSelectContainer = styled.div`
  position: relative;
  display: flex;
`

export const StyledSelectedItem = styled.div`
  color: #ffffff;
  background-color: ${props => props.theme.color.middleGrey};
  padding: 5px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
  font-size: 18px;
  width: 100%;
  margin-top: 5px;
  height: 20px

  border-top-right-radius: 3px;
  border-top-left-radius: 10px;
  ${props => props.clicked && `
    box-shadow: 3px 2px 8px ${props.theme.color.darkGrey};
  `}

  &:after {
    position: absolute;
    content: "";
    top: 16px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #fff transparent transparent transparent;
  }

  .search-input {
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
  }
`

export const StyledOptionsContainer = styled.div`
  display: ${props => props.show ? 'block' : 'none'}
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  max-height: 408px;
  overflow: scroll;
  box-shadow: 3px 2px 8px ${props => props.theme.color.middleGrey};
`

export const StyledOption = styled.div`
  color: ${props => props.theme.color.darkGrey};
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
  background-color: ${props => props.isSelected ? props.theme.color.middleGrey : '#F5F5F5'}

  &:hover {
    background-color: ${props => props.theme.color.orange};
  }
`
