import React from "react";

const FormattedServicesSVG = () => (
  <svg
    id="Laag_1"
    data-name="Laag 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 203.5 187"
  >
    <path
      className="cls-services-1"
      style={{
        fill: "none",
        stroke: "#cb3f5d",
        strokeLinejoin: "round",
        strokeWidth: "8px",
        strokeLinecap: "round",
      }}
      d="M2,161.5v-52a12,12,0,0,1,12-12h8a12,12,0,0,1,12,12v24a4,4,0,0,0,4,4H66a8,8,0,0,1,8,8h0a8,8,0,0,1-8,8H18"
    />
    <path
      className="cls-services-1"
      style={{
        fill: "none",
        stroke: "#cb3f5d",
        strokeLinejoin: "round",
        strokeWidth: "8px",
        strokeLinecap: "round",
      }}
      d="M202,161.5v-52a12,12,0,0,0-12-12h-8a12,12,0,0,0-12,12v24a4,4,0,0,1-4,4H138a8,8,0,0,0-8,8h0a8,8,0,0,0,8,8h48"
    />
    <circle
      className="cls-services-2"
      style={{
        fill: "none",
        stroke: "#cb3f5d",
        strokeLinejoin: "round",
        strokeWidth: "8px",
      }}
      cx="17.5"
      cy="73.5"
      r="16"
    />
    <circle
      className="cls-services-2"
      style={{
        fill: "none",
        stroke: "#cb3f5d",
        strokeLinejoin: "round",
        strokeWidth: "8px",
      }}
      cx="185.5"
      cy="73.5"
      r="16"
    />
    <path
      className="cls-services-2"
      style={{
        fill: "none",
        stroke: "#cb3f5d",
        strokeLinejoin: "round",
        strokeWidth: "8px",
      }}
      d="M44.17,153.5l-8.92,26.74a4,4,0,0,0,3.8,5.26H164a4,4,0,0,0,3.8-5.26l-8.92-26.74"
    />
    <path
      className="cls-services-2"
      style={{
        fill: "none",
        stroke: "#cb3f5d",
        strokeLinejoin: "round",
        strokeWidth: "8px",
      }}
      d="M153.5,137.5l-7.09-21.26a4,4,0,0,0-3.79-2.74H60.38a4,4,0,0,0-3.79,2.74L49.5,137.5"
    />
    <path
      className="cls-services-2"
      style={{
        fill: "none",
        stroke: "#cb3f5d",
        strokeLinejoin: "round",
        strokeWidth: "8px",
      }}
      d="M82,65.5l-16,16v-16H62a12,12,0,0,1-12-12v-40a12,12,0,0,1,12-12h80a12,12,0,0,1,12,12v40a12,12,0,0,1-12,12Z"
    />
    <line
      className="cls-services-1"
      style={{
        fill: "none",
        stroke: "#cb3f5d",
        strokeLinejoin: "round",
        strokeWidth: "8px",
        strokeLinecap: "round",
      }}
      x1="74"
      y1="25.5"
      x2="130"
      y2="25.5"
    />
    <line
      className="cls-services-1"
      style={{
        fill: "none",
        stroke: "#cb3f5d",
        strokeLinejoin: "round",
        strokeWidth: "8px",
        strokeLinecap: "round",
      }}
      x1="74"
      y1="41.5"
      x2="118"
      y2="41.5"
    />
  </svg>
);

export default FormattedServicesSVG;
