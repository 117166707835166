import { combineReducers } from "redux";
import error from "./error";
import user from "./user";
import shared from './shared';
import cognito from './cognito';
import list from './list';

const appReducer = combineReducers({
  error,
  user,
  shared,
  cognito,
  list
});

export const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action)
}
