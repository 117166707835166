import styled from 'styled-components';

export const StyledApplicationWrapper = styled.div`
  position: relative;
  background-color: ${props => props.theme.color.white};
  // height: calc(100vh - 55px);
  // margin: 75px auto 0 auto;

  height: 100vh;
  margin: auto;

  h2 {
    margin: 0;
  }
`

export const StyledApplicationContainer = styled.div`
  max-width: 100%;
  margin: 0 auto;
`

export const StyledWrapperContainer = styled.div`
  width: 20%;
  margin: 130px auto 0 auto;
  position: relative;
  border-radius: 5px;
  padding-left: 25px;
  border-left: .5px solid ${props => props.theme.color.lightGrey};

  .logo {
    position: absolute;
    top: 0px;
    left: -170px;
    height: 170px;
    width: 170px;

    img {
      width: 90%;
    }
  }
`

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.row ? 'row' : 'column'};

  h3 {
    padding-top: 15px;
    margin: 0;
  }
`

export const StyledScreenContainer = styled.div`
  position: relative;
  width: 40%;
  margin: 0 auto;
  background-color: white;
  margin-top: 165px;

  h2 {
    padding: 20px 0 0 20px;
    margin: 0;
  }

  form {
    position: relative;
    padding: 20px;  
    h2 {
      font-size: 20px;
      padding: 15px 0px 5px 0px;

      &:nth-child(1) {
        padding-top: 0px;
      }
    }

    input {
      width: 90%;
      overflow: hidden;
      max-width: 90%;
      overflow-x: hidden;
      white-space: nowrap;
    }
  }
`

export const StyledFormSpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.2);
`

export const StyledPopupContainer = styled.div`
  position: relative;
  padding-bottom: 75px;
  padding-top: 10px;

  h2 {
    font-size: 20px;
    padding: 15px 0px 5px 0px;

    &:nth-child(1) {
      padding-top: 0px;
    }
  }
`

export const StyledSeparateContainer = styled.div`
  margin: 5px 0;
  background-color: #dadada;
  padding: 0px 20px 10px 20px;
  border: 1px solid transparent;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 15px;
`

export const StyledElementContainer = styled.div`
  padding: 5px 0;

  p.error-form-container {
    margin: 0;
    padding-bottom: 10px;
    color: ${props => props.theme.color.error}
  }
`

export const StyledNestedRowContainer = styled.div`
  display: flex;
  flex-dirction: row;
  flex-flow: row;
`

export const StyledNestedItem = styled.div`
  width: 100%;

  &:nth-child(2) {
    padding: 0px 5px;
  }
`

export const StyledTableContainer = styled.div`
  height: auto;
  width: 100%;
  margin: ${props => props.assignForm ? '0px auto' : '0px auto 0 auto'};
  padding-bottom: ${props => props.assignForm ? '60px' : '65px'};
  border-radius: 5px;
  position: relative;

  ${props => props.assignForm && `
    min-height: 400px;
    padding-top: 15px;
  `}
`
export const StyledTabContainer = styled.div`
  display: flex;
  margin-top: 20px;
  border-top-right-radius: 3px;
  border-top-left-radius: 10px;
  padding: 20px 20px 5px 0px;
  ${props => props.attribute && `
    background-color: #DADADA
  `};
`
export const StyledResourcesContainer = styled.div`
  background-color: #DADADA;
  position: relative;
  border: solid 1px lightGrey; 
  
  p {
    padding: 20px;
  }

  ${props => props.padding && `
    padding-top: 10px;
  `};
`

export const StyledFormContainer = styled.div`
  padding: 10px 0;
  font-size: 1.3em;
  display: flex;
  flex-direction: ${props => props.row ? 'row' : 'column'};
  min-height: 275px;
  position: relative;

  h2 {
    margin: 0;
    padding-bottom: 20px;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin-top: 5px;

    li {
      text-align: left;
      padding-left: 20px;
      padding-bottom: 5px;
      border-top: 2px solid  ${props => props.theme.color.lightGrey}
      padding-top: 5px;
      border-bottom: 2px solid  ${props => props.theme.color.lightGrey}
      margin-top: 5px;
    }
  }

  form {
    h2 {
      margin-top: 0;
      margin-bottom: 15px;
      font-size: 25px;
    }

    p.error {
      margin: 0;
      color: ${props => props.theme.color.error};
    }

    p {
      a {
        text-decoration: none;
        color: ${props => props.theme.color.blue};
      }
    }
  }

  p.resetpassword {
    margin: 0;
    font-size: 15px;

    button {
      background-color: ${props => props.theme.color.owhite};
      border: none;
      padding: 0;
      color: ${props => props.theme.color.blue};
      cursor: pointer;

      &:hover {
        opacity: .5;
      }
    }
  }
`
