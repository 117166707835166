import styled from 'styled-components';

export const StyledTable = styled.table`
  width: 100%;
  background-color: ${props => props.theme.color.tableBackground};
  border: 1px solid grey;
  border-collapse: collapse;

  tbody {
    label {
      margin-left: 6px;
      margin-top: -22px;
    }
    .loading-container {
      margin-left: -55px;
    }
    tr {
      height: 45px;
      background-color: ${props => props.theme.color.tableBodyTr};

      button {
        outline: none;
        border: none;
        background-color: transparent;
        font-size: 15px;
        padding: 0;
        font-weight: 400;
        font-size: 13px;
        cursor: pointer;

        &.split {
          width: 200px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      &:hover {
        background-color: #eaeaea;
        border: 1px solid ${props => props.theme.color.tableBackground}
      }

      &:nth-child(even) {
        background-color: ${props => props.theme.color.tableBodyTrEven};

        &:hover {
          background-color: #eaeaea;
        }
      }

      th {
        .show-backup-button-container {
          height: 0;
          overflow: hidden;
          transition: all .5s
        }

        &.show-backup {
          .show-backup-button-container {
            height: 30px;
          }
        }

        &.tags {
          .show-backup-button {
            border: 1px solid ${props => props.theme.color.tableBackground}
            color: ${props => props.theme.color.tableBackground}
            padding: 4px;
            border-radius: 5px;

            &:hover {
              color: ${props => props.theme.color.white}
              background-color: ${props => props.theme.color.tableBackground}
            }
          }

          ul {
            list-style-type: none;
            padding: 0;

            li {
              margin-top: 10px;

              p {
                margin: 0 5px;
                float: left;
              }

              svg {
                margin-top: -5px;
              }
            }
          }
        }

        &:not(:last-child) {
          text-align: left;
          padding-left: 10px;
        }
      }
    }

    .action-buttons {
      button.action {
        background: transparent
        border: none;
        margin: 0 2px;
        outline: none;

        &:disabled {
          svg {
            path.hover {
              fill: grey;
            }
          }
          cursor: default;
        }

        &:hover:not([disabled]) {
          cursor: pointer;
        }

        &:hover {
          svg {
            path.hover {
              fill: grey;
            }
          }
        }
      }
    }
  }
`

export const StyledUserTable = styled.table`
  width: 100%;
  background-color: ${props => props.theme.color.tableBackground};
  border: 1px solid grey;
  border-collapse: collapse;  

`
export const StyledTh = styled.th`
  width: auto;
  &:not(:last-child) {
   text-align: left;

    &:not(:nth-child(-n+1)) {
      button {
        &:before {
          content: '';
          border-left: 1px solid grey;
          width: 250px;
          margin-left: -15px;
          height: 15px;
          position: absolute;
        }
      }
    }
  }
`

export const StyledTheadButton = styled.button`
    background: transparent;
    outline: none;
    cursor: pointer;
    width: 85%;
    position: relative;
    text-align: left;
    padding: 5px 20px 5px 10px;
    border: none;
    color:  ${props => props.theme.color.white};
    font-size: 1.2em;

    &:disabled {
      svg {
        path.hover {
          fill: grey;
        }
      }
      cursor: default;
    }

    &:hover {
      color: ${props => props.theme.color.lightGrey}
    }

    svg {
      position: absolute;
      top: 0px;
      right: -10px;
      fill: ${props => props.theme.color.orange}
    }
`

export const StyledThead = styled.thead` 
  color: ${props => props.theme.color.white};
`
export const StyledTr = styled.tr`
  height: 45px;
`
export const StyledTHead = styled.thead`
  label {
    margin-left: 15px;
    margin-top: -22px;
  }

  tr {
    height: 45px;

    th {
      width: auto;
      &:not(:last-child) {
        text-align: left;
        padding-left: 10px;
      }

      &:not(:nth-child(-n+2)) {
        button {
          &:before {
            content: '';
            border-left: 1px solid grey;
            width: 250px;
            margin-left: -15px;
            height: 15px;
            position: absolute;
          }
        }
      }

      button {
        background: transparent;
        outline: none;
        cursor: pointer;
        width: 90%;
        position: relative;
        text-align: left;
        padding: 5px 20px 5px 10px;
        border: none;
        color:  ${props => props.theme.color.white};
        font-size: 1.2em;

        &:disabled {
          svg {
            path.hover {
              fill: grey;
            }
          }
          cursor: default;
        }

        &:hover {
          color: ${props => props.theme.color.lightGrey}
        }

        svg {
          position: absolute;
          top: 0px;
          right: -10px;
          fill: ${props => props.theme.color.orange}
        }
      }
    }
  }
`

export const StyledBodyTH = styled.th`
  width: auto;
  text-align: left;
  padding-left: 10px;
  font-weight: bold;
`

export const StyledShowTh = styled.th`
  width: 150px;
  text-align: left;
  padding-left: 10px;
  font-weight: bold;
  transition: width 1s;
  ${props => props.show && `
    &:hover {
      width: 350px;
    }
  `}

  button {
    color: white;
    border-radius: 10px !important;
    padding: 15px !important;
    background-color: ${props => props.theme.color.tableBackground} !important;
    text-align: left;
    width: 100%;
  }
`

export const StyledBodyTR = styled.tr`
  height: 45px;
  background-color: ${props => props.theme.color.tableBodyTr};
  transition: all, .5s;

  button {
    outline: none;
    border: none;
    background-color: transparent;
    font-size: 15px;
    padding: 0;
    font-weight: 400;
    font-size: 13px;
    cursor: pointer;
    text-align: center;
    &.split {
      width: 200px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &:hover {
    background-color: #eaeaea;
    border: 1px solid ${props => props.theme.color.tableBackground}
  }

  &:nth-child(even) {
    background-color: ${props => props.theme.color.tableBodyTrEven};

    &:hover {
      background-color: #eaeaea;
    }

`
export const StyledTBody = styled.tbody`
  transition: all, .5s;
  font-size: 12px;

  padding-left: 10px
  .loading-container {
    margin-left: -55px;
  }

  tr {
    transition: all, .5s;
    height: 45px;
    background-color: ${props => props.theme.color.tableBodyTr};

    button {
      outline: none;
      border: none;
      background-color: transparent;
      font-size: 15px;
      padding: 0;
      font-weight: 400;
      font-size: 13px;
      cursor: pointer;

      &.split {
        width: 200px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &:hover {
      background-color: #eaeaea;
      border: 1px solid ${props => props.theme.color.tableBackground}
    }

    &:nth-child(even) {
      background-color: ${props => props.theme.color.tableBodyTrEven};

      &:hover {
        background-color: #eaeaea;
      }
    }

    th {
      .show-backup-button-container {
        height: 0;
        overflow: hidden;
        transition: all .5s
      }

      &.show-backup {
        .show-backup-button-container {
          height: 30px;
        }
      }

      &.tags {
        .show-backup-button {
          border: 1px solid ${props => props.theme.color.tableBackground}
          color: ${props => props.theme.color.tableBackground}
          padding: 4px;
          border-radius: 5px;

          &:hover {
            color: ${props => props.theme.color.white}
            background-color: ${props => props.theme.color.tableBackground}
          }
        }

        ul {
          list-style-type: none;
          padding: 0;

          li {
            margin-top: 10px;

            p {
              margin: 0 5px;
              float: left;
            }

            svg {
              margin-top: -5px;
            }
          }
        }
      }

      &:not(:last-child) {
        text-align: left;
        padding-left: 10px;
      }
    }
  }
`


export const StyledActionButtonContainer = styled.th`
  button.action {
    background: transparent
    border: none;
    margin: 0 2px;
    outline: none;

    &:disabled {
      svg {
        path.hover {
          fill: grey;
        }
      }
      cursor: default;
    }

    &:hover:not([disabled]) {
      cursor: pointer;
    }

    &:hover {
      svg {
        path.hover {
          fill: grey;
        }
      }
    }
  }
`

export const StyledActionButton = styled.button`
  background: transparent
  border: none;
  margin: 0 2px;
  outline: none;

  svg {
    width: 30px;
    fill: ${props => props.theme.color.red}
  }

  &:disabled {
    svg {
      fill: ${props => props.theme.color.lightGrey}
      path.hover {
        fill: grey;
      }
    }
    cursor: default;
  }

  &:hover:not([disabled]) {
    cursor: pointer;
  }

  &:hover {
    svg {
      path.hover {
        fill: grey;
      }
    }
  }
`


export const StyledAddButtonContainer = styled.div`
  position: absolute;
  top: 30px;
  right: -20px;
  background-color: white;
  height: 40px;
  width: 40px;
  border-radius: 40px;

  ${props => props.noAbsolute && `
    border-left: 50px solid ${props.theme.color.darkGrey};
    width: calc(100% - 50px);
    bottom: -85px;
  `}

  .pointer-container {
    float: right;
    margin-right: 18px;
    margin-top: 10px;

    p {
      text-align: center;
      padding-top: 9px;
      font-size: 18px;
      padding-left: 15px;
    }

    .pointer {
      width: 200px;
      height: 40px;
      position: relative;
      transition: opacity, .5s;
      background: ${props => props.theme.color.lightGrey};
      opacity: ${props => props.mouseEnter ? '1' : '0'};
      border-bottom-left-radius: 30px;

      &:before {
        content: "";
        position: absolute;
        right: -20px;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 20px solid ${props => props.theme.color.lightGrey};
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
      }
    }
  }

  button {
    float: right;
    background: transparent;
    cursor: pointer;
    border: none;
    outline: none;
    margin-right: -10px;
    margin-top: -6px;

    svg {
      width: 45px;
      height: 50px;
      fill: ${props => props.theme.color.red}

      path {
        &:last-child {
          fill: ${props => props.theme.color.orange}
        }
      }

      &:hover {
        path {
          &:last-child {
            fill: ${props => props.theme.color.darkGrey}
          }
        }
      }
    }
  }
`

export const StyledLoadingSpinnerForSchedule = styled.div`
  display: inline-block;
  animation: spin 2s linear infinite;
  border: 5px solid ${prop => prop.theme.color.lightGrey};
  border-radius: 50%;
  border-top: 5px solid ${prop => prop.theme.color.orange};
  height: 20px;
  outline: none;
  width: 20px;
  margin-top: 5px;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const PagingButton = styled.button`
  background-color: ${props => props.active ? props.theme.color.orange : 'transparent'};
  color: ${props => props.active ? props.theme.color.white : '#000'};
  cursor: pointer;
  font-size: 18px;
  border: none;
  outline: none;
`

export const StyledPaging = styled.div`
  position: absolute;
  width: 100%;
  bottom: 20px;
  justify-content: flex-end;
  display: flex;
  border-top: 1px solid #c3c3c3;
  padding-top: 10px;

  ul {
    list-style-type: none;
    margin: 0;

    li {
      display: inline-block;
      float: right;
    }
  }
`

export const StyledPagingButton = styled.button`
  background-color: ${props => props.active ? props.theme.color.red : 'transparent'};
  color: ${props => props.active ? props.theme.color.white : '#000'};
  cursor: pointer;
  font-size: 18px;
  border: none;
  outline: none;
`


export const StyledRowsPerPageContainer = styled.div`
  display: flex;
  justify-content: flex-end;  
  
`
