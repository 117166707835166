import React from "react";

const FormattedContactSVG = () => (
  <svg
    id="Laag_1"
    data-name="Laag 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 203.5 203"
  >
    <text
      className="cls-contact-1"
      style={{
        fontSize: "120px",
        fill: "#cb3f5d",
        fontFamily: "Aller-Regular, Aller",
      }}
      transform="translate(21.5 113.94)"
    >
      <tspan
        style={{ fontFamily: "Calibri" }}
        className="cls-contact-2"
        x="28.44"
        y="0"
      >
        ?
      </tspan>
    </text>
    <path
      className="cls-contact-3"
      style={{
        fill: "none",
        stroke: "#cb3f5d",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "8px",
      }}
      d="M163.2,201.48c21.73-.63,38.8-18.92,38.8-40.66v-12.9a20,20,0,0,0-13.68-19L154,117.5v-32a12,12,0,0,0-12-12h0a12,12,0,0,0-12,12v68l-9.86-9.86a20,20,0,0,0-28.28,0L90,145.5l43.72,44.29h0A39.86,39.86,0,0,0,163.2,201.48Z"
    />
    <path
      className="cls-contact-3"
      style={{
        fill: "none",
        stroke: "#cb3f5d",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "8px",
      }}
      d="M151.21,59.27a76,76,0,1,0-76.43,94.09"
    />
  </svg>
);

export default FormattedContactSVG;
