import { Auth } from 'aws-amplify';
import { apiCall } from '../../api';
import { clearLocalStorage } from '../utils';

// eslint-disable-next-line no-extend-native
Date.prototype.minus = function(h){
  this.setHours(this.getHours()-h);
  return this;
}

export const userSessionSelector = ({ sessionTimeout }) => {
  Auth.currentSession().then(data => {
    const now = new Date().minus(1);
    const exp = new Date(data['idToken']['payload']['auth_time'] * 1000);

    if (now > exp) {
      clearLocalStorage();
    }

    const timeoutInterval = setInterval(() => {
      const now = new Date().minus(1);
       if (now > exp) {
         clearLocalStorage();
         sessionTimeout('Session timeout');
         clearInterval(timeoutInterval)
       }
     }, 10000);
  }).catch(_ => {_ = true});
};

export const authenticatedUser = async ({ userInfoHandler, updateAppIsLoaded, updateLoading, allUsersHandler, allAppicationsHandler }) => {
  const attributeTranslation = {
    'custom:is_super_admin': 'superAdmin',
    'custom:is_admin': 'admin',
    'custom:intermediary': 'intermediary',
    'custom:intermediary_name': 'intermediaryName',
    'custom:is_office': 'isOffice',
    "custom:axylife_pensioen": "axyLifePensioenRights",
    "custom:axylife_leven": "axyLifeLevenRights",
    "custom:digital_archive": "digitalArchiveRights",
  }

  return Auth.currentAuthenticatedUser().then(async user => {
    if (user) {
      const userObject = {}
      const allApplicationsResponse = await apiCall({
        path: 'groups/all',
        method: 'get'
      });

      if (allApplicationsResponse) {
        allAppicationsHandler(allApplicationsResponse);
      }

      Object.keys(user).forEach(key => {
        if (key === 'attributes') {
          userObject['email'] = user[key]['email']
          const attributes = Object.keys(user[key]).map(
            key => key.includes('custom:') && key
          ).filter(
            item => typeof(item) !== 'boolean'
          );
          attributes.forEach(attribute => {
            userObject[attributeTranslation[attribute]] = user[key][attribute]
          });
        } else if (key === 'signInUserSession') {
          userObject['groups'] = user[key]['idToken']['payload']['cognito:groups']
        }
      });

      if (Object.keys(user).length > 0) {
        userInfoHandler(userObject);
      }
      if (updateLoading) {
        updateLoading(false);
      }
    }
  }).finally(async () => {
    if (updateAppIsLoaded) {
      updateAppIsLoaded(true);
    }
  });
}

// SELECTORS
export const userSelector = (state) => {
  const data = state.user.info;

  // Iterate through the object and apply the mapping function
  for (const key in data) {
      if (data.hasOwnProperty(key)) {
          data[key] = convertToBoolean(data[key]);
      }
  }


  return data
};


const convertToBoolean = (str) => {
  if (str === "true" || str === "false") {
    return str === "true";
  }
  return str
};
