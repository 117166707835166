import propTypes from 'prop-types';
import _filter from 'lodash/fp/filter';
import React, { useEffect, useRef, useState } from "react";
import { StyledElementContainer } from "../styles/containers";
import { StyledLabel } from "../styles/label";
import { StyledOption, StyledOptionsContainer, StyledSelectContainer, StyledSelectedItem } from '../styles/select';
import useOutsideClick from "./useOutsideClick";

const Select = ({ label, selectedItem, options, selectHandler, required, name, capitalize, readOnly, enableSearch }) => {
  const [display, updateDisplay] = useState(false);
  const [selectionOptions, updateSelctionOptions] = useState([]);
  const [searchFilter, updateSearchFilter] = useState('');

  useEffect(() => {
    updateSelctionOptions(options);
  }, [options])

  const ref = useRef();
  const showOptionsHandler = () => {
    if (!readOnly) {
      updateDisplay(!display);
    }
  };

  useOutsideClick(ref, () => {
    updateDisplay(false);
    updateSelctionOptions(options);
  });

  const handleKeyDown = (event) => {
      if (event.keyCode === 13) {
        if (selectionOptions.length === 1) {
          updateSearchFilter(selectionOptions[0]);
          selectHandler(selectionOptions[0]);
          updateSelctionOptions(options);
          updateDisplay(false);
          // updateSearchFilter('');
        }
      }
  }

  const handleSearch = ({ target: { value } }) => {
    const filterSearch = _filter(item => {
      return (
        item.toLowerCase().search(value.toLowerCase()) !== -1
      );
    }
    )(options);
    updateSearchFilter(value);
    updateSelctionOptions(filterSearch);
  }

  return (
    <StyledElementContainer>
      <StyledLabel>
        {label} {required && <span className="required">*</span>}
      </StyledLabel>
      <StyledSelectContainer padding={!display}>
        <StyledSelectedItem onClick={showOptionsHandler} ref={ref} clicked={display}>
          {!display && (
            <>
              {render(selectedItem, capitalize)}
            </> 
          )}
          {enableSearch && display && (
            <input className="search-input" placeholder="Zoek" onChange={handleSearch} autoFocus={true} onKeyDown={handleKeyDown} />
          )}
        </StyledSelectedItem>
        <StyledOptionsContainer show={display}>
          {selectionOptions.filter(o => o !== null).map((option, index) => {
            let label, value;
            if (typeof(option) === 'object' && option !== null) {
              label = option['label']
              value = option['value']
            }
            const labelItem = label ? label : option
            return (
              <StyledOption
                key={index}
                onClick={() => selectHandler(value ? value : option, (name && name))}
                isSelected={selectedItem === labelItem || searchFilter === labelItem}
              >
                {render(labelItem, capitalize)}
              </StyledOption>
            )
          })}
        </StyledOptionsContainer>
      </StyledSelectContainer>
    </StyledElementContainer>
  )
};

const render = (item, capitalize) => {
  return capitalize ? (
    item
    // capitalizeString(item)
  ) : (
      item
    )
}


Select.propTypes = {
  label: propTypes.string.isRequired,
  selectedItem: propTypes.oneOfType([
    propTypes.string,
    propTypes.number
  ]).isRequired,
  options: propTypes.array.isRequired,
  selectHandler: propTypes.func.isRequired,
  required: propTypes.bool,
  name: propTypes.string,
  readOnly: propTypes.bool
}

export default Select;
