import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { apiCall } from "../../api";
import {
  allAppicationsHandlerAction,
  allUsersHandlerAction,
} from "../../shared/actions/cognito";
import { setErrorMessageAction } from "../../shared/actions/error";
import CheckBox from "../../shared/components/checkBox";
import getSpinner from "../../shared/components/loadingSpinner";
import Radio from "../../shared/components/radio";
import RenderForm from "../../shared/components/renderForm";
import Select from "../../shared/components/select";
import {
  applicationsSelector,
  intermediaryListSelector,
} from "../../shared/selectors/cognito";
import { userSelector } from "../../shared/selectors/user";
import {
  StyledButton,
  StyledFixedBackground,
  StyledInput,
  StyledInputContainer,
} from "../../shared/styles";
import {
  StyledElementContainer,
  StyledScreenContainer,
} from "../../shared/styles/containers";
import { StyledLabel } from "../../shared/styles/label";
import { getKey, hasError, hasObj } from "../../shared/utils";
import {
  addUserForm,
  emailRegex,
  intermediaryForm,
  tpCodeForm,
  passRegex,
} from "../auth/constants";
// import { translateApps } from './applications';
import {
  initRadioButtons,
  translateAxyLifeOptions,
  translateDigitalArchiveOptions,
  translateRadioButtons,
  translatklantoverzichtOptions,
} from "./constants";

const translateApps = {
  "digitaal-archief": "Digitaal archief",
  "axy-life-leven": "Leven",
  "klantoverzicht": "Klantenoverzicht"
  // "axy-life-pensioen": "Pensioen",
};
// const translateApps = { // PRD
//   "digitaal-archief": "Digitaal archief",
//   "axy-life-leven": "Leven",
//   // "axy-life-pensioen": "Pensioen",
// };

const initializeRights = ({ user, rightName }) => {
  const rights = user[rightName].split(",").filter((item) => item !== "");
  const newOptions = [];

  rights.forEach((right) => {
    const splitted = right.split("-");
    const name = splitted[0];
    const permission = splitted[1];

    if (user['superAdmin']) {
      newOptions.push(name)
    } else {
      if (permission === 'true') {
         newOptions.push(name)
      }
    }
  });

  return newOptions;
};

const AddUser = ({
  handleCancelScreen,
  user,
  intermediaryList,
  applications,
  allUsersHandler,
  allAppicationsHandler,
  setErrorMessage,
}) => {
  const [valueObject, updateValueObject] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [intermediaryValue, updateIntermediaryValue] = useState("");
  const [tpCodeValue, updatetpCodeValue] = useState("");
  const [checkedRadioButtons, updateCheckedRadioButtons] = useState(
    initRadioButtons
  );
  const [applicationCheckboxValues, updateApplicationCheckboxValues] = useState(
    {}
  );
  const [digitalArchiveOptions, updatedigitalArchiveOptions] = useState({
    read_fi: false,
    read_po: false,
    read_pd: false,
  });
  const [axyLifeLevenOptions, updateAxyLifeLevenOptions] = useState({
    mutation: false,
  });
  const [axyLifePensioenOptions, updateAxyLifePensioenOptions] = useState({
    mutation: false,
    read: false,
  });
  const [klantoverzichtOptions, updateKlantoverzichtOptions] = useState({ //tst
    read: false,
  });
  const [digitalArchiveCheckboxOptions, updateDigitalArchiveCheckboxOptions] = useState(["read_fi", "read_po", "read_pd"]);
  const [axyLifeLevenCheckboxOptions, updateAxyLifeLevenCheckboxOptions] = useState(["mutation"]);
  const [axyLifePensioenCheckboxOptions, updateAxyLifePensioenCheckboxOptions] = useState(["mutation", "read"]);
  const [klantoverzichtCheckboxOptions, updateKlantoverzichtCheckboxOptions] = useState(["read"]); // tst
  const [selectedRights, updateSelectedRights] = useState();
  const [selectionValue, updateSelectionValue] = useState("");
  const [tpCodeSelection, updatetpCodeSelection] = useState("");
  const [loading, updateLoading] = useState(false);
  const [valid, updateValid] = useState(false);
  const { email, password, confirmPassword } = valueObject;

  useEffect(() => {
    if (user.admin) {
      updateCheckedRadioButtons({
        ...initRadioButtons,
        user: true,
      });
      updateSelectionValue(user.intermediaryName);
      updatetpCodeSelection(user.intermediary);

      if (Object.keys(user).includes("axyLifeLevenRights")) {
        updateAxyLifeLevenCheckboxOptions(
          initializeRights({ user, rightName: "axyLifeLevenRights" })
        );
      }
      if (Object.keys(user).includes("axyLifePensioenRights")) {
        updateAxyLifePensioenCheckboxOptions(
          initializeRights({ user, rightName: "axyLifePensioenRights" })
        );
      }
      if (Object.keys(user).includes("digitalArchiveRights")) {
        updateDigitalArchiveCheckboxOptions(
          initializeRights({ user, rightName: "digitalArchiveRights" })
        );
      }
      if (Object.keys(user).includes("klantoverzichtRights")) { // tst
        updateKlantoverzichtCheckboxOptions(
          initializeRights({ user, rightName: "klantoverzichtRights" })
        );
      }
    }
  }, [user]);

  useEffect(() => {
    updateSelectedRights(
      Object.keys(checkedRadioButtons)
        .map((key) => (checkedRadioButtons[key] ? key : false))
        .filter((item) => item !== false)[0]
    );
    updateIntermediaryValue("");
  }, [checkedRadioButtons]);

  useEffect(() => {
    if (applications) {
      const initCheckboxValues = {};
      applications.forEach((app) => {
        initCheckboxValues[app] = false;
      });
      updateApplicationCheckboxValues(initCheckboxValues);
    }
  }, [applications]);

  useEffect(() => {
    const validArray =
      Object.keys(validator)
        .map((key) => validator[key]["valid"])
        .filter((item) => item !== false).length ===
      Object.keys(validator).length;
    const hasAppSelection =
      Object.keys(applicationCheckboxValues)
        .map((key) => (applicationCheckboxValues[key] ? key : false))
        .filter((item) => item !== false).length > 0;

    let hasIntermediaryValue = false;
    let hasTPcodeValue = false;
    if (selectedRights !== "superAdmin") {
      hasIntermediaryValue =
        Object.keys(intermediaryValidator).map(
          (key) => intermediaryValidator[key]["valid"]
        )[0] || selectionValue !== "";
      hasTPcodeValue =
        Object.keys(tpCodeValidator).map(
          (key) => tpCodeValidator[key]["valid"]
        )[0] || selectionValue !== "";
    } else {
      hasIntermediaryValue = true;
    }

    if (user.superAdmin && selectedRights === "superAdmin") {
      updateValid(validArray && selectedRights);
    } else {
      updateValid(
        validArray &&
          hasAppSelection &&
          selectedRights &&
          hasIntermediaryValue &&
          hasTPcodeValue
      );
    }
  }, [
    intermediaryValue,
    tpCodeValue,
    selectedRights,
    applicationCheckboxValues,
    selectionValue,
    valueObject,
  ]);

  const handleChange = ({ target: { name, value } }) => {
    updateValueObject({
      ...valueObject,
      [name]: name === "email" ? value.toLowerCase() : value,
    });
  };

  const handleIntermediaryChange = ({ target: { value } }) => {
    updateIntermediaryValue(value);
  };

  const handleTpCodeChange = ({ target: { value } }) => {
    updatetpCodeValue(value);
  };

  const handleRadioButtonChange = ({ target: { checked, value } }) => {
    updateCheckedRadioButtons({
      ...initRadioButtons,
      [value]: checked,
    });
  };

  const selectHandler = (value) => {
    updateSelectionValue(value);
  };

  const handleApplicationCheckboxChange = ({ target: { name, checked } }) => {
    const key = getKey({ keys: translateApps, item: name });
    updateApplicationCheckboxValues({
      ...applicationCheckboxValues,
      [key]: checked,
    });
  };

  const handleCheckboxChanges = (
    { target: { name, checked } },
    application
  ) => {
    const optionStates = {
      digitalArchive: updatedigitalArchiveOptions,
      axyLifeLeven: updateAxyLifeLevenOptions,
      axyLifePensioen: updateAxyLifePensioenOptions,
      klantoverzicht: updateKlantoverzichtOptions // tst
    };

    const optionKeys = {
      digitalArchive: getKey({
        keys: translateDigitalArchiveOptions,
        item: name,
      }),
      axyLifeLeven: getKey({ keys: translateAxyLifeOptions, item: name }),
      axyLifePensioen: getKey({ keys: translateAxyLifeOptions, item: name }),
      klantoverzicht: getKey({ keys: translatklantoverzichtOptions, item: name }), // tst
    };

    const OptionApplications = {
      digitalArchive: digitalArchiveOptions,
      axyLifeLeven: axyLifeLevenOptions,
      axyLifePensioen: axyLifePensioenOptions,
      klantoverzicht: klantoverzichtOptions // tst
    };

    optionStates[application]({
      ...OptionApplications[application],
      [optionKeys[application]]: checked,
    });
  };

  const validator = {
    password: {
      valid: RegExp(passRegex).test(password),
      errorMessage: RegExp(passRegex).test(password)
        ? ""
        : "Het wachtwoord moet tenminste bestaan uit acht karakters, één hoofdletter, één nummer en één speciaal karakter",
    },
    confirmPassword: {
      valid: password === confirmPassword,
      errorMessage:
        password === confirmPassword ? "" : "Wachtwoorden komen niet overeen",
    },
    email: {
      valid: RegExp(emailRegex).test(email),
      errorMessage: RegExp(emailRegex).test(email)
        ? ""
        : "Geen geldig email adres",
    },
  };

  const intermediaryValidator = {
    intermediary: {
      valid: intermediaryValue.length > 0,
      errorMessage:
        intermediaryValue.length > 0 ? "" : "Vul de naam van een adviseur in",
    },
  };

  const tpCodeValidator = {
    tpcode: {
      valid: tpCodeValue.length === 5,
      errorMessage:
        tpCodeValue.length === 5
          ? ""
          : "Een TP-code moet bestaan uit vijf karakters",
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateLoading(true);

    let digitalArchiveValue = "";
    let axyLifeLevenValue = "";
    let axyLifePensioenValue = "";
    let klantoverzichtValue = ""; // tst

    Object.keys(digitalArchiveOptions).forEach((key) => {
      digitalArchiveValue =
        digitalArchiveValue + `${key}-${digitalArchiveOptions[key]},`;
    });

    Object.keys(axyLifeLevenOptions).forEach((key) => {
      axyLifeLevenValue =
        axyLifeLevenValue + `${key}-${axyLifeLevenOptions[key]},`;
    });

    Object.keys(axyLifePensioenOptions).forEach((key) => {
      axyLifePensioenValue =
        axyLifePensioenValue + `${key}-${axyLifePensioenOptions[key]},`;
    });

    Object.keys(klantoverzichtOptions).forEach((key) => { // tst
      klantoverzichtValue =
        klantoverzichtValue + `${key}-${klantoverzichtOptions[key]},`;
    });

    const newUserObject = {
      username: valueObject.email,
      temporaryPassword: valueObject.password,
      attributes: [
        {
          Name: "email_verified",
          Value: "true",
        },
        {
          Name: "email",
          Value: valueObject.email,
        },
        {
          Name: "custom:is_admin",
          Value: selectedRights === "admin" ? "true" : "false",
        },
        {
          Name: "custom:is_super_admin",
          Value: selectedRights === "superAdmin" ? "true" : "false",
        },
        {
          Name: "custom:intermediary_name",
          Value: selectionValue
            ? selectionValue
            : intermediaryValue
            ? intermediaryValue
            : "",
        },
        {
          Name: "custom:intermediary",
          Value: tpCodeSelection ? tpCodeSelection : tpCodeValue,
        },
        {
          Name: "custom:disabled",
          Value: "false",
        },
        {
          Name: "custom:digital_archive",
          Value: digitalArchiveValue,
        },
        {
          Name: "custom:axylife_pensioen",
          Value: axyLifePensioenValue,
        },
        {
          Name: "custom:axylife_leven",
          Value: axyLifeLevenValue,
        },
        {
          Name: "custom:klantoverzicht",
          Value: klantoverzichtValue,
        },
        {
          Name: "custom:is_office",
          Value: Object.keys(user).includes("isOffice") ? "true" : "false",
        },
      ],
    };

    const applicationSelection = {
      username: valueObject.email,
      groupList: Object.keys(applicationCheckboxValues)
        .map((key) => (applicationCheckboxValues[key] ? key : false))
        .filter((item) => item !== false),
    };

    const addUserResponse = await apiCall({
      path: "users/single",
      method: "post",
      body: newUserObject,
    });

    if (hasError(addUserResponse)) {
      setErrorMessage({
        title: "Gebruiker bestaat al",
        message: "gebruiker bestaal al",
      });
      handleCancelScreen({});
    } else {
      const addUserToGroupResponse = await apiCall({
        path: "groups/add-user-in-groups",
        method: "put",
        body: applicationSelection,
      });

      if (addUserToGroupResponse) {
        const allApplicationsResponse = await apiCall({
          path: "groups/all",
          method: "get",
        });
        if (allApplicationsResponse) {
          allAppicationsHandler(allApplicationsResponse);
        }
      }

      const allUsersResponse = await apiCall({
        path: "users/all",
        method: "get",
      });

      if (allUsersResponse) {
        allUsersHandler(allUsersResponse);
        // updateLoading(false);
      }
      handleCancelScreen({});
    }
  };

  // const validArray = Object.keys(validator).map(key => validator[key]['valid']);
  // const valid = !validArray.includes(false);

  return (
    <StyledFixedBackground>
      <StyledScreenContainer style={{ position: "relative", width: "40%" }}>
        <h2>Voeg gebruiker toe</h2>
        <form onSubmit={handleSubmit}>
          <RenderForm
            form={addUserForm}
            valueObject={valueObject}
            onChange={handleChange}
            validator={validator}
          />
          <StyledElementContainer>
            <h2>Rechten</h2>
            {Object.keys(initRadioButtons).map((key) => (
              <React.Fragment key={key}>
                {/superAdmin|admin/.test(key) ? (
                  <>
                    {user.superAdmin && (
                      <Radio
                        key={key}
                        name="rights"
                        label={translateRadioButtons[key]}
                        checked={checkedRadioButtons[key]}
                        value={key}
                        onChange={handleRadioButtonChange}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {!user.superAdmin && (
                      <Radio
                        key={key}
                        name="rights"
                        label={translateRadioButtons[key]}
                        checked={checkedRadioButtons[key]}
                        value={key}
                        onChange={handleRadioButtonChange}
                      />
                    )}
                  </>
                )}
              </React.Fragment>
            ))}
          </StyledElementContainer>
          <div style={{ clear: "both" }} />
          {selectedRights === "admin" && (
            <>
              <RenderForm
                form={intermediaryForm}
                valueObject={intermediaryValue}
                onChange={handleIntermediaryChange}
                validator={intermediaryValidator}
              />
              <RenderForm
                form={tpCodeForm}
                valueObject={tpCodeValue}
                onChange={handleTpCodeChange}
                validator={tpCodeValidator}
              />
            </>
          )}
          {selectedRights === "user" && (
            <>
              {user.superAdmin ? (
                <Select
                  label="Adviseur"
                  selectedItem={selectionValue}
                  options={intermediaryList}
                  selectHandler={selectHandler}
                  required={true}
                  name="intermediary"
                  readOnly={user.admin}
                  enableSearch={true}
                />
              ) : (
                <StyledElementContainer>
                  <StyledLabel>
                    {" "}
                    Adviseur <span className="required">*</span>
                  </StyledLabel>
                  <StyledInputContainer style={{ height: "auto" }}>
                    <StyledInput
                      onChange={undefined}
                      value={selectionValue}
                      name={"objectKey"}
                      id={"objectKey"}
                      placeholder={""}
                      type={"text"}
                      onBlur={undefined}
                      invalid={false}
                      readOnly={true}
                      autoFocus={false}
                    />
                  </StyledInputContainer>
                </StyledElementContainer>
              )}
            </>
          )}
          {hasObj(applicationCheckboxValues) && (
            <StyledElementContainer>
              <h2>Applicaties</h2>
              {applications.map((app) => (
                <CheckBox
                  key={app}
                  label={translateApps[app]}
                  onChange={handleApplicationCheckboxChange}
                  checked={applicationCheckboxValues[app]}
                />
              ))}
            </StyledElementContainer>
          )}
          {applicationCheckboxValues["digitaal-archief"] && (
            <StyledElementContainer>
              <h2>Rechten Digitaal archief</h2>
              {digitalArchiveCheckboxOptions.map((item) => (
                <CheckBox
                  key={item}
                  label={translateDigitalArchiveOptions[item]}
                  onChange={(e) => handleCheckboxChanges(e, "digitalArchive")}
                  checked={digitalArchiveOptions[item]}
                />
              ))}
            </StyledElementContainer>
          )}
          {applicationCheckboxValues["axy-life-leven"] && (
            <StyledElementContainer>
              <h2>Rechten Leven</h2>
              {axyLifeLevenCheckboxOptions.map((item) => (
                <CheckBox
                  key={item}
                  label={translateAxyLifeOptions[item]}
                  onChange={(e) => handleCheckboxChanges(e, "axyLifeLeven")}
                  checked={axyLifeLevenOptions[item]}
                />
              ))}
            </StyledElementContainer>
          )}
          {applicationCheckboxValues["klantoverzicht"] && ( // tst
            <StyledElementContainer>
              <h2>Rechten Klantenoverzicht</h2>
              {klantoverzichtCheckboxOptions.map((item) => (
                <CheckBox
                  key={item}
                  label={translatklantoverzichtOptions[item]}
                  onChange={(e) => handleCheckboxChanges(e, "klantoverzicht")}
                  checked={klantoverzichtOptions[item]}
                />
              ))}
            </StyledElementContainer>
          )}
          {/* {applicationCheckboxValues["axy-life-pensioen"] && (
            <StyledElementContainer>
              <h2>Rechten Pensioen</h2>
              {axyLifePensioenCheckboxOptions.map((item) => (
                <CheckBox
                  key={item}
                  label={translateAxyLifeOptions[item]}
                  onChange={(e) => handleCheckboxChanges(e, "axyLifePensioen")}
                  checked={axyLifePensioenOptions[item]}
                />
              ))}
            </StyledElementContainer>
          )} */}
          <StyledButton type="button" onClick={handleCancelScreen}>
            Annuleer
          </StyledButton>
          <StyledButton type="submit" disabled={!valid}>
            Bevestig
          </StyledButton>
        </form>
        {loading && getSpinner("form")}
      </StyledScreenContainer>
    </StyledFixedBackground>
  );
};

const mapStateToProps = (state) => ({
  user: userSelector(state),
  intermediaryList: intermediaryListSelector(state),
  applications: applicationsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  allUsersHandler: (users) => dispatch(allUsersHandlerAction(users)),
  allAppicationsHandler: (applications) =>
    dispatch(allAppicationsHandlerAction(applications)),
  setErrorMessage: (message) => dispatch(setErrorMessageAction(message)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUser);
