import React from 'react';
import { Link } from 'react-router-dom';
import { StyledFixedBackground, StyledMessageContainer } from '../styles';

const Message = ({ message }) => (
  <StyledFixedBackground>
    <StyledMessageContainer>
      <h2>
        {message.title}
      </h2>
      <p className="message">
        {message.body}
      </p>
      <div className="link-container">
        {message.button ? (
          <>
            {message.submitFunction ? (
              <button onClick={message.submitFunction}>
                {message.linkLabel}
              </button>
            ): (
              <div className="button">
                <Link to={message.link}>{message.linkLabel}</Link>
              </div>
            )}
          </>
        ) : (
          <Link to={message.link}>{message.linkLabel}</Link>
        )}
      </div>
    </StyledMessageContainer>
  </StyledFixedBackground>
);

export default Message;
