import { Auth } from 'aws-amplify';
import QRCode from 'qrcode.react';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { updateMessageAction } from '../../shared/actions/shared';
import { StyledButton, StyledMFAContainer } from '../../shared/styles';
import { clearLocalStorage } from '../../shared/utils';

const SetupMFA = ({ code, cUser, clearComponentParams, messageHandler }) => {
  const [verificationCode, updateVerificationCode] = useState();
  const [verificationCodeError, updateVerificationCodeError] = useState();
  const [disable, updateDisableButton] = useState(false);
  const [filled, updateFilled] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    Auth.verifyTotpToken(cUser, verificationCode).then(() => {
      Auth.setPreferredMFA(cUser, 'TOTP');
      clearLocalStorage()
      updateDisableButton(true);
      messageHandler({
        title: 'MFA ingesteld',
        body: 'MFA is ingesteld, log opnieuw in.',
        button: true,
        submitFunction: clearComponentParams,
        linkLabel: 'Volgende'
      });
      updateFilled(true);
    }).catch(() => {
      updateVerificationCodeError('Code is niet correct');
    });
  }

 const handleChangeVerification = ({ target: { value } }) => {
    updateVerificationCode(value)
  }

  return (
    <React.Fragment>
      {code ? (
        <StyledMFAContainer>
          <div className="wrapper">
            <div className="qr-container">
            <h2>
              Scan de QR-code met de authenticatieapp
            </h2>
            <form onSubmit={handleSubmit}>
              <QRCode value={code.qr} size={200} />
              <p className="error">
                {verificationCodeError}
              </p>
              <input autoFocus={!disable} readOnly={filled} placeholder="Vul code in" onChange={handleChangeVerification} />
              <div className="button-container">
                <StyledButton type="button" onClick={clearComponentParams}>
                  Annuleer
                </StyledButton>
                <StyledButton disable={disable} type="submit" >
                  Bevestig
                </StyledButton>
              </div>
            </form>
            </div>
          </div>
        </StyledMFAContainer>
      ) : (
        <p>..</p>
      )}
    </React.Fragment>
  )};

  const mapStateToProps = (state) => ({});

  const mapDispatchToProps = dispatch => ({
    messageHandler: message => dispatch(updateMessageAction(message)),
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(SetupMFA)
