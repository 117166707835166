import {
  Auth
} from "aws-amplify";
import config from "../config";
import fetch from "isomorphic-fetch";

export const apiCall = async ({
  path,
  method,
  body
}) => {
  return Auth.currentAuthenticatedUser().then(async user => {
    return new Promise(resolver => {
      fetch(`${config.apiUrl}/${config.stage}/${path}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.signInUserSession.idToken.jwtToken
        },
        method,
        body: JSON.stringify(body)
      }).then(response => {
        response.json().then(data => {
          resolver(data);
        });
      });
    });
  });
}

export const apiCallUnAuthenticated = async ({
  path,
  method,
  body
}) => {
  return new Promise(resolver => {
    fetch(`${config.apiUrl}/${config.stage}/${path}`, {
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": config.apiKey
      },
      method,
      body: JSON.stringify(body)
    }).then(response => {
      response.json().then(data => {
        resolver(data);
      });
    });
  });
}
