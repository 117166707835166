import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { updateMessageAction } from '../../shared/actions/shared';
import RenderForm from '../../shared/components/renderForm';
import SubmitButton from '../../shared/components/submitButton';
import { StyledFormContainer } from '../../shared/styles/containers';
import { initialNewPasswordForm, newPassswordForm, passRegex } from './constants';

const NewPassword = ({ loginForm, messageHandler, clearComponentParams }) => {
  const [form, updateForm] = useState(initialNewPasswordForm);
  const [validator, updateValidator] = useState({});
  const [valid, updateValid] = useState({});
  const [filled, updateFilled] = useState(false); 

  useEffect(() => {
    const validObject = {
      password: {
        valid: RegExp(passRegex).test(form['password']),
        errorMessage: RegExp(passRegex).test(form['password']) ? '' : 'Het wachtwoord moet tenminste bestaan uit acht karakters, één hoofdletter, één nummer en één speciaal karakter'
      },
      confirmPassword: {
        valid: form['password'] === form['confirmPassword'],
        errorMessage: form['password'] === form['confirmPassword'] ? '' : 'Wachtwoorden komen niet overeen'
      },
    }
    updateValidator(validObject);

   updateValid(!Object.keys(validObject).map(key => validObject[key]['valid']).includes(false));
  }, [form]);

  const handleSubmit = e => {
    e.preventDefault();
    Auth.signIn(loginForm.email, loginForm.password).then(user => {
      Auth.completeNewPassword(user, form.password).then(() => {
        window.localStorage.clear();
        messageHandler({
          title: 'Wachtwoord ingesteld',
          body: 'Het nieuwe wachtwoord is ingesteld, log opnieuw in.',
          button: true,
          submitFunction: clearComponentParams,
          linkLabel: 'Volgende'
        });
        updateFilled(true);
      }).catch(err => {
        console.log(err)
      });
    }).catch(err => {
      console.log(err)
    });
  }

  const handleFormChange = ({ target: { name, value } }) => {
    updateForm({ ...form, [name]: value })
  }

  return (
    <StyledFormContainer>
      <form onSubmit={handleSubmit}>
        <RenderForm
          form={newPassswordForm}
          valueObject={form}
          onChange={handleFormChange}
          validator={validator}
          readOnly={filled}
        />
        <SubmitButton text="Bevestig" valid={valid} type="submit" disabled={filled} />
      </form>
    </StyledFormContainer>
)};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = dispatch => ({
  messageHandler: message => dispatch(updateMessageAction(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewPassword)
