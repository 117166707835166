import isHotkey from "is-hotkey";
import isUrl from "is-url";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  createEditor,
  Editor,
  Element as SlateElement,
  Range,
  Transforms,
} from "slate";
import { withHistory } from "slate-history";
import { Editable, Slate, useSlate, withReact } from "slate-react";
import styled from "styled-components";
import { apiCall } from "../../api";
import { StyledButton, StyledFixedBackground } from "../styles";
import { StyledScreenContainer } from "../styles/containers";
import getSvg from "../svg/getSvg";
import { hasSuccess } from "../utils";
import SubmitButton from "./submitButton";

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

const LIST_TYPES = ["numbered-list", "bulleted-list"];

const StyledAreaBox = styled.div`
  h1 {
    margin: 5px;
  }

  p {
    margin: 5px;
  }

  .box {
    padding: "0 10px";
    color: ${props => props.theme.color.blue}
  }
`;

const ButtonAreaBox = styled.button`
  border: 2px solid ${(props) => (props.active ? "darkgrey" : "lightgrey")};
  margin: 5px;
  cursor: pointer;
`;

const StyledEditButton = styled.button`
  width: 45px;
  background-color: transparent;
  border: none;
  position: absolute;
  // right: 5px;
  left: 800px
  top: -99px;
  cursor: pointer;
  z-index: 1000;
`

const MessageBox = ({ superAdmin, initMessage }) => {
  const [loading, updateLoading] = useState(false);
  const [value, setValue] = useState([]);
  const [editScreen, updateEditScreen] = useState(false);

  const handleEditScreen = () => {
    updateEditScreen(!editScreen)
  }

  useEffect(() => {
    if (initMessage) {
      setValue(initMessage);
    }
  }, [initMessage]);
  const withLinks = (editor) => {
    const { insertData, insertText, isInline } = editor;

    editor.isInline = (element) => {
      return element.type === "link" ? true : isInline(element);
    };

    editor.insertText = (text) => {
      if (text && isUrl(text)) {
        wrapLink(editor, text);
      } else {
        insertText(text);
      }
    };

    editor.insertData = (data) => {
      const text = data.getData("text/plain");

      if (text && isUrl(text)) {
        wrapLink(editor, text);
      } else {
        insertData(data);
      }
    };

    return editor;
  };
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(
    () => withLinks(withHistory(withReact(createEditor()))),
    []
  );

  const insertLink = (editor, url) => {
    if (editor.selection) {
      wrapLink(editor, url);
    }
  };

  const isLinkActive = (editor) => {
    const [link] = Editor.nodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
    });
    return !!link;
  };

  const unwrapLink = (editor) => {
    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
    });
  };

  const wrapLink = (editor, url) => {
    if (isLinkActive(editor)) {
      unwrapLink(editor);
    }

    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);
    const link = {
      type: "link",
      url,
      children: isCollapsed ? [{ text: url }] : [],
    };

    if (isCollapsed) {
      Transforms.insertNodes(editor, link);
    } else {
      Transforms.wrapNodes(editor, link, { split: true });
      Transforms.collapse(editor, { edge: "end" });
    }
  };

  const Element = ({ attributes, children, element }) => {
    switch (element.type) {
      case "link":
        return (
          <a {...attributes} href={element.url}>
            {children}
          </a>
        );
      default:
        return <p {...attributes}>{children}</p>;
    }
  };

  const LinkButton = () => {
    const editor = useSlate();
    return (
      <ButtonAreaBox
        active={isLinkActive(editor)}
        onMouseDown={(event) => {
          event.preventDefault();
          const url = window.prompt("Enter the URL of the link:");
          if (!url) return;
          insertLink(editor, url);
        }}
      >
        {getSvg["link"]}
      </ButtonAreaBox>
    );
  };

  const changeTextbox = (value) => {
    setValue(value);
  };

  const submitChanges = async (e) => {
    e.preventDefault();
    updateLoading(true);

    const response = await apiCall({
      path: "message/post",
      method: "post",
      body: {
        message: "main",
        body: JSON.stringify(value),
      },
    });

    if (hasSuccess(response)) {
      updateLoading(false);
      const getresponse = await apiCall({
        path: "message/get",
        method: "post",
        body: {
          message: "main",
        },
      });

      if (hasSuccess(getresponse)) {
        setValue(JSON.parse(getresponse));
      }
      updateEditScreen(!editScreen);
    }
  };

  return (
    <Slate
      editor={editor}
      value={value}
      onChange={(value) => changeTextbox(value)}
    >
      <StyledAreaBox>
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Vul iets in"
          className={"box"}
          autoFocus={false}
          readOnly={!superAdmin}
          onKeyDown={(event) => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            }
          }}
        />
      </StyledAreaBox>
      {superAdmin && (
        <StyledEditButton onClick={handleEditScreen}>
          {getSvg['edit']}
        </StyledEditButton>
      )}
      {editScreen && (
        <StyledFixedBackground>
          <StyledScreenContainer style={{ position: 'relative', width: '40%' }}>
            <div>
              <MarkButton format="bold" icon="bold" />
              <MarkButton format="underline" icon="underline" />
              <BlockButton format="heading-one" icon="looksOne" />
              <BlockButton format="heading-two" icon="looksTwo" />
              <LinkButton />
            </div>
            <StyledAreaBox>
              <Editable
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                placeholder="Vul iets in"
                className={"box"}
                autoFocus={false}
                readOnly={!superAdmin}
                onKeyDown={(event) => {
                  for (const hotkey in HOTKEYS) {
                    if (isHotkey(hotkey, event)) {
                      event.preventDefault();
                      const mark = HOTKEYS[hotkey];
                      toggleMark(editor, mark);
                    }
                  }
                }}
              />
            </StyledAreaBox>
            <form onSubmit={submitChanges}>
              <SubmitButton valid={true} loading={loading} text="Bevestig" />
              <StyledButton type="button" onClick={handleEditScreen}>
                Annuleer
              </StyledButton>
            </form>
          </StyledScreenContainer>
        </StyledFixedBackground>
      )}
    </Slate>
  );
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      LIST_TYPES.includes(
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type
      ),
    split: true,
  });
  const newProperties = {
    type: isActive ? "paragraph" : isList ? "list-item" : format,
  };
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  });

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();

  return (
    <ButtonAreaBox
      active={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {getSvg[icon]}
    </ButtonAreaBox>
  );
};

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <ButtonAreaBox
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {getSvg[icon]}
    </ButtonAreaBox>
  );
};

export default MessageBox;
