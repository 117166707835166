import React, { useEffect, useState } from "react";
import {
  StyledButton,
  StyledFixedBackground,
  StyledInput,
  StyledInputContainer,
} from "../../shared/styles";
import {
  StyledElementContainer,
  StyledScreenContainer,
} from "../../shared/styles/containers";
import { StyledLabel } from "../../shared/styles/label";
import { apiCall } from "../../api";
import SubmitButton from "../../shared/components/submitButton";
import { emailRegex } from "../auth/constants";
import Message from "../../shared/components/message";

const ResetUserMfa = ({ handleCancelScreen }) => {
  const [valid, updateValid] = useState(false);
  const [form, updateForm] = useState({ email: "" });
  const [errorMessage, updateErrorMessage] = useState();
  const [loading, updateLoading] = useState(false);
  const [message, updateMessage] = useState({});

  useEffect(() => {
    const validObject = {
      email: {
        valid: RegExp(emailRegex).test(form["email"]),
        errorMessage: RegExp(emailRegex).test(form["email"])
          ? ""
          : "Geen geldig email adres",
      },
    };
    updateValid(
      !Object.keys(validObject)
        .map((key) => validObject[key]["valid"])
        .includes(false)
    );
  }, [form]);

  const resetMfa = async (e) => {
    e.preventDefault();
    updateLoading(true);
    updateErrorMessage("");

    const response = await apiCall({
      path: "users/reset-user-mfa",
      method: "post",
      body: {
        email: form["email"],
      },
    });

    if (response) {
      updateLoading(false);
      if (Object.keys(response).includes("error")) {
        updateErrorMessage(response["error"]);
      } else {
        updateMessage({
          title: "MFA reset gelukt",
          body: `Er is een mail verzonden naar ${
            form["email"]
          } en partnermanagement@ogmail.nl ter bevestiging.`,
          button: true,
          submitFunction: handleCancelScreen,
          linkLabel: "Volgende",
        });
      }
    }
  };

  const handleOnChange = ({ target: { name, value } }) => {
    updateForm({ ...form, [name]: value });
  };

  return (
    <>
      <StyledFixedBackground>
        <StyledScreenContainer>
          <form onSubmit={(e) => resetMfa(e)}>
            <StyledElementContainer>
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
              <StyledLabel>Gebruiker MFA Reset</StyledLabel>
              <StyledInputContainer style={{ height: "auto" }}>
                <StyledInput
                  id={"email"}
                  name={"email"}
                  value={form["email"]}
                  onChange={(e) => handleOnChange(e)}
                  placeholder={"Gebruikers email"}
                  type={"text"}
                />
              </StyledInputContainer>
            </StyledElementContainer>
            <StyledElementContainer>
              <StyledButton type="button" onClick={handleCancelScreen}>
                Annuleer
              </StyledButton>
              <SubmitButton valid={valid} loading={loading} text="Bevestig" />
            </StyledElementContainer>
          </form>
        </StyledScreenContainer>
      </StyledFixedBackground>
      {Object.keys(message).length > 0 && <Message message={message} />}
    </>
  );
};

export default ResetUserMfa;
