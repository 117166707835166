import React from 'react';
import AddSVG from './add';
import EditSVG from './edit';
import SearchSVG from './search'
import FormattedBoldSVG from './formattedBold';
import FormattedUnderlineSVG from './formattedUnderline';
import FormattedLooksOneSVG from './formattedLooksOne';
import FormattedLooksTwoSVG from './formattedLooksTwo';
import FormattedLinkSVG from './formattedLink';
import FormattedUserSVG from './formattedUser';
import OnderlingeDigitaalArchiefSVG from './onderlingeDigitaalArchief';
import OnderlingeGebruikersfSVG from './onderlingeGebruikers';
import FormattedContactSVG from './formattedContact';
import FormattedLifeSVG from './formattedLife';
import FormattedInfoSVG from './formattedInfo';
import FormattedPensionSVG from './formattedPension';
import FormattedMortgageSVG from './formattedMortgage';
import FormattedServicesSVG from './formattedServices';
import FormattedSmartPhoneSVG from './formattedSmartPhone';
import FormattedKlantoverzichtSVG from './formattedKlantoverzicht';


const getSvg = {
  edit: <EditSVG />,
  add: <AddSVG />,
  search: <SearchSVG />,
  bold: <FormattedBoldSVG />,
  underline: <FormattedUnderlineSVG />,
  looksOne: <FormattedLooksOneSVG />,
  looksTwo: <FormattedLooksTwoSVG />,
  link: <FormattedLinkSVG />,
  user: <FormattedUserSVG />,
  users: <OnderlingeGebruikersfSVG />,
  contact: <FormattedContactSVG />,
  smartPhone: <FormattedSmartPhoneSVG />,
  'digitaal-archief': <OnderlingeDigitaalArchiefSVG />,
  'axy-life-leven': <FormattedLifeSVG />,
  'algemene-informatie': <FormattedInfoSVG />,
  'axy-life-pensioen': <FormattedPensionSVG />,
  'zilverhuis-hypotheek': <FormattedMortgageSVG />,
  'zilverhuis-diensten': <FormattedServicesSVG />,
  'klantoverzicht': <FormattedKlantoverzichtSVG/>
}

export default getSvg;
