const theme = {
  color: {
    white: '#fff',
    owhite: '#F7F5F2',
    black: '#000',
    error: '#BB2E31',
    lightGrey: '#B7B8B9',
    middleGrey: '#999',
    darkGrey: '#2D3142',
    blue: '#6B7492',
    red: '#CB3F5D',
    yellow: '#FAA21D',
    mainBackgroundColor: '#fff',
    sideNavigation: '#4F5D75',
    navItem: '#DBE2ED',
    buttonColor: '#FFFF',
    buttonBackground: '#6B7492',
    buttonBorder: '#BFBFBF',
    buttonHover: '#f1f1f1',
    borderWindow: '#e8e8e8',
    readOnlyBackgroundColor: '#e2e2e2',
    tableBackground: "#4F5D75",
    tableBodyTr: "#f1f1f1",
    tableBodyTrEven: "#dbdbdb"
  }
};

export default theme;
