import { applyMiddleware, compose, createStore } from "redux";
import reduxThunk from "redux-thunk";
import { rootReducer } from "./reducers/root";

/* eslint-disable no-underscore-dangle */
const extensionCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
/* eslint-enable no-underscore-dangle */

const composeEnhancers =
  (process.env.NODE_ENV === "development" && extensionCompose) || compose;

const createReduxStore = () =>
  createStore(rootReducer, composeEnhancers(applyMiddleware(reduxThunk)));

export default createReduxStore;
