import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { allAppicationsHandlerAction, allUsersHandlerAction } from '../shared/actions/cognito';
import { sessionErrorAction } from '../shared/actions/error';
import { userInfoHandlerAction } from '../shared/actions/user';
import ErrorMessageComponent from '../shared/components/errorMessage';
import getSpinner from '../shared/components/loadingSpinner';
import Message from '../shared/components/message';
import { errorMessageSelector, errorSessionSelector, globalLoaderSelector, messageSelector } from '../shared/selectors/shared';
import { authenticatedUser, userSelector, userSessionSelector } from '../shared/selectors/user';
import { hasObj } from '../shared/utils';
import { StyledEnviromentIndicator } from '../shared/styles'
import Login from './auth';
import Dashboard from './dashboard';
import config from '../config'

const Root = ({ history, sessionErrorHandler, errorSession, userInfoHandler, user, globalLoader, message, allUsersHandler, allAppicationsHandler, errorMessage, stest }) => {
  const [ userInfo, updateUserInfo ] = useState({});
  const [ appIsLoaded, updateAppIsLoaded ] = useState(false);
  const acceptance = config['environment'] == 'ACC'

  function useEffectAsync(effect, inputs) {
     useEffect(() => {
        effect();
      }, inputs);
  }

  useEffectAsync( async () => {
    userSessionSelector({ sessionTimeout: sessionErrorHandler });
    authenticatedUser({
      userInfoHandler,
      updateAppIsLoaded,
      allUsersHandler,
      allAppicationsHandler
    });
    if (!hasObj(userInfo) && window.location.pathname !== '/') {
      window.location = '/'
    }
  }, []);

  useEffect(() => {
    updateUserInfo(user);
  }, [user, userInfo]);

  const clearSessionData = () => {
    updateUserInfo({});
    sessionErrorHandler({});
    userInfoHandler({});
    allUsersHandler({});
    allAppicationsHandler({});
    history.push('/');
  }

  return appIsLoaded ? (
    <React.Fragment>
      {globalLoader && getSpinner('common')}
      {hasObj(message) && <Message message={message} />}
      {hasObj(errorMessage) && <ErrorMessageComponent />}
      {hasObj(errorSession) && <Message message={{
        title: 'Sessie is verlopen',
        body: 'De sessie is verlopen.',
        linkLabel: 'Log opnieuw in',
        submitFunction: clearSessionData,
        button: true
      }} />}
      {acceptance && (
        <StyledEnviromentIndicator>
          Acceptatie
        </StyledEnviromentIndicator>
      )}
      {hasObj(userInfo) ? (
        <Dashboard user={userInfo} history={history} acceptance={acceptance} />
      ) : (
        <Login history={history} acceptance={acceptance} />
      )}
    </React.Fragment>
  ) : getSpinner('common')
};

const mapStateToProps = (state, props) => ({
  user: userSelector(state),
  globalLoader: globalLoaderSelector(state),
  message: messageSelector(state),
  errorMessage: errorMessageSelector(state),
  errorSession: errorSessionSelector(state),
});

const mapDispatchToProps = dispatch => ({
  sessionErrorHandler: message => dispatch(sessionErrorAction(message)),
  userInfoHandler: user => dispatch(userInfoHandlerAction(user)),
  allUsersHandler: users => dispatch(allUsersHandlerAction(users)),
  allAppicationsHandler: applications => dispatch(allAppicationsHandlerAction(applications))
});

export default connect(mapStateToProps, mapDispatchToProps)(Root)
