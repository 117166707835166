import React from "react";

const FormattedKlantoverzichtSVG = () => (
  <svg id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 203 161.83">
  <path className="cls-1" style={{
    fill:"none",stroke:"#cb3f5d",strokeLinejoin:"round",strokeWidth:"8px"
  }} d="M125.73,112.05,101.5,132.74,77.27,112.05l-10.92,3.63a14.37,14.37,0,0,0-9.85,13.61v31h90v-31a14.37,14.37,0,0,0-9.85-13.61Z"/>
  <circle className="cls-1" style={{
    fill:"none",stroke:"#cb3f5d",strokeLinejoin:"round",strokeWidth:"8px"
  }} cx="101" cy="77.36" r="27.59"/>
  <circle className="cls-1" style={{
    fill:"none",stroke:"#cb3f5d",strokeLinejoin:"round",strokeWidth:"8px"
  }} cx="156.17" cy="29.09" r="27.59"/>
  <circle className="cls-1" style={{
    fill:"none",stroke:"#cb3f5d",strokeLinejoin:"round",strokeWidth:"8px"
  }} cx="45.83" cy="29.09" r="27.59"/>
  <path className="cls-1" style={{
    fill:"none",stroke:"#cb3f5d",strokeLinejoin:"round",strokeWidth:"8px"
  }} d="M76.57,66.09l-6.1-2L46.33,84.74,22.19,64.05,11.31,67.68A14.37,14.37,0,0,0,1.5,81.29v31H77"/>
  <path className="cls-1" style={{
    fill:"none",stroke:"#cb3f5d",strokeLinejoin:"round",strokeWidth:"8px"
  }} d="M126,112.33h75.5v-31a14.37,14.37,0,0,0-9.81-13.61l-10.88-3.63L156.67,84.74,132.53,64.05l-6.1,2"/>
</svg>
);

export default FormattedKlantoverzichtSVG;
