import React from 'react';
import { compose, withHandlers } from "recompose";
import { StyledConfirm } from '../../shared/styles/index';
// import CloseOnEscape from '../closeOnEscape';
import { StyledButton } from '../styles';


const Confirm = ({
  text,
  handleCancelScreen,
  handleSubmit
}) => (
  <StyledConfirm>
    <div className="confirm-container">
      <h2>
        {text.heading}
      </h2>
      <p>
        {text.paraf}
      </p>
      <StyledButton onClick={handleCancelScreen}>
        Annuleer
      </StyledButton>
      <StyledButton  onClick={() => handleSubmit()}>
        Bevestig
      </StyledButton>
    </div>
  </StyledConfirm>
)

export default compose(
  withHandlers({
    handleSubmit: ({ loadingHelper, loadingHelperParams, setLoading, setLoadingParams, action, actionParams, handleCancelScreen, initLoader, submit }) => () => {
      if (!Array.isArray(actionParams)) {
        action(actionParams);
      } else {
        action(...actionParams);
      }
      // if (initLoader) {
      //   setLoading(initLoader);
      // }
      // if (loadingHelper !== false) {
      //   setLoading(setLoadingParams);
      //   loadingHelper(loadingHelperParams);
      // }
      handleCancelScreen({ submit: true });

      if (submit) {
        submit();
      }
    }
  })
)(Confirm);
