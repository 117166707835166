import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { StyledButton, StyledFixedBackground, StyledInput, StyledInputContainer } from '../../shared/styles';
import { StyledElementContainer, StyledScreenContainer } from '../../shared/styles/containers';
import { StyledLabel } from '../../shared/styles/label';
import { apiCall } from '../../api';
import SubmitButton from "../../shared/components/submitButton";

const uploadUserSheet = ({ handleCancelScreen }) => {
  const [valid, updateValid] = useState(false);
  const [selectedFile, setSelectedFile] = useState('');
  const [errorMessage, updateErrorMessage] = useState();
  const [loading, updateLoading] = useState(false);

  const selectFile = ({ target }) => {
    setSelectedFile(target.files[0]);
  }

  useEffect(() => {
    if (selectedFile) {
      const re = /(?:\.([^.]+))?$/;
      const extension = re.exec(selectedFile.name)[1];
      const hasRightExtension = extension === "xlsx"
      if (!hasRightExtension) {
        updateErrorMessage(
          "Het geselecteerde bestand voldoet niet aan de eisen."
        );
        updateValid(false);
      } else {
        updateErrorMessage();
        updateValid(true);
      }
    }
  }, [selectedFile]);

  const uploadFile = async (e) => {
    e.preventDefault();
    updateLoading(true);

    const response = await apiCall({
      path: 'presignedUrl/generate',
      method: 'post',
      body: {
        fileName: selectedFile['name']
      }
    });

    if (response['presignedUrl']) {
      uploadFileToS3({
        presignedUrl: response['presignedUrl'],
        file: selectedFile
      })
    } 
  }

  const uploadFileToS3 = ({ presignedUrl, file }) => {
    const formData = new FormData();

    Object.keys(presignedUrl.fields).forEach((key) => {
      formData.append(key, presignedUrl.fields[key]);
    });

    formData.append("file", file);

    axios
      .post(presignedUrl.url, formData, {
        headers: {
          "Content-Type": file['type'],
        },
      })
      .then(() => {
        updateLoading(false);
        setSelectedFile(null);
        handleCancelScreen();
      })
      .catch(function (error) {
        console.log("POST ERROR", error);
      });
  };

  return (
    <StyledFixedBackground>
      <StyledScreenContainer>
        <form onSubmit={(e) => uploadFile(e)}>
          <StyledElementContainer>
            {errorMessage && (
              <p style={{color: 'red'}}>
                {errorMessage}
              </p>
            )}
            <StyledLabel> Selecteer xlsx bestand <span className="required">*</span></StyledLabel>
            <StyledInputContainer style={{ height: 'auto' }}>
              <StyledLabel className="label-button" htmlFor="uploadFile">
                {selectedFile ? selectedFile['name'] : 'Selecteer bestand'}
              </StyledLabel>
              <StyledInput
                id={'uploadFile'}
                name={'uploadFile'}
                // value={selectedFile ? selectedFile['name'] : ''}
                onChange={selectFile}
                placeholder={'Upload usersheet'}
                type={'file'}
              />
            </StyledInputContainer>
          </StyledElementContainer>
          <StyledElementContainer>
            <StyledButton type="button" onClick={handleCancelScreen}>
              Annuleer
            </StyledButton>
            <SubmitButton valid={valid} loading={loading} text="Uploaden" />
            {/* <StyledButton type="submit" disabled={(!valid)}>
              Upload
            </StyledButton> */}
          </StyledElementContainer>
        </form>
      </StyledScreenContainer>
    </StyledFixedBackground>
  )
}

export default uploadUserSheet;
