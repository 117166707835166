export const passRegex =
  "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[-#?!@$%^&|\\\\*+=(//\\,.`)_{}\\][]).{8,}$";

export const emailRegex =
  "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";

export const loginForm = Object.freeze({
  email: {
    label: 'E-mail',
    type: 'email',
    placeholder: 'Voer e-mail adres in',
    required: true
  },
  password: {
    label: 'Wachtwoord',
    type: 'password',
    placeholder: 'Voer wachtwoord in',
    required: true
  }
});

export const forgotPasswordForm = Object.freeze({
  email: {
    label: 'E-mail',
    type: 'email',
    placeholder: 'Voer je e-mail in',
    required: true
  }
});

export const resetPassswordForm = Object.freeze({
  email: {
    label: 'E-mail',
    type: 'email',
    placeholder: 'Voer e-mail in',
    required: true,
    autoFocus: true
  },
  verificationCode: {
    label: 'Verificatiecode',
    type: 'number',
    placeholder: 'Voer verificatiecode in',
    required: true
  },
  password: {
    label: 'Wachtwoord',
    type: 'password',
    placeholder: 'Voer nieuw wachtwoord in',
    required: true
  },
  confirmPassword: {
    label: 'Herhaal wachtwoord',
    type: 'password',
    placeholder: 'Voer nogmaals het wachtwoord',
    required: true
  }
});

export const newPassswordForm = Object.freeze({
  password: {
    label: 'Wachtwoord',
    type: 'password',
    placeholder: 'Nieuw wachtwoord',
    required: true,
    autoFocus: true,
  },
  confirmPassword: {
    label: 'Herhaal wachtwoord',
    type: 'password',
    placeholder: 'Herhaal wachtwoord',
    required: true
  }
});

export const initialForm = {
  email: '',
  password: ''
}

export const initialForgotPasswordForm = {
  email: ''
}

export const initialResetPasswordForm = {
  email: '',
  verificationCode: '',
  password: '',
  confirmPassword: ''
}

export const initialNewPasswordForm = {
  password: '',
  confirmPassword: ''
}

export const initialAuthenticationForm = {
  code: '',
}

export const authenticationForm = Object.freeze({
  code: {
    label: 'MFA Code',
    type: 'number',
    placeholder: 'Voer MFA code in',
    required: true
  }
});


export const transelateErrorMessage = {
  UserNotFoundException: 'Het e-mailadres en/of wachtwoord is niet correct.',
  NotAuthorizedException: 'Het e-mailadres en/of wachtwoord is niet correct.',
  NotAuthorizedExceptionResetPassword: 'Het e-mailadres en/of wachtwoord is niet correct.',
  CodeMismatchException: 'Verkeerde MFA code.',
  ExpiredCodeException: 'Verificatie code is verlopen.',
  ActivationCodeError: "Wanneer je mailadres bij ons bekend is, ontvang je daarop een nieuwe activatiemail."
}

export const addUserForm = Object.freeze({
  email: {
    label: 'E-mail',
    type: 'email',
    placeholder: 'Voer het e-mail adres in',
    required: true
  },
  password: {
    label: 'Tijdelijk wachtwoord',
    type: 'password',
    placeholder: 'Voer een tijdelijk wachtwoord in',
    required: true
  },
  confirmPassword: {
    label: 'Herhaal tijdelijk wachtwoord',
    type: 'password',
    placeholder: 'Herhaal tijdelijk wachtwoord',
    required: true
  }
});

export const editUserForm = Object.freeze({
  email: {
    label: 'E-mail',
    type: 'email',
    placeholder: 'Voer het e-mail adres in',
    required: true
  }
});

export const intermediaryForm = Object.freeze({
  intermediary: {
    label: 'Adviseur',
    type: 'input',
    placeholder: 'Naam van adviseur',
    required: true
  }
});

export const tpCodeForm = Object.freeze({
  tpcode: {
    label: 'TP-code',
    type: 'input',
    placeholder: 'TP-code',
    required: true
  }
});
