import React from 'react';

import { StyledSearchInputContainer } from '../styles/input';
import getSvg from '../svg/getSvg';

const SearchInput = ({ value, handleSearch, assignForm }) => (
  <StyledSearchInputContainer style={{ paddingTop: assignForm ? '15px' : '' }} >
    <input className="search" placeholder="Zoeken" value={value} onChange={(e) => handleSearch(e)} />
    <div className="svg-container">
      {getSvg['search']}
    </div>
  </StyledSearchInputContainer>
);

export default SearchInput;
