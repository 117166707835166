export const userManagementListPagingAction = paging => ({
  type: "UPDATE_LIST_PAGING_USER_MANAGEMENT",
  payload: paging
});

export const userManagementRowsPerPageAction = row => ({
  type: "UPDATE_ROWS_PER_PAGE_USER_MANAGEMENT",
  payload: row
});

export const userManagementSearchValueAction = value => ({
  type: "UPDATE_SEARCH_VALUE_USER_MANAGEMENT",
  payload: value
});
