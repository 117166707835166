import styled from 'styled-components';

export const StyledInputWrapper = styled.div`
  // padding: 5px;
  // background-color: ${props => props.theme.color.middleGrey};
  // color: ${props => props.theme.color.white};
  // border: 1px solid ${props => props.theme.color.middleGrey};
  // border-top-right-radius: 3px;
  // border-top-left-radius: 10px;
  // margin-right: 5px;

  // &:hover {
  //   opacity: .5;
  // }
`

export const StyledSearchInputContainer = styled.div`
  float: right;
  margin-bottom: 10px;

  .svg-container {
    float: right;
  }

  input.search {
    padding: 5px;
    outline: none;
    margin-right: 5px;
    border: 1px solid ${props => props.theme.color.middleGrey};
    border-color: ${props => props.theme.color.middleGrey};
    border-top-right-radius: 3px;
    border-top-left-radius: 10px;
  }
`


export const StyledInputContainer = styled.div`
  position: relative;
  display: flex;
  padding: 5px 0;
`

export const StyledRadioInput = styled.label`
  ${props => props.displayBlock && `
    display: block;
  `}
  position: relative;
  padding: 3px 8px 5px 30px;
  cursor: pointer;
  font-size: 1.2em;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border: 1px solid #b5b5b5;
    border-radius: 20px;

    ${props => props.disabled && `
      opacity: .2;
      cursor: default;
    `}
  }

  a {
    color: ${props => props.theme.color.red}
  }

  &:hover input ~ .checkmark {
    background-color: #f0f0f0;
  }

  input:checked ~ .checkmark {
    background-color: ${props => props.theme.color.red};
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

export const StyledInput = styled.input`
  width: 65%;
  padding: 10px;
  border: 1px solid ${props => props.theme.color.middleGrey};
  border-color: ${props => props.theme.color.middleGrey};
  border-radius: 5px;
  display: flex;
  opacity: .8;
  font-size: 1.20rem;
  &:hover {
    opacity: 1;
  }

  ${props => props.height && `
    height: ${props.height}px;
  `}

  ${props => (props.margin || props.margin === 0) && `
    margin: ${props.margin}px;
  `}

  ${props => props.width && `
    width: ${props.width}%;
  `}

  &:focus {
    outline: none;
    opacity: 1;
  }

  &:read-only {
    background-color: ${props => props.theme.color.readOnlyBackgroundColor};
    border: 1px solid #bdbdbd;
    color: #696969;
    cursor: auto;
  }

  ${props => props.invalid && `
    border: 1px solid ${props.theme.color.error}
  `}

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  &[type=number] {
    -moz-appearance: textfield;
    -webkit-appearance: none;
    margin: 0;
  }
`
