import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { apiCall } from '../../api';
import { allAppicationsHandlerAction, allUsersHandlerAction } from '../../shared/actions/cognito';
import { setErrorMessageAction } from '../../shared/actions/error';
import { userManagementListPagingAction, userManagementRowsPerPageAction, userManagementSearchValueAction } from '../../shared/actions/list';
import GetScreen from '../../shared/components/getScreen';
import { ListComponent } from '../../shared/components/list';
import { intermediaryListSelector, usersSelector } from '../../shared/selectors/cognito';
import { userManagementPagingSelector, userManagementRowsPerPageSelector, userManagementSearchValueSelector } from '../../shared/selectors/list';
import { userSelector } from '../../shared/selectors/user';
import { StyledContentContainer } from '../../shared/styles';
import { hasError, hasObj, hasSuccess } from '../../shared/utils';
import Navigation from '../navigation/main';
import { listItems, translateListItems } from './constants';
import getSpinner from '../../shared/components/loadingSpinner';


const UserManagement = ({ user, allUsersHandler, allAppicationsHandler, setErrorMessage, users, intermediaryList, paging, handleListPaging, handleRowsPerPage, selectedRow, handleSearchValue, searchValue, history }) => {
  // const [ isSuperAdmin, updateIsSuperAdmin ] = useState(false);
  const [ screen, updateScreen ] = useState({});
  const [ bodyItems, updateBodyItems ] = useState();
  const [ loadingItem, upadateLoadingItem ] = useState();
  const [ isLoadingUsers, updateIsLoadingUsers] = useState(false);

  function useEffectAsync(effect, inputs) {
      useEffect(() => {
        effect();
      }, inputs);
  }

  useEffectAsync( async () =>  {
    if (Object.keys(users).includes("1") && users["1"].length === 0) {
      updateIsLoadingUsers(true);
      const allUsersResponse = await apiCall({
        path: 'users/all',
        method: 'get'
      });

      if (allUsersResponse) {
        allUsersHandler(allUsersResponse);
        updateIsLoadingUsers(false);
      }
    }
  }, [])

  useEffect(() => {
    if (hasObj(users)) {
      updateBodyItems(users);
    }
  }, [users])

  const handleSearch = ({ target: { value } }) => {
    handleSearchValue(value);
  }

  const handleCancelScreen = () => {
    updateScreen({});
  }

  const openScreenHandler = (screen) => {
    updateScreen({
      screen,
      intermediaryList
    });
  }

  const handlePaging = (page) => {
    handleListPaging(page)
  }

  const deleteUserHandler = async ({ selectedUser }) => {
    upadateLoadingItem({
      ...loadingItem,
      [selectedUser.username]: true
    });

    const deleteUserResponse = await apiCall({
      path: 'users/single',
      method: 'delete',
      body: {
        username: selectedUser.username,
        tpcode: selectedUser.tpcode
      }
    });

    if (hasSuccess(deleteUserResponse)) {
      const allUsersResponse = await apiCall({
        path: 'users/all',
        method: 'get'
      });

      if (allUsersResponse) {
        allUsersHandler(allUsersResponse);
        upadateLoadingItem({
          ...loadingItem,
          [selectedUser.username]: false
        });
      }
    }
  }

  const editUserHandler = async ({ selectedUser, userObject, applicationSelection, validApplicationChanges, userDisabled }) => {
    upadateLoadingItem({
      ...loadingItem,
      [selectedUser.username]: true
    });

    if (userDisabled !== undefined) {
      apiCall({
        path: 'users/update-user-status',
        method: 'post',
        body: {
          username: selectedUser.username,
          disable: userDisabled
        }
      });
    }

    const addUserResponse = await apiCall({
      path: 'users/update-user-attributes',
      method: 'post',
      body: userObject
    });

    if (hasError(addUserResponse)) {
      setErrorMessage({
        title: 'Oops!',
        message: 'Er is iets verkeerd gegaan.'
      });
      handleCancelScreen({});
    } else {
      if (validApplicationChanges) {
        const addUserToGroupResponse = await apiCall({
          path: 'groups/add-user-in-groups',
          method: 'put',
          body: applicationSelection
        });
        
        if (addUserToGroupResponse) {
          const allApplicationsResponse = await apiCall({
            path: 'groups/all',
            method: 'get'
          });
          if (allApplicationsResponse) {
            allAppicationsHandler(allApplicationsResponse);
          }
        }
      }

      setTimeout(async () => {
        const allUsersResponse = await apiCall({
          path: 'users/all',
          method: 'get'
        });
  
        if (allUsersResponse) {
          allUsersHandler(allUsersResponse);
          upadateLoadingItem({
            ...loadingItem,
            [selectedUser.username]: false
          });
        }
      }, 2000);
    }
  }

  const actionHandler = async (action, user) => {
    updateScreen({
      screen: `${action}User`,
      user,
      editUserHandler,
      deleteUserHandler
    });

    const userGroups = await apiCall({
      path: 'users/get-user-groups',
      method: 'post',
      body: {
        username: user['username']
      }
    });

    if (userGroups) {
      updateScreen({
        screen: `${action}User`,
        user: { ...user, groups: userGroups},
        editUserHandler,
        deleteUserHandler
      });
    }

  }

  return bodyItems ? (
    <>
      <Navigation user={user} backButton={true} history={history} />
      <StyledContentContainer>
        <>
          <div className="content">
            <ListComponent
              user={user}
              title='Gebruikersbeheer'
              listItems={listItems['userManagment']}
              bodyItems={bodyItems}
              translateListItems={translateListItems['userManagment']}
              actionHandler={actionHandler}
              searchValue={searchValue}
              handleSearch={handleSearch}
              openScreenHandler={openScreenHandler}
              listPage={paging}
              handlePaging={handlePaging}
              handleRowsPergPage={handleRowsPerPage}
              selectedRow={selectedRow}
              assignForm={false}
              addProfie={false}
              page={paging}
              loadingItem={loadingItem}
              loadingKey={'username'}
              disabledAction={[user.email, 'username']}
              isLoadingUsers={isLoadingUsers}
            />
          </div>
          {hasObj(screen) && (
            <GetScreen getObject={screen} handleCancelScreen={handleCancelScreen} />
          )}
          </>
      </StyledContentContainer>
    </>
) : null };

const mapStateToProps = (state) => ({
  users: usersSelector(state),
  user: userSelector(state),
  intermediaryList: intermediaryListSelector(state),
  paging: userManagementPagingSelector(state),
  selectedRow: userManagementRowsPerPageSelector(state),
  searchValue: userManagementSearchValueSelector(state)
});

const mapDispatchToProps = dispatch => ({
  handleListPaging: paging => dispatch(userManagementListPagingAction(paging)),
  handleRowsPerPage: row => dispatch(userManagementRowsPerPageAction(row)),
  handleSearchValue: value => dispatch(userManagementSearchValueAction(value)),
  setErrorMessage: message => dispatch(setErrorMessageAction(message)),
  allUsersHandler: users => dispatch(allUsersHandlerAction(users)),
  allAppicationsHandler: applications => dispatch(allAppicationsHandlerAction(applications)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
