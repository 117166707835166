import React from "react";

const FormattedMortgageSVG = () => (
  <svg id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 203 203">
    {/* <defs>
      <style>.cls-1,.cls-2{fill:none;stroke:#cb3f5d;stroke-linejoin:round;stroke-width:3px;}.cls-2{stroke-linecap:round;}</style>
    </defs> */}
    <path style={{ fill:'none', stroke:'#cb3f5d', strokeLinejoin:'round', strokeWidth: "8px", }} d="M25.5,110v79.5a12,12,0,0,0,12,12h128a12,12,0,0,0,12-12V110"/>
    <polygon style={{ fill:'none', stroke:'#cb3f5d', strokeLinejoin:'round', strokeWidth: "8px", }} points="169.5 69.5 169.5 17.5 145.5 17.5 145.5 45.5 101.5 1.5 1.5 101.5 17.5 117.5 101.5 33.5 185.5 117.5 201.5 101.5 169.5 69.5"/>
    <path style={{ fill:'none', strokeLinecap:'round', stroke:'#cb3f5d', strokeLinejoin:'round', strokeWidth: "8px", }} d="M121,105.5"/>
    <path style={{ fill:'none', strokeLinecap:'round',stroke:'#cb3f5d', strokeLinejoin:'round', strokeWidth: "8px", }} d="M81,161.5"/>
  </svg>
);

export default FormattedMortgageSVG;
