import { forEach, keys } from "lodash/fp";

export const clearLocalStorage = () => {
  Object.keys(window.localStorage).forEach(item => {
    window.localStorage.removeItem(item);
  });
};

export const hasObj = item => Object.keys(item).length > 0;
export const hasError = item => Object.keys(item).includes('Error');
export const hasSuccess = item => Object.keys(item).includes('Success');
export const hasValue = item => item !== undefined || item !== null;
export const getKey = ({keys, item}) => Object.keys(keys).filter(key => keys[key] === item)[0];


export const linkTo = ({ link, title }) => window.history.replaceState(null, title, link)

export const createTableGroup = ({ items, groupLimit, listItems }) => {
  let itemsGroup = {};
  let group = 1;

  forEach(key => {
    const definition = items[key];
    const itemsObj = {}

    forEach(item => {
      itemsObj[item] = definition[item]
    })(listItems);

    if (itemsGroup[group.toString()] && itemsGroup[group.toString()].length === groupLimit) {
      group++;
    }

    const groupMakerObj = tableGroupMaker({ itemsGroup, group, itemsObj })
    itemsGroup = { ...groupMakerObj }

  })(keys(items));

  return itemsGroup;
}

const tableGroupMaker = ({ itemsGroup, group, itemsObj }) => {
  if (itemsGroup[group.toString()]) {
    itemsGroup[group.toString()].push(itemsObj);
  } else {
    itemsGroup[group.toString()] = [itemsObj];
  }
  return itemsGroup;
}
