import React from "react";

const FormattedInfoSVG = () => (
  <svg
    id="Laag_1"
    data-name="Laag 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 203 203"
  >
    <text
      style={{ fontSize: "129.61px", fill: "#cb3f5d", fontFamily: "Calibri" }}
      className="cls-info-1"
      transform="translate(86.63 133.62)"
    >
      i
    </text>
    <circle
      style={{
        fill: "none",
        stroke: "#cb3f5d",
        strokeMiterlimit: "10",
        strokeWidth: "8px",
      }}
      className="cls-info-2"
      cx="101.5"
      cy="101.5"
      r="100"
    />
  </svg>
);

export default FormattedInfoSVG;
